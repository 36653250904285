import {
  ConstraintsResourceEnum,
  ConstraintsSettingsNameEnum,
  ConstraintsSubFeatureIdEnum,
} from '../config/ConstraintsEnums';
import { DeviceCacheCdmDataEnum } from '../config/DeviceCacheCdmDataEnums';
import { DeviceCacheServiceGunEnum } from '../config/DeviceCacheServiceGunEnums';
import { FeatureIdEnum } from '../config/FeatureEnums';
import { FleetSvcPolicyAttributeId } from '../config/FleetServiceEnums';
import {
  SubFeatureAccessModeEnum,
  SubFeatureControlTypeEnum,
  SubfeatureIdEnum,
} from '../config/SubFeatureEnums';

export const DigitalSendDataModel = [
  {
    id: `${FeatureIdEnum.EmailAddress_MessageSetting}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.EmailAddress_RestrictionEnabled}`,
        attribute: `${FleetSvcPolicyAttributeId.EmailAddress_MessageSetting}.restrictions`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'EmailAddressRestriction_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.EmailAddress_Restriction}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_ScanEmail}`,
        settingsName: `${ConstraintsSettingsNameEnum.EmailAddress_Restriction}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.EmailAddressAndMessageSetting}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.EmailAddressRestrictionEnabled}`,
      },
      {
        id: `${SubfeatureIdEnum.EmailAddress_MessageSetting}`,
        attribute: `${FleetSvcPolicyAttributeId.EmailAddress_MessageSetting}.message.from`,
        type: `${SubFeatureControlTypeEnum.Table}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'EmailAddressMessageSetting_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.EmailAddress_MessageSetting}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_ScanEmail}`,
        settingsName: `${ConstraintsSettingsNameEnum.EmailAddress_MessageSetting}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.EmailAddressAndMessageSetting}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.EmailMessageSettingTable}`,
      },
      {
        id: `${SubfeatureIdEnum.EmailAddress_AllowInvalidFormats}`,
        attribute: `${FleetSvcPolicyAttributeId.EmailAddress_MessageSetting}.allow-invalid-email`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'EmailAddressAllowInvalidFormat_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.EmailAddress_AllowInvalidFormats}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_ScanEmail}`,
        settingsName: `${ConstraintsSettingsNameEnum.EmailAddress_AllowInvalidFormats}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.EmailAddressAndMessageSetting}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.EmailAddressInvalidFormats}`,
      },
      {
        id: `${SubfeatureIdEnum.EmailAddress_DigitalSignUser}`,
        attribute: `${FleetSvcPolicyAttributeId.EmailAddress_MessageSetting}.sign`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'EmailAddressDglSignMsgUser_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.EmailAddress_DglSignEmail}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_ScanEmail}`,
        settingsName: `${ConstraintsSettingsNameEnum.EmailAddress_DgSignEmailMsg}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.EmailAddressAndMessageSetting}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.EmailAddressDglSign}`,
      },
      {
        id: `${SubfeatureIdEnum.EmailAddress_DigitalSignUserEditable}`,
        attribute: `${FleetSvcPolicyAttributeId.EmailAddress_MessageSetting}.sign-editable`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'EmailAddressDglSignMsgUserEditable_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.EmailAddress_DglSignEmail_UserEditable}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_ScanEmail}`,
        settingsName: `${ConstraintsSettingsNameEnum.EmailAddress_DgSignEmailMsg_UserEditable}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.EmailAddressAndMessageSetting}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.EmailAddressDglSignUserEditable}`,
      },
      {
        id: `${SubfeatureIdEnum.EmailAddress_EncryptEmailMsg}`,
        attribute: `${FleetSvcPolicyAttributeId.EmailAddress_MessageSetting}.encrypt`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'EmailAddressEncrypt_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Encrypt_EmailMsg}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_ScanEmail}`,
        settingsName: `${ConstraintsSettingsNameEnum.Encrypt_EmailMsg}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.EmailAddressAndMessageSetting}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.EmailAddressEncrypt}`,
      },
      {
        id: `${SubfeatureIdEnum.EmailAddress_EncryptEmailMsgUserEditable}`,
        attribute: `${FleetSvcPolicyAttributeId.EmailAddress_MessageSetting}.encrypt-editable`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'EmailAddressEncryptUserEditable_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Encrypt_EmailMsg_UserEditable}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_ScanEmail}`,
        settingsName: `${ConstraintsSettingsNameEnum.Encrypt_EmailMsg_UserEditable}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.EmailAddressAndMessageSetting}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.EmailAddressEncryptUserEditable}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.SaveTo_SharePoint}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.SaveTo_SharePoint}`,
        attribute: `${FleetSvcPolicyAttributeId.SaveTo_SharePoint}.check`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'saveToSharePoint_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.SaveTo_SharePoint}`,
        resource: `${ConstraintsResourceEnum.Scan_DestinationConfig}`,
        settingsName: `${ConstraintsSettingsNameEnum.SaveTo_SharePoint}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ScanDestinationConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.SaveToSharePoint}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.SendTo_Email}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.SendTo_Email}`,
        attribute: `${FleetSvcPolicyAttributeId.SendTo_Email}.check`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'sendToEmail_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.SendTo_Email}`,
        resource: `${ConstraintsResourceEnum.Scan_DestinationConfig}`,
        settingsName: `${ConstraintsSettingsNameEnum.SendTo_Email}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ScanDestinationConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.SendToEmail}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.SaveTo_NetworkFolder}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.SaveTo_NetworkFolder}`,
        attribute: `${FleetSvcPolicyAttributeId.SaveTo_NetworkFolder}.check`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'saveToNtf_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.SaveTo_NetworkFolder}`,
        resource: `${ConstraintsResourceEnum.Scan_DestinationConfig}`,
        settingsName: `${ConstraintsSettingsNameEnum.SaveTo_NetworkFolder}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ScanDestinationConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.SaveToNetworkFolder}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Email_Scan_Settings}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Email_Scan_Settings}`,
        attribute: `${FleetSvcPolicyAttributeId.Email_Scan_Settings}.optimize-text-picture`,
        type: `${SubFeatureControlTypeEnum.Radio}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Email_Scan_Settings}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_ScanEmail}`,
        settingsName: `${ConstraintsSettingsNameEnum.Email_Scan_Settings}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.EmailAddressAndMessageSetting}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.EmailScanSettings}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Network_Folder_Notification_Settings}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Network_Folder_Notification_Condition}`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Network_Folder_Notification_Condition}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_scanNetworkFolder}`,
        settingsName: `${ConstraintsSettingsNameEnum.Network_Folder_Notification_Condition}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.Network_Folder_Notification_Settings}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Network_Folder_Notification_Condition}`,
      },
      {
        id: `${SubfeatureIdEnum.Network_Folder_Notification_NotificationMode}`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'NotificationMode_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Network_Folder_Notification_NotificationMode}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_scanNetworkFolder}`,
        settingsName: `${ConstraintsSettingsNameEnum.NotificationMode}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.Network_Folder_Notification_Settings}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Network_Folder_Notification_NotificationMode}`,
      },
      {
        id: `${SubfeatureIdEnum.Network_Folder_Notification_NotificationAddress}`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Network_Folder_Notification_NotificationAddress}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_scanNetworkFolder}`,
        settingsName: `${ConstraintsSettingsNameEnum.NotificationAddress}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.Network_Folder_Notification_Settings}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Network_Folder_Notification_NotificationAddress}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.NetworkFolder_FileSetting}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Nffs_FileName_Prefix}`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'fileNamePrefix_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Nffs_FileNamePrefix}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_scanNetworkFolder}`,
        settingsName: `${ConstraintsSettingsNameEnum.Nffs_FileNamePrefix}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.Network_Folder_Notification_Settings}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Nffs_FileName_Prefix}`,
      },
      {
        id: `${SubfeatureIdEnum.Nffs_Default_FileName}`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'defaultFileName_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Nffs_DefaultFileName}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_scanNetworkFolder}`,
        settingsName: `${ConstraintsSettingsNameEnum.Nffs_DefaultFileName}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.Network_Folder_Notification_Settings}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Nfss_DefaultFileName}`,
      },
      {
        id: `${SubfeatureIdEnum.Nffs_UserEditable}`,
        type: `${SubFeatureControlTypeEnum.Checkbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'userEditable_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Nffs_UserEditable}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_scanNetworkFolder}`,
        settingsName: `${ConstraintsSettingsNameEnum.Nffs_UserEditable}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.Network_Folder_Notification_Settings}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Nffs_UserEditable}`,
      },
      {
        id: `${SubfeatureIdEnum.Nffs_FileNameSuffix}`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'fileNameSuffix_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Nffs_FileNameSiffix}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_scanNetworkFolder}`,
        settingsName: `${ConstraintsSettingsNameEnum.Nffs_FileNameSufffix}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.Network_Folder_Notification_Settings}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Nffs_FileNameSuffix}`,
      },
      {
        id: `${SubfeatureIdEnum.Nffs_FileNamePreview}`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'fileNamePreview_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Nffs_FileNamePreview}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_scanNetworkFolder}`,
        settingsName: `${ConstraintsSettingsNameEnum.Nffs_FileNamePrefix}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.Network_Folder_Notification_Settings}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Nffs_FileNamePreview}`,
      },
      {
        id: `${SubfeatureIdEnum.Nffs_DefaultColor_Preference}`,
        type: `${SubFeatureControlTypeEnum.Dropbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'defaultColorPreference_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Nffs_DefaultColorPreference}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_scanNetworkFolder}`,
        settingsName: `${ConstraintsSettingsNameEnum.Nffs_DefaultColorPreference}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.Network_Folder_Notification_Settings}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Nffs_DefaultColorPreference}`,
      },
      {
        id: `${SubfeatureIdEnum.Nffs_DefaultOutputQuality}`,
        type: `${SubFeatureControlTypeEnum.Dropbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'defaultOutputQuality_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Nffs_DefaultOutputQuality}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_scanNetworkFolder}`,
        settingsName: `${ConstraintsSettingsNameEnum.Nffs_DefaultOutputQuality}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.Network_Folder_Notification_Settings}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Nffs_DefaultOutputQuality}`,
      },
      {
        id: `${SubfeatureIdEnum.Nffs_DefaultFileType}`,
        type: `${SubFeatureControlTypeEnum.Dropbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'defaultFileType_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Nffs_DefaultFileType}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_scanNetworkFolder}`,
        settingsName: `${ConstraintsSettingsNameEnum.Nffs_DefaultFileType}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.Network_Folder_Notification_Settings}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Nffs_DefaultFileType}`,
      },
      {
        id: `${SubfeatureIdEnum.Nffs_DefaultResolution}`,
        type: `${SubFeatureControlTypeEnum.Dropbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'defaultResolution_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Nffs_DefaultResolution}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_scanNetworkFolder}`,
        settingsName: `${ConstraintsSettingsNameEnum.Nffs_DefaultResolution}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.Network_Folder_Notification_Settings}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Nffs_DefaultResolution}`,
      },
      {
        id: `${SubfeatureIdEnum.Nffs_PdfCompression}`,
        type: `${SubFeatureControlTypeEnum.Dropbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'pdfCompression_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Nffs_PdfCompression}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_scanNetworkFolder}`,
        settingsName: `${ConstraintsSettingsNameEnum.Nffs_PdfCompression}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.Network_Folder_Notification_Settings}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Nffs_PdfCompression}`,
      },
      {
        id: `${SubfeatureIdEnum.Nffs_BlockTiffCompressionMethod}`,
        type: `${SubFeatureControlTypeEnum.Dropbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'blockTiffCompressionMethod_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Nffs_BlockTiffCompressionMethod}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_scanNetworkFolder}`,
        settingsName: `${ConstraintsSettingsNameEnum.Nffs_BlockTiffCompressionMethod}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.Network_Folder_Notification_Settings}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Nffs_BlackTiffCompression}`,
      },
      {
        id: `${SubfeatureIdEnum.Nffs_ColorGrayScaleTiffCompressionMethod}`,
        type: `${SubFeatureControlTypeEnum.Dropbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'colorGrayCompressionMethod_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Nffs_ColorGrayScaleCompressionMethod}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_scanNetworkFolder}`,
        settingsName: `${ConstraintsSettingsNameEnum.Nffs_ColorGrayScaleCompressionMethod}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.Network_Folder_Notification_Settings}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Nffs_ColorGrayScaleCompressionMethod}`,
      },
      {
        id: `${SubfeatureIdEnum.Nffs_PdfEncryption}`,
        type: `${SubFeatureControlTypeEnum.Checkbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'pdfEncryption_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Nffs_PdfEncryption}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_scanNetworkFolder}`,
        settingsName: `${ConstraintsSettingsNameEnum.Nffs_PdfEncryption}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.Network_Folder_Notification_Settings}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Nffs_PdfEncryption}`,
      },
      {
        id: `${SubfeatureIdEnum.Nffs_BlankPageSuppression}`,
        type: `${SubFeatureControlTypeEnum.Checkbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'blankPageSuppression_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Nffs_BlankPageSuppression}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_scanNetworkFolder}`,
        settingsName: `${ConstraintsSettingsNameEnum.Nffs_BlankPageSuppression}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.Network_Folder_Notification_Settings}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Nffs_BlackPageSuppression}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Email_FileSetting}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Emfs_FileName_Prefix}`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'emfs_FileNamePrefix_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Emfs_FileNamePrefix}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_ScanEmail}`,
        settingsName: `${ConstraintsSettingsNameEnum.Emfs_FileNamePrefix}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.EmailAddressAndMessageSetting}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Emfs_FileName_Prefix}`,
      },
      {
        id: `${SubfeatureIdEnum.Emfs_Default_FileName}`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'emfs_DefaultFileName_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Emfs_DefaultFileName}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_ScanEmail}`,
        settingsName: `${ConstraintsSettingsNameEnum.Emfs_DefaultFileName}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.EmailAddressAndMessageSetting}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Nfss_DefaultFileName}`,
      },
      {
        id: `${SubfeatureIdEnum.Emfs_UserEditable}`,
        type: `${SubFeatureControlTypeEnum.Checkbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'emfs_UserEditable_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Emfs_UserEditable}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_ScanEmail}`,
        settingsName: `${ConstraintsSettingsNameEnum.Emfs_UserEditable}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.EmailAddressAndMessageSetting}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Emfs_UserEditable}`,
      },
      {
        id: `${SubfeatureIdEnum.Emfs_FileNameSuffix}`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'emfs_FileNameSuffix_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Emfs_FileNameSiffix}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_ScanEmail}`,
        settingsName: `${ConstraintsSettingsNameEnum.Emfs_FileNameSufffix}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.EmailAddressAndMessageSetting}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Emfs_FileNameSuffix}`,
      },
      {
        id: `${SubfeatureIdEnum.Emfs_FileNamePreview}`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'emfs_FileNamePreview_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Emfs_FileNamePreview}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_ScanEmail}`,
        settingsName: `${ConstraintsSettingsNameEnum.Emfs_FileNamePrefix}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.EmailAddressAndMessageSetting}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Emfs_FileNamePreview}`,
      },
      {
        id: `${SubfeatureIdEnum.Emfs_OriginalSize}`,
        type: `${SubFeatureControlTypeEnum.Dropbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'emfs_OriginalSize_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Emfs_OriginalSize}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_ScanEmail}`,
        settingsName: `${ConstraintsSettingsNameEnum.Emfs_OriginalSize}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.EmailAddressAndMessageSetting}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Emfs_OriginalSize}`,
      },
      {
        id: `${SubfeatureIdEnum.Emfs_OriginalSlides}`,
        type: `${SubFeatureControlTypeEnum.Dropbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'emfs_OriginalSlides_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Emfs_OriginalSlides}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_ScanEmail}`,
        settingsName: `${ConstraintsSettingsNameEnum.Emfs_OriginalSlides}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.EmailAddressAndMessageSetting}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Emfs_OriginalSlides}`,
      },
      {
        id: `${SubfeatureIdEnum.Emfs_DefaultColor_Preference}`,
        type: `${SubFeatureControlTypeEnum.Dropbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'emfs_DefaultColorPreference_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Emfs_DefaultColorPreference}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_ScanEmail}`,
        settingsName: `${ConstraintsSettingsNameEnum.Emfs_DefaultColorPreference}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.EmailAddressAndMessageSetting}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Emfs_DefaultColorPreference}`,
      },
      {
        id: `${SubfeatureIdEnum.Emfs_DefaultOutputQuality}`,
        type: `${SubFeatureControlTypeEnum.Dropbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'emfs_DefaultOutputQuality_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Emfs_DefaultOutputQuality}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_ScanEmail}`,
        settingsName: `${ConstraintsSettingsNameEnum.Emfs_DefaultOutputQuality}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.EmailAddressAndMessageSetting}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Emfs_DefaultOutputQuality}`,
      },
      {
        id: `${SubfeatureIdEnum.Emfs_DefaultResolution}`,
        type: `${SubFeatureControlTypeEnum.Dropbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'emfs_DefaultResolution_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Emfs_DefaultResolution}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_ScanEmail}`,
        settingsName: `${ConstraintsSettingsNameEnum.Emfs_DefaultResolution}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.EmailAddressAndMessageSetting}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Emfs_DefaultResolution}`,
      },
      {
        id: `${SubfeatureIdEnum.Emfs_DefaultFileType}`,
        type: `${SubFeatureControlTypeEnum.Dropbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'emfs_DefaultFileType_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Emfs_DefaultFileType}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_ScanEmail}`,
        settingsName: `${ConstraintsSettingsNameEnum.Emfs_DefaultFileType}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.EmailAddressAndMessageSetting}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Emfs_DefaultFileType}`,
      },
      {
        id: `${SubfeatureIdEnum.Emfs_PdfCompression}`,
        type: `${SubFeatureControlTypeEnum.Dropbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'emfs_PdfCompression_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Emfs_PdfCompression}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_ScanEmail}`,
        settingsName: `${ConstraintsSettingsNameEnum.Emfs_PdfCompression}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.EmailAddressAndMessageSetting}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Emfs_PdfCompression}`,
      },
      {
        id: `${SubfeatureIdEnum.Emfs_PdfEncryption}`,
        type: `${SubFeatureControlTypeEnum.Checkbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'emfs_PdfEncryption_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Emfs_PdfEncryption}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_ScanEmail}`,
        settingsName: `${ConstraintsSettingsNameEnum.Emfs_PdfEncryption}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.EmailAddressAndMessageSetting}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Emfs_PdfEncryption}`,
      },
      {
        id: `${SubfeatureIdEnum.Emfs_BlankPageSuppression}`,
        type: `${SubFeatureControlTypeEnum.Checkbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'emfs_BlankPageSuppression_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Emfs_BlankPageSuppression}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_ScanEmail}`,
        settingsName: `${ConstraintsSettingsNameEnum.Emfs_BlankPageSuppression}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.EmailAddressAndMessageSetting}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Emfs_BlackPageSuppression}`,
      },
    ],
  },
];
