import { FeatureIdEnum } from '../config/FeatureEnums';
import {
  CORSIds,
  AutoSendWebAddress,
  ApplicationIds,
  AutoSendEmailAddress,
  EmailAddMsgSettingTableIds,
  IPconfigPrecedenceTableIds,
  LDAPBindAndSearchRootIds,
  OutgoingServersTableIds,
  SleepScheduleHolidayTableIds,
  SleepScheduleWeeklyTableIds,
  SubfeatureIdEnum,
  AppDeploymentTableIds,
  TrayAdminTableIds,
  PermissionSetsTableIds,
  DelayVeryLowMessageTableIds,
  ControlPanelTableIds,
  FaxMethodIds
} from '../config/SubFeatureEnums';

// This is the mapping of subfeatureId to tableIds and label for the table Header
const createMapping = (ids, label) => ({ ids, label });

export const tableIdMapping = {
  [SubfeatureIdEnum.Sleep_Schedule_Weekly]: createMapping(
    SleepScheduleWeeklyTableIds,
    `${FeatureIdEnum.Sleep_Schedule}`,
  ),
  [SubfeatureIdEnum.Sleep_Schedule_Holiday]: createMapping(
    SleepScheduleHolidayTableIds,
    `${FeatureIdEnum.Sleep_Schedule}`,
  ),
  [SubfeatureIdEnum.EmailAddress_MessageSetting]: createMapping(
    EmailAddMsgSettingTableIds,
    `${FeatureIdEnum.EmailAddress_MessageSetting}`,
  ),
  [SubfeatureIdEnum.Outgoing_Server_List_Email]: createMapping(
    OutgoingServersTableIds,
    `${FeatureIdEnum.Outgoing_Servers}`,
  ),
  [SubfeatureIdEnum.Outgoing_Server_List_InternetFax]: createMapping(
    OutgoingServersTableIds,
    `${FeatureIdEnum.Outgoing_Servers}`,
  ),
  [SubfeatureIdEnum.Outgoing_Server_List_AutomatedEmail]: createMapping(
    OutgoingServersTableIds,
    `${FeatureIdEnum.Outgoing_Servers}`,
  ),
  [SubfeatureIdEnum.Outgoing_Server_List_AutoSend]: createMapping(
    OutgoingServersTableIds,
    `${FeatureIdEnum.Outgoing_Servers}`,
  ),
  [SubfeatureIdEnum.CORSTrustedSite]: createMapping(CORSIds, `${FeatureIdEnum.CORS}`),
  [SubfeatureIdEnum.Non_Default_Applications]: createMapping(
    ApplicationIds,
    `${FeatureIdEnum.Restrict_Color}`,
  ),
  [SubfeatureIdEnum.LDAP_Bind_And_Search_Root]: createMapping(
    LDAPBindAndSearchRootIds,
    `${FeatureIdEnum.LDAP_SignIn_Setup}`,
  ),
  [SubfeatureIdEnum.IP_Config_Precedence]: createMapping(
    IPconfigPrecedenceTableIds,
    `${FeatureIdEnum.IP_Config_Precedence}`,
  ),
  [SubfeatureIdEnum.App_Deployment_AppInstallation]: createMapping(
    AppDeploymentTableIds,
    `${FeatureIdEnum.Web_App_Deployment}`,
  ),
  [SubfeatureIdEnum.Tray_Admin]: createMapping(
    TrayAdminTableIds,
    `${FeatureIdEnum.TrayAdministration}`,
  ),
  [SubfeatureIdEnum.PermissionSets]: createMapping(
    PermissionSetsTableIds,
    `${FeatureIdEnum.AccessControl}`,
  ),
  [SubfeatureIdEnum.Delay_VeryLow_Message]: createMapping(
    DelayVeryLowMessageTableIds,
    `${FeatureIdEnum.Delay_VeryLow_Message}`),
  [SubfeatureIdEnum.ControlPanelAccessTable]: createMapping(ControlPanelTableIds,
    `${FeatureIdEnum.AccessControl}`,
  ),
  [SubfeatureIdEnum.EWS_AccessControlTable]: createMapping(ControlPanelTableIds,
     `${FeatureIdEnum.AccessControl}`),
  [SubfeatureIdEnum.Fax_Send_Method]: createMapping(FaxMethodIds,
    `${FeatureIdEnum.Fax_Send_Settings}`),
  [SubfeatureIdEnum.Fax_Send_CommonJob]: createMapping(FaxMethodIds,
    `${FeatureIdEnum.Fax_Send_Settings}`),
  [SubfeatureIdEnum.Fax_Receive_Method]: createMapping(FaxMethodIds,
      `${FeatureIdEnum.Fax_Receive_Settings}`),
  [SubfeatureIdEnum.Fax_Receive_CommonJob]: createMapping(FaxMethodIds,
    `${FeatureIdEnum.Fax_Receive_Settings}`),
    [SubfeatureIdEnum.AutoSend_Web_Addresses]: createMapping(AutoSendWebAddress, `${FeatureIdEnum.AutoSend}`),
    [SubfeatureIdEnum.AutoSend_Email_Addresses]: createMapping(AutoSendEmailAddress, `${FeatureIdEnum.AutoSend}`),
};

// This is the mapping of Email Address Message Setting table

export const tableValueIds = [
  'fromSignInRequired',
  'from.emailAddress',
  'from.displayName',
  'toListSignInRequired',
  'ccListSignInRequired',
  'bccListSignInRequired',
  'subject',
  'body',
];
export const tableValueIdsFaxSendMethod = [
  'analogFaxOperation.errorCorrectionModeEnabled',
  'analogFaxOperation.compressionJBIGEnabled',
];
export const tableValueIdsFaxReceiveMethod = [
  'analogFaxReceiveSettings.ringsToAnswer',
  'analogFaxReceiveSettings.ringerVolume',
];
export const tableValueIdsFaxSendCommonJob = [
  'pipelineOptions.imageModifications.backgroundCleanup',
  'pipelineOptions.imageModifications.exposure',
  'pipelineOptions.imageModifications.contrast',
  'pipelineOptions.imageModifications.sharpness',
  'pipelineOptions.notification.notificationCondition',
  'pipelineOptions.notification.notificationMode',
  'pipelineOptions.notification.notificationAddress',
];
export const tableValueIdsFaxReceiveCommonJob = [
  'imageModifications.outputCanvasMediaId',
  'pipelineOptions.notification.notificationCondition',
  'pipelineOptions.notification.notificationMode',
  'pipelineOptions.notification.notificationAddress',
]

export const editableFieldsMapping = {
  fromSignInRequired: 'isFromEditable',
  toListSignInRequired: 'toListIsEditable',
  ccListSignInRequired: 'ccListIsEditable',
  bccListSignInRequired: 'bccListIsEditable',
  subject: 'isSubjectEditable',
  body: 'isBodyEditable',
};

export const localizeValueMapping = [
  'fromSignInRequired',
  'toListSignInRequired',
  'ccListSignInRequired',
  'bccListSignInRequired',
];
