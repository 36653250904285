export enum FleetSvcPolicyAttributeId {
  Asset_Number = 'asset-number',
  Auto_Send = 'auto-send',
  Company_Name = 'company-name',
  Contact_Person = 'contact-person',
  Device_Location = 'device-location',
  Device_Name = 'device-name',
  Duplex_Binding = 'duplex-binding',
  Outgoing_Servers = 'outgoing-servers',
  Outgoing_Server_List = 'outgoing-email-server-list',
  Outgoing_Server_List_Email = 'outgoing-email-server-list-email',
  Outgoing_Server = 'outgoing-servers',
  Outgoing_Server_List_InternetFax = 'outgoing-email-server-list-fax',
  Outgoing_Server_List_AutomatedEmail = 'outgoing-email-server-list-automatedEmail',
  Outgoing_Server_List_AutoSend = 'outgoing-email-server-list-autoSend',
  Outgoing_Server_Name = 'address',
  Outgoing_Server_Port = 'port',
  Outgoing_Server_Split = 'splitEmail',
  Outgoing_Server_SMTP = 'ssl',
  Outgoing_Server_Valid_Cert = 'validateCert',
  Outgoing_Server_Req_Auth = 'auth',
  Outgoing_Server_CredType = 'credential',
  Outgoing_Server_User_Name = 'user',
  Outgoing_Server_Password = 'password',
  Outgoing_Server_Send_Email = 'email',
  Outgoing_Server_Fax = 'fax',
  Outgoing_Server_Auto_AutomatedEmail = 'automatedEmail',
  Outgoing_Server_Auto_Send = 'autoSend',
  Tray_Admin = 'tray-admin',
  Control_Panel_Language = 'ctrl-panel-language',
  CORS = 'cors',
  Access= 'access-ctrl',
  PermissionSets = 'device-access-ctrl',
  AccessControlTable = 'device-access-ctrl',
  EWSSignIn = 'device-access-ctrl',
  AutoSignOut = 'device-access-ctrl',
  Home_Screen_App_Fs4 = 'home-screen-app-fs4',
  Home_Screen_Custom = 'home-screen-custom',
  Home_Screen_Language = 'home-screen-language',
  Date_Format = 'date-format',
  Date_And_Time = 'date-and-time',
  Time_Format = 'time-format',
  Time_Zone = 'time-zone',
  Sleep_Delay = 'sleep-delay',
  Sleep_Schedule = 'sleep-schedule',
  Sleep_Settings = 'sleep-settings',
  Default_Media_Size = 'def-media-size',
  Default_Media_Type = 'def-media-type',
  Def_Print_Options = 'def-print-options',
  Delay_Low_Supply_Msg = 'delay-low-supply-msg',
  Device_Announcement_Agent = 'device-announcement',
  Cartridge_Policy = 'cartridge-policy',
  Cartridge_VeryLowAction_Black = 'very-low-action-black',
  Cartridge_VeryLowAction_Color = 'very-low-action-color',
  Delay_VeryLowMessage = 'delay-low-supply-msg',
  Device_User_Accounts = 'device-user-accounts',
  Bt_Low_Energy = 'bt-low-energy.',
  Ftp_print = 'ftp_print',
  EWS_Config = 'ews-config',
  Primary_Dns_Server = 'dns-server-primary',
  Secondary_Dns_Server = 'dns-server-secondary',
  Domain_Name = 'domain-name',
  IP_Config_Precedence = 'configuration-precedence',
  Ipv4_Info = 'ipv4-info',
  Wifi0_info = 'wifi0-info',
  Subnet_mask = 'subnet-mask',
  Wifi0_SubnetMask = 'Wifi0-subnet-mask',
  Gate_way = 'gate-way',
  Wifi0_Gate_way = 'gate-way',
  Ipv6_Info = 'ipv6-info',
  Wifi0_IPv6Info = 'ipv6-info',
  Link_Setting = 'link-setting',
  Snmp_Traps = 'snmp-traps',
  Nw_Features = 'nw-features',
  Dhcp_v4_Compliance = 'dhcp-v4-compliance',
  Ews_Language = 'ews-language',
  Time_Services = 'time-services',
  Ews_Access = 'ews-access',
  Ews_Admin_Password = 'ews-password',
  EWS_Admin_Password_Check = 'ews-admin-password-check',
  PJL_Password = 'pjl-password',
  LDAP_Setup = 'ldap-setup',
  SNMP_V1_Enable = 'snmp-v1-enable',
  SNMPV1V2_Access_Option = 'snmpv1v2_accessoption',
  SNMPV1V2_RW_Access = 'snmp-v1-v2',
  SNMPV1V2_Default_Public = 'snmp-v1-v2',
  SNMPV1V2_Read_Community_Name_Password = 'snmp-v1-v2',
  SNMPV1V2_RW_Community_Name_Password = 'snmp-v1-v2',
  SNMPV3 = 'snmp-v3',
  SNMPV3_KeyType = 'snmp_v3_keytype',
  Remote_Configuration_Password = 'remote-cfg-password',
  Restrict_Color = 'restrict-color',
  Service_Access_Code = 'svc-access-code',
  AirPrint_Fax = 'airprint-fax',
  AirprintScan = 'airprint-scan',
  Airprint_SecureScan = 'airprint-secure-scan',
  DHCPv4_FQDN_Compliance_With_RFC_4702 = 'dhcp-v4-compliance',
  CA_Certificate = 'ca-certificate',
  ID_Certificate = 'id-certificate',
  ID_Certificate_Password = 'id-password',
  EmailAddress_MessageSetting = 'email-message',
  SaveTo_SharePoint = 'save-to-share-point',
  SendTo_Email = 'save-to-email',
  SaveTo_NetworkFolder = 'save-to-network-folder',
  Smart_CloudPrint = 'smart-cloud-print',
  HPJetAdvantage_MoreApps = 'hp-jet-adv',
  Dot1x_802_1x_auth = '802-1x-auth',
  Dot1x_802_1x_auth_wireless = '802-1x-auth-wifi',
  Wifi_Direct = 'wifi-direct',
  App_Deployment = 'app-config',
  StoredData_PinLimit = 'pin-protection',
  HP_WebServices = 'hp-web-svc',
  Toner_Collection_Unit = 'very-full-action-toner-collection',
  Cartridge_Protection = 'cartridge-protection',
  Home_Screen_Application = 'home-screen-custom',
  Home_Screen_LanguageSelection  = 'home-screen-language',
  Home_Screen_AlternateKeyboard = 'home-screen-language',
  Emergency_Client_Mode = 'home-screen-custom',
  Cartridge_Threshold_Black = 'cartridge-threshold-black',
  Cartridge_Threshold_Cyan = 'cartridge-threshold-cyan',
  Cartridge_Threshold_Magenta = 'cartridge-threshold-magenta',
  Cartridge_Threshold_Yellow = 'cartridge-threshold-yellow',
  Drum_Threshold_Black = 'drum-threshold-black',
  Drum_Threshold_Cyan = 'drum-threshold-cyan',
  Drum_Threshold_Magenta = 'drum-threshold-magenta',
  Drum_Threshold_Yellow = 'drum-threshold-yellow',
  Fuser_Kit_Threshold = 'fuser-kit-threshold',
  Use_Requested_Tray = 'use-requested-tray',
  Override_A4_Letter = 'override-letter-a4',
  ManualFeedPrompt = 'manual-feed-prompt',
  MaintenanceKit_threshold= 'very-low-action-maintenance-kit',
  TransferKit_Threshold = 'transfer-kit-threshold',
  Tray1_Mode_Manual_feed = 'manual-feed',
  Size_Type_Prompt = 'size-type-prompt',
  Proxy_Server = 'proxy-server',
  Bootloader_Password = 'bootloader-password',
  Email_Scan_Settings = 'email-scan',
  Online_Solutions = 'online-solutions',
  Digital_Sending_Service =  'digital-sending',
  Fax_Header_Settings_Phone_Number = 'fax-header-settings-phone-number',
  Fax_Header_Settings_Company_Name = 'fax-header-settings-company-name',
  Fax_Header_Settings_Location = 'fax-header-settings-location',
  Pc_Fax_Send = 'pc-fax-send',
  Host_Usb_Plug_And_Play = 'host-usb-pnp',
  Tcp_Ip_Configuration_Method = 'tcpip-config',
  Hp_Jetdirect_Xml_Services ='jd-xml-svc',
  Temporary_Job_Storage_Limit = 'job-storage-limit-temporary',
  SMB_CIFS = 'smb-cifs',
  FIPS_140_Compliance_Library = 'fips-140-compliance',
  Retain_Temporary_Print_Jobs_after_Reboot = 'retain-jobs-after-reboot',
  Web_Scan = 'web-scan',
  Fuser_Kit_Very_Low_Action = 'very-low-action-fuser-kit',
  Supply_Low_Alerts = 'supply-low-alerts',
}

export enum FleetSvcCustomValue {
  PERMISSION = 'snmp-v1-v2.permission',
  READONLY = 'snmp-v1-v2.permission.read-only',
  READWRITE = 'snmp-v1-v2.permission.read-write',
  DISABLE = 'snmp-v1-v2.permission.disable',
  PUBLIC = 'snmp-v1-v2.def-public',
  MD5 = 'snmp-v3.encryption-algorithm.md5',
  SHA1 = 'snmp-v3.encryption-algorithm.sha1',
  LDAP_SETUP_CREDENTIAL_TRUE = 'ldap-setup.credential.device-user',
  LDAP_SETUP_CREDENTIAL_FALSE = 'ldap-setup.credential.admin',
  DOT1X_AUTHENTICATION_ONAUTHENTICATIONFAILURE_TRUE = '802-1x-auth.on-failure.block',
  DOT1X_AUTHENTICATION_ONAUTHENTICATIONFAILURE_FALSE = '802-1x-auth.on-failure.connect',
  OUTGOING_SERVERMODE_APPEND = 'outgoing-servers.mode.append',
  OUTGOING_SERVERMODE_OVERRIDE = 'outgoing-servers.mode.overwrite',
  WIFIDIRECT_CONNECTION_METHOD_AUTO = 'wifi-direct.method.auto',
  WIFIDIRECT_CONNECTION_METHOD_MANUAL = 'wifi-direct.method.manual',
  WIFIDIRECT_CONNECTION_METHOD_ADVANCED = 'wifi-direct.method.advanced',
  WIFIDIRECT_PASSWORD = 'wifi-direct.password',
  DOT1X_AUTHENTICATION_WIRELESS_ENCRYPTIONSTRENGHT_LOW = '802-1x-auth.encryption-strength.low',
  DOT1X_AUTHENTICATION_WIRELESS_ENCRYPTIONSTRENGHT_MEDIUM = '802-1x-auth.encryption-strength.medium',
  DOT1X_AUTHENTICATION_WIRELESS_ENCRYPTIONSTRENGHT_HIGH = '802-1x-auth.encryption-strength.high',
  DOT1X_AUTHENTICATION_WIRED_ENCRYPTIONSTRENGHT_LOW = '802-1x-auth.encryption-strength.low',
  DOT1X_AUTHENTICATION_WIRED_ENCRYPTIONSTRENGHT_MEDIUM = '802-1x-auth.encryption-strength.medium',
  DOT1X_AUTHENTICATION_WIRED_ENCRYPTIONSTRENGHT_HIGH = '802-1x-auth.encryption-strength.high',
}
 