import { SubfeatureIdEnum } from '../../config/SubFeatureEnums';
import { EmailAddressMessageHelper } from '../../helper/table/EmailAddressMessageHelper';
import { LdapSigninSetupHelper } from '../../helper/table/LdapSigninSetupHelper';
import { OutgoingServersHelper } from '../../helper/table/OutgoingServersHelper';
import LocalizeHelper from '../../helper/LocalizeHelper';
import { SleepScheduleHelper } from '../../helper/table/SleepScheduleHelper';
import { DelayVeryLowMessageHelper } from '../../helper/table/DelayVeryLowMessageHelper';
import { ControlPanelTableHelper } from '../../helper/table/ControlPanelTableHelper';
import { PermissionSetsTableDataHelper } from '../../helper/table/PermissionSetsTableDataHelper';
import { EWSAccessControlTableHelper } from '../../helper/table/EWSAccessControlTableHelper';
import { FaxTableHelper } from '../../helper/table/FaxTableHelper';
import { AutoSendEmailAddressesHelper } from '../../helper/table/AutoSendEmailAddressesHelper';

const useTableBody = (id, subFeatureId, tableHead, value,deviceConstraints, deviceCacheResult) => { 
  let tableBody;
  const { getDeviceCommonLocString } = LocalizeHelper();
  const tableBodyMapping = {
    [SubfeatureIdEnum.EmailAddress_MessageSetting]: (value) =>
      EmailAddressMessageHelper(subFeatureId, value).emailAddressMessageData,
    [SubfeatureIdEnum.Outgoing_Server_List_Email]: (value) =>
      OutgoingServersHelper(subFeatureId, value).outgoingServersData,
    [SubfeatureIdEnum.LDAP_Bind_And_Search_Root]: (value) =>
      LdapSigninSetupHelper(subFeatureId, value).LdapSigninData,
    [SubfeatureIdEnum.Sleep_Schedule_Weekly]: (value) =>
      SleepScheduleHelper(subFeatureId, value).SleepScheduleData,
    [SubfeatureIdEnum.Sleep_Schedule_Holiday]: (value) =>
      SleepScheduleHelper(subFeatureId, value).SleepScheduleData,
    [SubfeatureIdEnum.Delay_VeryLow_Message]: (value) =>
      DelayVeryLowMessageHelper(id, value).DelayVeryLowMessageData,
    [SubfeatureIdEnum.PermissionSets]: (value) =>
      PermissionSetsTableDataHelper(subFeatureId, value).PermissionSetsTableData,
    [SubfeatureIdEnum.ControlPanelAccessTable]: (value) =>
      ControlPanelTableHelper(subFeatureId, value, tableHead, deviceCacheResult).ControlPanelTableData,
    [SubfeatureIdEnum.EWS_AccessControlTable]: (value) =>
      EWSAccessControlTableHelper(subFeatureId, value,tableHead, deviceCacheResult).EWSAccessControlTableData,
    [SubfeatureIdEnum.Fax_Send_Method] : (value) => 
      FaxTableHelper(subFeatureId, value, deviceConstraints).FaxSendTableData,
    [SubfeatureIdEnum.Fax_Send_CommonJob] : (value) =>
      FaxTableHelper(subFeatureId, value, deviceConstraints).FaxSendTableData,
    [SubfeatureIdEnum.Fax_Receive_Method] : (value) =>
      FaxTableHelper(subFeatureId, value, deviceConstraints).FaxSendTableData,
    [SubfeatureIdEnum.Fax_Receive_CommonJob] : (value) =>
      FaxTableHelper(subFeatureId, value, deviceConstraints).FaxSendTableData,
    [SubfeatureIdEnum.AutoSend_Email_Addresses]: (value) =>
      AutoSendEmailAddressesHelper(subFeatureId, value).AutoSendEmailAddressesData,
  };

  const transformItemBasedOnSubFeatureId = (item, subFeatureId, id, getDeviceCommonLocString) => {
    if (subFeatureId === SubfeatureIdEnum.Non_Default_Applications || subFeatureId === SubfeatureIdEnum.Tray_Admin) {
      return Object.keys(item).reduce((transformedObject, key) => {
        const value = item[key];
        const localizedValue = getDeviceCommonLocString(
          id,
          subFeatureId === SubfeatureIdEnum.Tray_Admin ? String(key + '_' + value) : String(value)
        ) || String(value);
        transformedObject[key] = localizedValue;
        return transformedObject;
      }, {});
    }
    return item;
  };

  const getCommonTableBody = (valuex) => {
    return valuex.map((item) => {
      if (typeof item === 'object' && item !== null && !Array.isArray(item)) {
        return transformItemBasedOnSubFeatureId(item, subFeatureId, id, getDeviceCommonLocString);
      } else {
        return { [tableHead[0]?.id]: item };
      }
    });
  };
  tableBody = tableBodyMapping[subFeatureId]
    ? tableBodyMapping[subFeatureId](value)
    : getCommonTableBody(value);
  return { tableBody };
};

export default useTableBody;


