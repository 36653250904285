import { FeatureIdEnum } from '../../../../../config/FeatureEnums';
import LocalizeHelper from '../../../../../helper/LocalizeHelper';

const ThresholdAction = () => {
    const { getDeviceConfigurationsLocString } = LocalizeHelper();
    const lowThresholdIdMapping = {
        [FeatureIdEnum.Cartridge_Threshold_Black]: 1,
        [FeatureIdEnum.Cartridge_Threshold_Cyan]: 2,
        [FeatureIdEnum.Cartridge_Threshold_Magenta]: 3,
        [FeatureIdEnum.Cartridge_Threshold_Yellow]: 4,
        [FeatureIdEnum.Drum_Threshold_Black]: 5,
        [FeatureIdEnum.Drum_Threshold_Cyan]: 6,
        [FeatureIdEnum.Drum_Threshold_Magenta]: 7,
        [FeatureIdEnum.Drum_Threshold_Yellow]: 8,
        [FeatureIdEnum.Fuser_Kit_Threshold]: 109,
        [FeatureIdEnum.MaintenanceKit_threshold]: 111,
        [FeatureIdEnum.Transfer_Kit_threshold]: 100
    };

    const veryLowActionIdMapping = {
        [FeatureIdEnum.Fuser_Kit_Very_Low_Action]: 109,
    };

    const ThresholdActionHelper = (id, value) => {

        const lowThresholdTargetId = lowThresholdIdMapping[id];
        const veryLowActionTargetId = veryLowActionIdMapping[id];
        let result = true;

        if(lowThresholdTargetId !== undefined && Array.isArray(value))
        {
            result = value?.some((item) => item.id === lowThresholdTargetId)
        }
        else if(veryLowActionTargetId !== undefined && Array.isArray(value))
        {
            result = value?.some((item) => item.supplySlotIdentifier === veryLowActionTargetId)
        }

        return result;
    };

    const LowThresholdValue = (id, value) => {

        const targetId = lowThresholdIdMapping[id];

        const result = value
            .filter((item) => item.id === targetId)
            .map((item) => item.threshold);

        return result;
    };

    const VeryLowActionValue = (id, value, subId) => {

        const targetId = veryLowActionIdMapping[id];

        const result = value
            .filter((item) => item.supplySlotIdentifier === targetId)
            .map((item) => item.veryLowAction);

        const localisedValue = getDeviceConfigurationsLocString(id, result, subId)

        return localisedValue;
    };

    return{
        ThresholdActionHelper,
        LowThresholdValue,
        VeryLowActionValue,
    };
};

export default ThresholdAction;
