export enum FeatureIdEnum {
  Asset_Number = 'fe-asset-number',
  AutoSend = 'fe-auto-send',
  Company_Name = 'fe-company-name',
  Contact_Person = 'fe-contact-person',
  CORS = 'fe-cors',
  AccessControl = 'fe-access-control',
  Device_Location = 'fe-device-location',
  Device_Name = 'fe-device-name',
  Duplex_Binding = 'fe-duplex-binding',
  Outgoing_Servers = 'fe-outgoing-servers',
  Date_Time_Format = 'fe-date-time-format',
  Time_Zone = 'fe-time-zone',
  Ews_Language = 'fe-ews-language',
  Time_Service = 'fe-time-service',
  Ews_Admin_Password = 'fe-ews-password',
  LDAP_SignIn_Setup = 'fe-ldap-signin-setup',
  PJL_Password = 'fe-pjl-password',
  Remote_Configuration_Password = 'fe-remote-configuration-password',
  SNMP_V1_V2 = 'fe-snmp-v1-v2',
  SNMP_V3 = 'fe-snmp-v3',
  Control_Panel_Language = 'fe-ctrl-panel-language',
  Date_And_Time = 'fe-date-and-time',
  Sleep_Delay = 'fe-sleep-delay',
  Sleep_Schedule = 'fe-sleep-schedule',
  Sleep_Settings = 'fe-sleep-settings',
  Service_Access_Code = 'fe-service-access-code',
  Cartridge_Policy = 'fe-cartridge-policy',
  Cartridge_VeryLowAction_Black = 'fe-cartridge-verylowaction-black',
  Cartridge_VeryLowAction_Color = 'fe-cartridge-verylowaction-color',
  Delay_VeryLow_Message = 'fe-delayverylow_messsage',
  Airpint_fax = 'fe-airprint-fax',
  Airprint_Scan = 'fe-airprint-scan',
  Airprint_SecureScan = 'fe-airprint-secure-scan',
  DHCPv4_FQDN_Compliance_With_RFC_4702 = 'fe-dhcpv4-fqdn-compliance-with-rfc-4702',
  Link_Settings = 'fe-link-Settings',
  Bt_Low_Energy = 'fe-bluetooth-low-energy',
  Ftp_print = 'fe-ftp-printing',
  EWS_Config = 'fe-ews-config',
  Dns_Server = 'fe-dns-server',
  IP_Config_Precedence = 'fe-ip-config-precedence',
  IPv4_information = 'fe-ipv4-information',
  IPv6_Information = 'fe-ipv6-information',
  Default_Media_Size = 'fe-default-media-size',
  Default_Media_Type = 'fe-default-media-type',
  Work_Path_Enablement = 'fe-work-path-enablement',
  Web_App_Deployment = 'fe-web-app-deployment',
  CA_Certificate = 'fe-ca-certificate',
  ID_Certificate = 'fe-id-certificate',
  EmailAddress_MessageSetting = 'fe-emailAddress-messageSetting',
  SaveTo_SharePoint = 'fe-saveto-sharePoint',
  SendTo_Email = 'fe-sent-email',
  SaveTo_NetworkFolder = 'fe-saveto-networkFolder',
  Smart_CloudPrint = 'fe-smart-cloud-print',
  HPJetAdvantage_MoreApps = 'fe-hpjetadvantage-moreApps',
  DocumentFeederKit_VeryLowAction = 'fe-document-feeder-kit-very-low-action',
  Dot1x_Authentication = 'fe-dot1x-authentication',
  Dot1x_Authentication_Wireless = 'fe-dot1x-authentication-wireless',
  Wifi_Direct = 'fe-wifi-direct',
  Restrict_Color = 'fe-restrict-color',
  Transfer_Kit_threshold = 'fe-transfer-kit-threshold',
  TrayAdministration = 'fe-tray-administration',
  MaintenanceKit_threshold = 'fe-maintenance-kit-threshold',
  StoredDataPin_Protection = 'fe-stored-data-pin-protection',
  ColorCopyMode = 'fe-color-copy-mode',
  Copy_JobBuild = 'fe-copy-job-build',
  HP_WebServices = 'fe-hp-webservices',
  Toner_Collection_Unit = 'fe-toner-collection-unit',
  Cartridge_Protection = 'fe-cartridge-protection',
  Home_Screen_customization_Futuresmart = 'fe-home-screen-customization-futuresmart',
  Cartridge_Threshold_Black = 'fe-cartridge-threshold-black',
  Cartridge_Threshold_Cyan = 'fe-cartridge-threshold-cyan',
  Cartridge_Threshold_Magenta = 'fe-cartridge-threshold-magenta',
  Cartridge_Threshold_Yellow = 'fe-cartridge-threshold-yellow',
  Drum_Threshold_Black = 'fe-drum-threshold-black',
  Drum_Threshold_Cyan = 'fe-drum-threshold-cyan',
  Drum_Threshold_Magenta = 'fe-drum-threshold-magenta',
  Drum_Threshold_Yellow = 'fe-drum-threshold-yellow',
  Fuser_Kit_Threshold = 'fe-fuser-kit-threshold',
  Home_Screen_Language = 'fe-homeScreen-Language',
  Use_Requested_Tray = 'fe-use-requested-tray',
  Override_A4_Letter = 'fe-override-a4-letter',
  ManualFeedPrompt = 'fe-manual-feed-prompt',
  Tray1_Mode_Manual_feed = 'fe-tray1-mode-manual-feed',
  System_Location = 'fe-system-location',
  System_Contact = 'fe-system-contact',
  Support_Contact = 'fe-support-contact',
  Size_Type_Prompt = 'fe-size-type-prompt',
  ProxyServer = 'fe-proxy-server',
  Bootloader_Password = 'fe-bootloader-password',
  Email_Scan_Settings = 'fe-email-scan-settings',
  Copy_Background_Cleanup = 'fe-copy-background-cleanup',
  Copy_Contrast = 'fe-copy-contrast',
  Copy_Darkness = 'fe-copy-darkness',
  Copy_Optimize_Text_Picture = 'fe-copy-optimize-text-picture',
  Copy_Paper_Tray_Selection = 'fe-copy-paper-tray-selection',
  Copy_Sharpness = 'fe-copy-sharpness',
  Copy_stamp = 'fe-copy-stamp',
  Online_Solutions = 'fe-online-solutions',
  Network_Folder_Notification_Settings = 'fe-network-folder-notification-settings',
  Digital_Sending_Service = 'fe-digital-sending-service',
  Fax_Header_Settings = 'fe-fax-header-settings',
  Pc_Fax_Send = 'fe-pc-fax-send',
  Fax_Send_Settings = 'fe-fax-send-settings',
  Fax_Receive_Settings = 'fe-fax-receive-settings',
  Device_Announcement_Agent = 'fe-device-announcement-agent',
  NetworkFolder_FileSetting = 'fe-network-folder-file-setting',
  Email_FileSetting = 'fe-email-file-setting',
  Host_Usb_Plug_And_Play = 'fe-host-usb-plug-and-play',
  Tcp_Ip_Configuration_Method = 'fe-tcp-ip-configuration-method',
  Hp_Jetdirect_Xml_Services = 'fe-hp-jetdirect-xml-services',
  Temporary_Job_Storage_Limit = 'fe-temporary-job-storage-limit',
  SMB_CIFS = 'fe-smb-cifs',
  FIPS_140_Compliance_Library = 'fe-fips-140-compliance-library',
  Retain_Temporary_Print_Jobs_after_Reboot = 'fe-retain-temporary-print-jobs-after-reboot',
  WebScan = 'fe-webscan',
  Fuser_Kit_Very_Low_Action = 'fe-fuser-kit-very-low-action',
  Supply_Low_Alerts = 'fe-supply-low-alerts',
}
export enum FeatureEntitlementEnum {
  basic = 'basicsecurity',
  advance = 'advancesecurity',
}
export enum FeatureEffectivePolicyEnum {
  controlledPolicy = 'controlledPolicy',
  PartialControlledPolicy = 'partialControlledPolicy',
  relatedPolicy = 'relatedPolicy',
}
