import {
  ConstraintsResourceEnum,
  ConstraintsSettingsNameEnum,
  ConstraintsSubFeatureIdEnum,
} from '../config/ConstraintsEnums';
import { DeviceCacheCdmDataEnum } from '../config/DeviceCacheCdmDataEnums';
import { DeviceCacheServiceGunEnum } from '../config/DeviceCacheServiceGunEnums';
import { FeatureIdEnum } from '../config/FeatureEnums';
import { FleetSvcPolicyAttributeId } from '../config/FleetServiceEnums';
import {
  SubFeatureAccessModeEnum,
  SubFeatureControlTypeEnum,
  SubFeatureCustomModeEnum,
  SubfeatureIdEnum,
  SubfeatureIndentEnum,
} from '../config/SubFeatureEnums';

export const NetworkDataModel = [
  {
    id: `${FeatureIdEnum.Bt_Low_Energy}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Bt_Low_Energy}`,
        attribute: `${FleetSvcPolicyAttributeId.Bt_Low_Energy}.check`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'label',
        info: 'info',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Bt_Low_Energy}`,
        resource: `${ConstraintsResourceEnum.BluetoothConfig}`,
        settingsName: `${ConstraintsSettingsNameEnum.Bt_Low_Energy}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.BleConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.RequestedBeaconingEnabled}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Ftp_print}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Ftp_enable}`,
        attribute: `${FleetSvcPolicyAttributeId.Ftp_print}.check`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Ftp_print}`,
        resource: `${ConstraintsResourceEnum.FtpPrinting}`,
        settingsName: `${ConstraintsSettingsNameEnum.Ftp_print}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.FTPPrintingConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.FTPPrintEnabled}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.CA_Certificate}`,
    warningNotification: true,
    customizable: `${SubFeatureCustomModeEnum.READVIEW}`,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.CA_Certificate}`,
        attribute: `${FleetSvcPolicyAttributeId.CA_Certificate}.file`,
        type: `${SubFeatureControlTypeEnum.File}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
        label: 'selectFile_label',
        info: 'info',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.CA_Certificate}`,
        resource: `${ConstraintsResourceEnum.CA_Certificate}`,
        settingsName: `${ConstraintsSettingsNameEnum.CA_Certificate_File}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.ID_Certificate}`,
    warningNotification: true,
    customizable: `${SubFeatureCustomModeEnum.READVIEW}`,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.ID_Certificate}`,
        attribute: `${FleetSvcPolicyAttributeId.ID_Certificate}.file`,
        type: `${SubFeatureControlTypeEnum.File}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
        label: 'selectFile_label',
        info: 'info',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.ID_Certificate}`,
        resource: `${ConstraintsResourceEnum.ID_Certificate}`,
        settingsName: `${ConstraintsSettingsNameEnum.ID_Certificate_File}`,
      },
      {
        id: `${SubfeatureIdEnum.ID_Certificate_Password}`,
        attribute: `${FleetSvcPolicyAttributeId.ID_Certificate}.password`,
        type: `${SubFeatureControlTypeEnum.Password}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
        label: 'password_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.ID_Certificate}`,
        resource: `${ConstraintsResourceEnum.ID_Certificate}`,
        settingsName: `${ConstraintsSettingsNameEnum.ID_Certificate_Password}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.DHCPv4_FQDN_Compliance_With_RFC_4702}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Dhcp_v4_FQDN_Compliance_With_RFC_4702}`,
        attribute: `${FleetSvcPolicyAttributeId.DHCPv4_FQDN_Compliance_With_RFC_4702}.check`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'label',
        info: 'info',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.DHCPV4withRFC}`,
        resource: `${ConstraintsResourceEnum.AdapterConfigs}`,
        settingsName: `${ConstraintsSettingsNameEnum.DHCPv4withRFC}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.DeviceAdapterConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Eth0IPV4DhcpFqdnRfc4702Compliance}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Dns_Server}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Primary_Dns_Server}`,
        attribute: `${FleetSvcPolicyAttributeId.Primary_Dns_Server}.string `,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'primary_dns_label',
        hint: 'primary_dns_hint',
        help: 'help',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Primary_Dns_Server}`,
        resource: `${ConstraintsResourceEnum.AdapterConfigs}`,
        settingsName: `${ConstraintsSettingsNameEnum.Primary_Dns_Server}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.DeviceAdapterConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Eth0IPV4DnsServerPrimary}`,
      },
      {
        id: `${SubfeatureIdEnum.Secondary_Dns_Server}`,
        attribute: `${FleetSvcPolicyAttributeId.Secondary_Dns_Server}.string `,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'secondary_dns_label',
        hint: 'secondary_dns_hint',
        help: 'help',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Secondary_Dns_Server}`,
        resource: `${ConstraintsResourceEnum.AdapterConfigs}`,
        settingsName: `${ConstraintsSettingsNameEnum.Secondary_Dns_Server}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.DeviceAdapterConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Eth0IPV4DnsServerSecondary}`,
      },
      {
        id: `${SubfeatureIdEnum.Domain_Name}`,
        attribute: `${FleetSvcPolicyAttributeId.Domain_Name}.string `,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'domain_name_label',
        hint: 'domain_name_hint',
        help: 'domain_name_help',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Domain_Name}`,
        resource: `${ConstraintsResourceEnum.AdapterConfigs}`,
        settingsName: `${ConstraintsSettingsNameEnum.Domain_Name}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.DeviceAdapterConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Eth0IPV4DnsServerDomainName}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.IP_Config_Precedence}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.IP_Config_Precedence}`,
        attribute: `${FleetSvcPolicyAttributeId.IP_Config_Precedence}.order `,
        type: `${SubFeatureControlTypeEnum.IPconfigPrecedence}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'config_precedence_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.IP_Config_Precedence}`,
        resource: `${ConstraintsResourceEnum.AdapterConfigs}`,
        settingsName: `${ConstraintsSettingsNameEnum.IP_Config_Precedence}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.Eth0_DeviceAdapterConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Eth0IPConfigPrecedence}`,
      }
    ],
  },
  {
    id: `${FeatureIdEnum.IPv4_information}`,
    configurable: false,
    customizable: `${SubFeatureCustomModeEnum.READVIEW}`,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Ipv4_Info}`,
        attribute: `${FleetSvcPolicyAttributeId.Ipv4_Info}.string `,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'ipv4Info_label',
        hint: 'ipv4Info_hint',
        help: 'help',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Ipv4_Info}`,
        resource: `${ConstraintsResourceEnum.AdapterConfigs}`,
        settingsName: `${ConstraintsSettingsNameEnum.Ipv4_Info}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.DeviceAdapterConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Eth0IPV4Ip}`,
        group: true,
      },
      {
        id: `${SubfeatureIdEnum.Subnet_mask}`,
        attribute: `${FleetSvcPolicyAttributeId.Subnet_mask}.subnet-mask `,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'subnet_mask_label',
        hint: 'subnet_mask_hint',
        help: 'help',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Subnet_mask}`,
        resource: `${ConstraintsResourceEnum.AdapterConfigs}`,
        settingsName: `${ConstraintsSettingsNameEnum.Subnet_Mask}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.DeviceAdapterConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Eth0IPV4subnet}`,
        group: true,
      },
      {
        id: `${SubfeatureIdEnum.Gate_way}`,
        attribute: `${FleetSvcPolicyAttributeId.Gate_way}.string `,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'gate_way_label',
        hint: 'gate_way_hint',
        help: 'help',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Gate_way}`,
        resource: `${ConstraintsResourceEnum.AdapterConfigs}`,
        settingsName: `${ConstraintsSettingsNameEnum.Domain_Name}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.DeviceAdapterConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Eth0IPV4Gateway}`,
        group: true,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.IPv6_Information}`,
    configurable: false,
    customizable: `${SubFeatureCustomModeEnum.READVIEW}`,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Ipv6_Info}`,
        attribute: `${FleetSvcPolicyAttributeId.Ipv6_Info}.check `,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'ipv6_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Ipv6_Info}`,
        resource: `${ConstraintsResourceEnum.AdapterConfigs}`,
        settingsName: `${ConstraintsSettingsNameEnum.Ipv6_info}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.DeviceAdapterConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Eth0IPV6Enabled}`,
        group: true,
      },
      {
        id: `${SubfeatureIdEnum.dhcp_IPv6Policy}`,
        attribute: `${FleetSvcPolicyAttributeId.Ipv6_Info}.dhcp-policy `,
        type: `${SubFeatureControlTypeEnum.Radio}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        indent: SubfeatureIndentEnum.Indent_Level_1,
        label: 'ipv6dhcppolicy_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Ipv6_DhcpPolicy}`,
        resource: `${ConstraintsResourceEnum.AdapterConfigs}`,
        settingsName: `${ConstraintsSettingsNameEnum.IPv6DhcpPolicy}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.DeviceAdapterConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Eth0IPV6Dhcpv6}`,
        group: true,
      },
      {
        id: `${SubfeatureIdEnum.IPv6_DomainName}`,
        attribute: `${FleetSvcPolicyAttributeId.Ipv6_Info}.domain `,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        indent: SubfeatureIndentEnum.Indent_Level_1,
        label: 'ipv6domainname_label',
        hint: 'ipv6domainname_hint',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Ipv6_DomainName}`,
        resource: `${ConstraintsResourceEnum.AdapterConfigs}`,
        settingsName: `${ConstraintsSettingsNameEnum.IPv6DomainName}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.DeviceAdapterConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Eth0DomainName}`,
        group: true,
      },
      {
        id: `${SubfeatureIdEnum.IPv6_PrimaryDNS}`,
        attribute: `${FleetSvcPolicyAttributeId.Ipv6_Info}.primary-dns `,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        indent: SubfeatureIndentEnum.Indent_Level_1,
        label: 'ipv6primarydns_label',
        hint: 'ipv6primarydns_hint',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Ipv6_PrimaryDNS}`,
        resource: `${ConstraintsResourceEnum.AdapterConfigs}`,
        settingsName: `${ConstraintsSettingsNameEnum.IPv6_PrimaryDNS}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.DeviceAdapterConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Eth0IPV6PrimaryDnsServer}`,
        group: true,
      },
      {
        id: `${SubfeatureIdEnum.IPv6_SecondaryDNS}`,
        attribute: `${FleetSvcPolicyAttributeId.Ipv6_Info}.secondary-dns `,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        indent: SubfeatureIndentEnum.Indent_Level_1,
        label: 'ipv6secondarydns_label',
        hint: 'ipv6secondarydns_hint',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Ipv6_SecondaryDNS}`,
        resource: `${ConstraintsResourceEnum.AdapterConfigs}`,
        settingsName: `${ConstraintsSettingsNameEnum.IPv6_SecondaryDNS}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.DeviceAdapterConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Eth0IPV6SecondaryDnsServer}`,
        group: true,
      }
    ],
  },
  {
    id: `${FeatureIdEnum.Link_Settings}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Link_Setting}`,
        attribute: `${FleetSvcPolicyAttributeId.Link_Setting}.mode`,
        type: `${SubFeatureControlTypeEnum.Dropbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'linkSetting_label',
        info: 'linkSetting_info',
        hint: 'linkSetting_hint',
        help: 'linkSetting_ErrorNotSelected',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Link_Setting}`,
        resource: `${ConstraintsResourceEnum.AdapterConfigs}`,
        settingsName: `${ConstraintsSettingsNameEnum.Link_Setting}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.DeviceAdapterConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Eth0LinkConfigConfig}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Airpint_fax}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.AirPrint_Fax}`,
        attribute: `${FleetSvcPolicyAttributeId.AirPrint_Fax}.check`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'airprint_label',
        info: 'airprint_info',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Airprint_fax}`,
        resource: `${ConstraintsResourceEnum.FaxSendConfig}`,
        settingsName: `${ConstraintsSettingsNameEnum.Airpint_Fax}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.FaxSendConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.IppFaxEnabled}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Airprint_Scan}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.AirprintScan}`,
        attribute: `${FleetSvcPolicyAttributeId.AirprintScan}.check`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'airprint_scan_label',
        info: 'airprint_scan_info',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Airprint_scan}`,
        resource: `${ConstraintsResourceEnum.NetworkScanService}`,
        settingsName: `${ConstraintsSettingsNameEnum.Airpint_scan}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.NetworkScanServices}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.AirPrintScan}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Airprint_SecureScan}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Airprint_SecureScan}`,
        attribute: `${FleetSvcPolicyAttributeId.Airprint_SecureScan}.check`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'airprint_securescan_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Airprint_secure_scan}`,
        resource: `${ConstraintsResourceEnum.NetworkScanService}`,
        settingsName: `${ConstraintsSettingsNameEnum.Airpint_Secure_Scan}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.NetworkScanServices}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.AirPrintSecureScan}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.EWS_Config}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.EWS_Config}`,
        attribute: `${FleetSvcPolicyAttributeId.EWS_Config}.check`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'ews_Config_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.EWS_Config}`,
        resource: `${ConstraintsResourceEnum.EWS_Config}`,
        settingsName: `${ConstraintsSettingsNameEnum.EWS_Config}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.EWS_Config}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.EWSConfig}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.System_Location}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.System_Location}`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'system_location_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.System_Location}`,
        resource: `${ConstraintsResourceEnum.JetDirectServices}`,
        settingsName: `${ConstraintsSettingsNameEnum.System_Location}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.JetDirectServices}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.System_Location}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.System_Contact}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.System_Contact}`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'system_contact_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.System_Contact}`,
        resource: `${ConstraintsResourceEnum.JetDirectServices}`,
        settingsName: `${ConstraintsSettingsNameEnum.System_Contact}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.JetDirectServices}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.System_Contact}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Support_Contact}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Support_Contact}`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'support_contact_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Support_Contact}`,
        resource: `${ConstraintsResourceEnum.Device_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.Support_Contact}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.SystemResourceConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Support_Contact}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Tcp_Ip_Configuration_Method}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Tcp_Ip_Configuration_Method}`,
        attribute: `${FleetSvcPolicyAttributeId.Tcp_Ip_Configuration_Method}.mode`,
        type: `${SubFeatureControlTypeEnum.Dropbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'tcp_ip_configuration_method_label',
        hint: 'tcp_ip_configuration_method_hint',
        help: 'tcp_ip_configuration_method_help',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Tcp_Ip_Configuration_Method}`,
        resource: `${ConstraintsResourceEnum.AdapterConfigs}`,
        settingsName: `${ConstraintsSettingsNameEnum.Tcp_Ip_Configuration_Method}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.DeviceAdapterConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Tcp_Ip_Configuration_Method}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.FIPS_140_Compliance_Library}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.FIPS_140_Compliance_Library}`,
        attribute: `${FleetSvcPolicyAttributeId.FIPS_140_Compliance_Library}.check`,
        type: `${SubFeatureControlTypeEnum.Checkbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'fips_140_compliance_library_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.FIPS_140_Compliance_Library}`,
        resource: `${ConstraintsResourceEnum.TLS_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.FIPS_140_Compliance_Library}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.TLSConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.FIPS_140_Compliance_Library}`,
      },
    ],
  },
    {
      id: `${FeatureIdEnum.WebScan}`,
      configurable: false,
      subfeatures: [
        {
          id: `${SubfeatureIdEnum.WebScan_eSCL}`,
         attribute: `${FleetSvcPolicyAttributeId.Web_Scan}.check`,
          type: `${SubFeatureControlTypeEnum.Toggle}`,
          accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
          label: 'WebScan_eSCL_label',
          subFeatureId: `${ConstraintsSubFeatureIdEnum.Web_Scan_eSCL}`,
          resource: `${ConstraintsResourceEnum.NetworkScanService}`,
          settingsName: `${ConstraintsSettingsNameEnum.Airpint_scan}`,
          serviceGunData: `${DeviceCacheServiceGunEnum.NetworkScanServices}`,
          serviceSettingsData: `${DeviceCacheCdmDataEnum.AirPrintScan}`,
        },
        {
          id: `${SubfeatureIdEnum.WebScan_eSCLSecure}`,
         attribute: `${FleetSvcPolicyAttributeId.Web_Scan}.secure`,
          type: `${SubFeatureControlTypeEnum.Toggle}`,
          accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
          label: 'WebScan_eSCLSecure_label',
          subFeatureId: `${ConstraintsSubFeatureIdEnum.Web_Scan_eSCL_Secure}`,
          resource: `${ConstraintsResourceEnum.NetworkScanService}`,
          settingsName: `${ConstraintsSettingsNameEnum.Airpint_Secure_Scan}`,
          serviceGunData: `${DeviceCacheServiceGunEnum.NetworkScanServices}`,
          serviceSettingsData: `${DeviceCacheCdmDataEnum.AirPrintSecureScan}`,
        },
      ],
  }
];
