export const mockDeviceCacheResponse = {
  'com.hp.cdm.service.controlPanel.version.1.resource.configuration': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.controlPanel.version.1.resource.configuration',
    },
    cdmData: {
      currentLanguage: 'en',
      currentKeyboardLayout: 'arabic',
      defaultHomeScreenApplication: "HP"
    },
  }, 
  'com.hp.cdm.service.security.version.1.resource.ldapConfig': {
    cdmMetaData: {
      version: "1.0.0",
      serviceGun: "com.hp.cdm.service.security.version.1.resource.ldapConfig",
      eTag: "71f49fc1-c5d6-4648-a414-f9e98b6ec2a8"
    },
    cdmData: {
      version: "1.0.0",
      enabled: true,
      ldapSignInConfig: {
        serverAddress: "0.0.0.0",
        port: 389,
        secureConnectionEnabled: false,
        ldapBindRoots: [
          "RootName01",
          "RootName02",
          "RootName03",
        ],
        matchLdapNameAttribute: "Attribute01",
        retrieveLdapEmailAttribute: "name01@gmail.com",
        retrieveLdapNameAttribute: "Name01",
        retrieveLdapGroupAttribute: "objectClass",
        enableGroupExactMatch: true,
        productCredentialsEnabled: "true",
        ldapBindPrefix: "Bind prefix temp",
        ldapAdministratorDN: "Admin DN temp",
        ldapAdministratorPassword: ";********"
      }
    }
  },
  'com.hp.cdm.service.security.version.1.resource.systemConfig': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.security.version.1.resource.systemConfig',
    },
    cdmData: {
      version: 'string',
      httpsRedirectionEnabled: 'true',
      csrfPreventionEnabled: 'true',
      corsEnabled: 'true',
      trustedSites: ['string'],
    },
  },
  'com.hp.cdm.service.ews.version.1.resource.configuration': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.ews.version.1.resource.configuration',
    },
    cdmData: {
      languageSource: 'browser',
      selectedLanguage: 'en',
      displayPrintPageOnInformationTab: 'true',
      displayJobLogOnInformationTab: 'true',
      ewsEnabled: 'true',
      sessionTimeout: 1800,
      informationTabAccess: 'false',
      showSupportLinks: 'true',
    },
  },
  'com.hp.cdm.ioConfig.2.resource.adapterConfig.usb': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.ioConfig.version.2.resource.usb_adapterConfig',
    },
    cdmData: {
      enabled: 'false',
      version: '1.0.0',
    },
  },
  'com.hp.cdm.service.security.version.1.resource.accountPolicy': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.security.version.1.resource.accountPolicy',
    },
    cdmData: {
      minPasswordLength: 8,
      minPasswordLengthEnabled: 'true',
      accountLockout: {
        maximumAttempts: 5,
        lockoutInterval: 300,
        resetLockoutCounterInterval: 300,
        enabled: 'false',
      },
      passwordComplexityEnabled: 'true',
      version: '1.0.0',
    },
  },
  'com.hp.cdm.firmwareUpdate.2.resource.configuration': {
    cdmMetaData: {
      version: '2.0.0',
      serviceGun: 'com.hp.cdm.service.firmwareUpdate.version.2.resource.configuration',
    },
    cdmData: {
      pjlUpdateEnabled: 'false',
      version: '1.0.0',
      sha1ValidationEnabled: 'true',
    },
  },
  'com.hp.cdm.platform.stratus.shadow.version.1.resource.devicestatus': {
    cdmMetaData: {
      serviceGun: 'com.hp.cdm.platform.stratus.shadow.version.1.resource.devicestatus',
      version: '1.0.0-beta.1',
    },
    cdmData: {
      scanAdfState: 'NOT_ADF_SCANNER',
      printerState: 'IDLE',
      powerState: 'ACTIVE_NORMAL',
      printerStateReasons: ['NONE'],
      inkSupplyStatus: [
        {
          value: 'OK',
          inkType: 'Y_SUPPLY',
        },
        {
          value: 'OK',
          inkType: 'M_SUPPLY',
        },
        {
          value: 'OK',
          inkType: 'C_SUPPLY',
        },
        {
          value: 'OK',
          inkType: 'K_SUPPLY',
        },
      ],
      printerIsAcceptingJobs: 'YES',
      printerStateSeverity: 'NONE',
      supplyStatus: 'GENUINE_HP',
      connectivityState: {
        lastSeenTime: '2023-12-06T02:17:53.000Z',
        connectionState: 'offline',
      },
      scanState: 'NOT_SCANNER',
      supplyStatusCounter: '1',
      tunnelStatus: 'CONNECTING',
    },
  },
  'com.hp.cdm.faxReceive.1.resource.configuration': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.faxReceive.version.1.resource.configuration',
    },
    cdmData: {
      useHolidaySleepScheduleEnabled: 'false',
      fitToPageEnabled: 'true',
      stampReceivedFaxesEnabled: 'false',
      faxReceiveMethod: 'internalModem',
      faxReceiveEnabled: 'false',
      version: '1.0.0',
    },
  },
  'com.hp.stratus.iot.devicefirmware': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.firmwareUpdate.version.1.resource.autoUpdateConfiguration',
    },
    cdmData: {
      version: '1.0.0',
    },
  },
  'com.hp.cdm.platform.stratus.version.1.resource.deviceidentities': {
    cdmMetaData: {
      serviceGun: 'com.hp.cdm.platform.stratus.version.1.resource.deviceidentities',
      version: '1.0.0',
    },
    cdmData: {
      deviceId: 'AQAAAAGMNhoBVwAAAAHzTplS',
      serialNumber: 'KEYSTONE05',
      deviceUuid: 'eeeeeeee-eeee-eeee-eeee-ace2d3434878',
      modelNumber: 'T3U55A',
      modelName: 'HP Color LaserJet MFP M776',
      firmwareVersion: '2554266_194463',
    },
  },
  'com.hp.cdm.service.storeJobManagement.version.1.resource.configuration': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.storeJobManagement.version.1.resource.configuration',
    },
    cdmData: {
      temporaryJobLimit: 32,
      standardJobRetentionInMinutes: 240,
      temporaryJobRetentionPolicy: 'off',
      defaultSortCriteria: 'name',
      storeJobEnabled: 'true',
      temporaryJobRetentionInMinutes: 240,
      defaultFolderName: 'Untitled',
      version: '1.0.0',
    },
  },
  'com.hp.cdm.service.security.version.1': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.security.version.1',
    },
    cdmData: {
      csrfPreventionEnabled: 'true',
      httpsRedirectionEnabled: 'true',
      version: '1.0.0',
    },
  },
  'com.hp.cdm.service.security.version.1.resource.controlPanelAccess': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.security.version.1.resource.controlPanelAccess',
    },
    cdmData: {
      accessLevel: 'open',
    },
  },
  'com.hp.cdm.storeJobManagement.1.resource.configuration': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.storeJobManagement.version.1.resource.configuration',
    },
    cdmData: {
      temporaryJobLimit: 32,
      standardJobRetentionInMinutes: 240,
      temporaryJobRetentionPolicy: 'off',
      defaultSortCriteria: 'name',
      storeJobEnabled: 'true',
      temporaryJobRetentionInMinutes: 240,
      defaultFolderName: 'Untitled',
      version: '1.0.0',
    },
  },
  'com.hp.cdm.service.fax.version.1.resource.sendConfiguration': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.fax.version.1.resource.sendConfiguration',
    },
    cdmData: {
      consolidatePrintedReportsEnabled: 'false',
      ippFaxEnabled: 'false',
      billingCodeSettings: {
        billingCodeEnabled: 'false',
        usersToEditBillingCodeEnabled: 'true',
        billingCodeMinimumLength: '1',
      },
      faxSendEnabled: 'true',
      faxSendHeader: 'overlay',
      faxSendMethod: 'internalModem',
      userEditableEmailIdEnabled: 'true',
      faxNumberConfirmationEnabled: 'false',
      promptUserPriorToJobStartEnabled: 'false',
      pcFaxSendEnabled: 'true',
    },
  },
  'com.hp.cdm.service.network.version.1.resource.discoveryServices': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.network.version.1.resource.discoveryServices',
    },
    cdmData: {
      wsDiscovery: {
        enabled: 'true',
      },
      slp: {
        enabled: 'false',
      },
      bonjour: {
        domainName: 'hubjdi0809.local.',
        serviceName: 'HP Color LaserJet MFP M776 [434878]',
        highestPriorityPrintService: 'ipps',
        enabled: 'true',
      },
      version: '1.0.0',
    },
  },
  'com.hp.cdm.service.network.version.1.resource.jetdirectServices': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.network.version.1.resource.jetdirectServices',
    },
    cdmData: {
      xdmEnabled: 'true',
      telnetEnabled: 'false',
      tftpEnabled: 'false',
    },
  },
  'com.hp.cdm.service.jobTicket.version.1.resource.printConfigurationDefaults': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.jobTicket.version.1.resource.printConfigurationDefaults',
    },
    cdmData: {
      dest: {
        print: {
          mediaSize: 'na_letter_8.5x11in',
          mediaType: 'stationery',
          mediaSource: 'auto',
          duplexBinding: 'twoSidedLongEdge',
          plexMode: 'duplex',
        },
      },
    },
  },
  'com.hp.cdm.service.system.version.1.resource.configuration': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.system.version.1.resource.configuration',
    },
    cdmData: {
      assetNumber: '',
      deviceDescription: 'HP Color LaserJet MFP M776',
      companyName: 'HP Inc RCB',
      companyContact: 'Lathashree',
      deviceLocation: 'Emulator keystone device',
    },
  },
  'com.hp.cdm.usbHost.1.resource.configuration': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.usbHost.version.1.resource.configuration',
    },
    cdmData: {
      printFromUsbEnabled: 'false',
      scanToUsbEnabled: 'false',
      version: '1.0.0',
    },
  },
  'com.hp.cdm.service.firmwareUpdate.version.1.resource.autoUpdateConfiguration': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.firmwareUpdate.version.1.resource.autoUpdateConfiguration',
    },
    cdmData: {
      version: '1.0.0',
    },
  },
  'com.hp.cdm.service.supply.version.1.resource.configPrivate': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.supply.version.1.resource.configPrivate',
    },
    cdmData: {
      veryLowPageDelayEnabled: 'true',
      supplyPolicy: 'authorizedHP',
    },
  },
  'com.hp.cdm.service.network.version.1.resource.nameResolverServices': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.network.version.1.resource.nameResolverServices',
    },
    cdmData: {
      wins: {
        winsPort: 'false',
        secondaryServer: '0.0.0.0',
        primaryServer: '0.0.0.0',
        enabled: 'true',
      },
      llmnr: {
        enabled: 'false',
      },
      version: '1.0.0',
    },
  },
  'com.hp.cdm.security.1.resource.dssConfig': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.security.version.1.resource.dssConfig',
    },
    cdmData: {
      minPasswordLength: 8,
      passwordConfigured: 'true',
      accountLockout: {
        maximumAttempts: 5,
        lockoutInterval: 10,
        resetLockoutCounterInterval: 10,
        enabled: 'false',
      },
      passwordComplexityEnabled: 'true',
      version: '1.0.0',
    },
  },
  'com.hp.cdm.service.ioConfig.version.2.resource.eth0_adapterConfig': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.ioConfig.version.2.resource.eth0_adapterConfig',
    },
    cdmData: {
      macAddress: 'ac:e2:d3:43:48:78',
      ipv4: {
        address: {
          requestedConfigMethod: 'dhcpv4',
          subnet: '255.255.192.0',
          dhcpLeaseTime: 604800,
          activeConfigMethod: 'dhcpv4',
          ip: '15.77.167.41',
          dhcpFqdnRfc4702Compliance: 'false',
          configuredBy: '15.77.141.97',
          gateway: '15.77.128.10',
        },
        multicastEnabled: 'false',
        dnsServer: {
          secondary: {
            address: '15.77.52.28',
          },
          primary: {
            address: '15.77.52.27',
          },
        },
        enabled: 'true',
      },
      connectionState: 'connectedInternet',
      identity: {
        domainNameV6: {
          name: '',
        },
        hostname: {
          name: 'hubjdi0809',
        },
        domainName: {
          name: 'psr.rd.hpicorp.net',
        },
      },
      ipv6: {
        addresses: [
          {
            prefixLength: 18,
            preferredLifeTime: 1,
            validLifeTime: 0,
            address: '15.77.167.41',
            addressType: 'dhcpv4',
          },
          {
            prefixLength: 64,
            preferredLifeTime: 1,
            validLifeTime: 0,
            address: 'fe80::aee2:d3ff:fe43:4878',
            addressType: 'unknown',
          },
        ],
        statelessEnabled: 'true',
        manual: {
          enabled: 'false',
        },
        dnsServer: {
          secondary: {
            address: '',
          },
          primary: {
            address: '',
          },
        },
        enabled: 'true',
      },
      adapterName: 'eth0',
      ipConfigPrecedence: [
        {
          "method": "manual",
          "precedence": 0
        },
        {
          "method": "dhcpv4",
          "precedence": 2
        },
        {
          "method": "dhcpv6",
          "precedence": 3
        },
        {
          "method": "default",
          "precedence": 4
        }
      ],
      version: '2.0',
      adapterType: 'wiredEthernet',
      ethConfig: {
        linkConfig: 'automatic',
        dot1xEnabled: 'false',
        linkSpeed: 'link1000full',
        dot1xAuthConfig: {
          eapPeapEnabled: 'true',
          password: '',
          reauthenticate: 'true',
          exactServerIdMatch: 'true',
          blockNetworkOnAuthFailure: 'true',
          eapTlsEnabled: 'true',
          userName: '',
          serverIdentity: '',
        },
      },
    },
  },
  'com.hp.cdm.service.ioConfig.version.2.resource.wirelessConfig': {
    cdmMetaData: {
      version: "2.0.0",
      serviceGun: "com.hp.cdm.service.ioConfig.version.2.resource.wirelessConfig"
  },
    cdmData: {
      wlanProfile1: {
            dot1xAuthConfig: {
                eapPeapEnabled: "true",
                password: "SET",
                eapLeapEnabled: "false",
                exactServerIdMatch: "true",
                encryptionStrength: "low",
                userName: "test2",
                eapTlsEnabled: "true",
                serverIdentity: "test"
            }
        }
    }
  },
  'com.hp.cdm.service.ioConfig.version.2.resource.adapterConfigs.eth0': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.ioConfig.version.2.resource.adapterConfigs.eth0',
    },
    cdmData: {
      macAddress: 'ac:e2:d3:43:48:78',
      ipv4: {
        address: {
          requestedConfigMethod: 'dhcpv4',
          subnet: '255.255.192.0',
          dhcpLeaseTime: 604800,
          activeConfigMethod: 'dhcpv4',
          ip: '15.77.167.41',
          dhcpFqdnRfc4702Compliance: 'false',
          configuredBy: '15.77.141.97',
          gateway: '15.77.128.10',
        },
        multicastEnabled: 'false',
        dnsServer: {
          secondary: {
            address: '15.77.52.28',
          },
          primary: {
            address: '15.77.52.27',
          },
        },
        enabled: 'true',
      },
      connectionState: 'connectedInternet',
      identity: {
        domainNameV6: {
          name: '',
        },
        hostname: {
          name: 'hubjdi0809',
        },
        domainName: {
          name: 'psr.rd.hpicorp.net',
        },
      },
      ipv6: {
        addresses: [
          {
            prefixLength: 18,
            preferredLifeTime: 1,
            validLifeTime: 0,
            address: '15.77.167.41',
            addressType: 'dhcpv4',
          },
          {
            prefixLength: 64,
            preferredLifeTime: 1,
            validLifeTime: 0,
            address: 'fe80::aee2:d3ff:fe43:4878',
            addressType: 'unknown',
          },
        ],
        statelessEnabled: 'true',
        manual: {
          enabled: 'false',
        },
        dnsServer: {
          secondary: {
            address: '',
          },
          primary: {
            address: '',
          },
        },
        enabled: 'true',
      },
      adapterName: 'eth0',
      ipConfigPrecedence: [
        {
          "method": "manual",
          "precedence": 0
        },
        {
          "method": "dhcpv4",
          "precedence": 2
        },
        {
          "method": "dhcpv6",
          "precedence": 3
        },
        {
          "method": "default",
          "precedence": 4
        }
      ],
      version: '2.0',
      adapterType: 'wiredEthernet',
      ethConfig: {
        linkConfig: 'automatic',
        dot1xEnabled: 'false',
        linkSpeed: 'link1000full',
        dot1xAuthConfig: {
          eapPeapEnabled: 'true',
          password: '',
          reauthenticate: 'true',
          exactServerIdMatch: 'true',
          blockNetworkOnAuthFailure: 'true',
          eapTlsEnabled: 'true',
          userName: '',
          serverIdentity: '',
        },
      },
    },
  },
  'com.hp.cdm.service.ioConfig.version.2.resource.usb_adapterConfig': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.ioConfig.version.2.resource.usb_adapterConfig',
    },
    cdmData: {
      enabled: 'false',
      version: '1.0.0',
    },
  },
  'com.hp.cdm.platform.stratus.shadow.version.1.resource.supplies': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.platform.stratus.shadow.version.1.resource.supplies',
    },
    cdmData: {
      supplyStates: {
        K: {
          supplyState: 'GENUINE_HP',
          levelState: 'OK',
          pct10LevelRemaining: '100',
        },
        M: {
          supplyState: 'GENUINE_HP',
          levelState: 'OK',
          pct10LevelRemaining: '100',
        },
        Y: {
          supplyState: 'GENUINE_HP',
          levelState: 'OK',
          pct10LevelRemaining: '100',
        },
        C: {
          supplyState: 'GENUINE_HP',
          levelState: 'OK',
          pct10LevelRemaining: '100',
        },
      },
    },
  },
  'com.hp.cdm.service.ioConfig.version.2.resource.adapterConfigs': {
    cdmMetaData: {
      version: '1.0.0',
      eTag: '1000000',
      serviceGun: 'com.hp.cdm.service.ioConfig.version.2.resource.adapterConfigs',
    },
    cdmData: {
      usb: {
        adapterName: 'usb',
        adapterType: 'usbDevice',
        version: '2.1.0',
        enabled: 'true',
      },
      eth0: {
        macAddress: 'c0:18:03:b7:03:70',
        ipv4: {
          address: {
            requestedConfigMethod: 'dhcpv4',
            subnet: '255.255.240.0',
            dhcpLeaseTime: 691200,
            activeConfigMethod: 'dhcpv4',
            dhcpFqdnRfc4702Compliance: 'false',
            ip: '15.83.4.213',
            configuredBy: '15.83.41.40',
            gateway: '15.83.0.1',
          },
          dnsServer: {
            preferredDnsConfigMethod: 'automatic',
            secondary: {
              address: '15.16.134.14',
              configMethod: 'automatic',
            },
            primary: {
              address: '15.16.134.15',
              configMethod: 'automatic',
            },
          },
          enabled: 'true',
        },
        connectionState: 'connectedInternet',
        identity: {
          domainNameV6: {
            configMethod: 'notConfigured',
          },
          hostname: {
            configMethod: 'notConfigured',
            name: 'HPIB70370',
          },
          domainName: {
            configMethod: 'automatic',
            name: 'bcn.rd.hpicorp.net',
          },
        },
        ipv6: {
          addresses: [
            {
              prefixLength: 64,
              address: 'fe80::c218:3ff:feb7:370',
              addressType: 'linkLocal',
            },
          ],
          statelessEnabled: 'true',
          dnsServer: {
            preferredDnsConfigMethod: 'automatic',
            secondary: {
              address: '::',
              configMethod: 'notConfigured',
            },
            primary: {
              address: '::',
              configMethod: 'notConfigured',
            },
          },
          manual: {
            address: {
              address: '',
              addressType: 'manual',
            },
            enabled: 'false',
          },
          enabled: 'true',
          dhcpv6Enabled: 'true',
        },
        adminEnabled: 'false',
        adapterName: 'eth0',
        adapterType: 'wiredEthernet',
        version: '2.1.0',
        enabled: 'true',
        ethConfig: {
          linkConfig: 'automatic',
          linkSpeed: 'link1000full',
          dot1xAuthConfig: {
            eapPeapEnabled: 'false',
            exactServerIdMatch: 'false',
            eapTlsEnabled: 'false',
          },
        },
      },
      version: '2.1.0',
    },
  },
  'com.hp.cdm.service.faxReceive.version.1.resource.configuration': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.faxReceive.version.1.resource.configuration',
    },
    cdmData: {
      useHolidaySleepScheduleEnabled: 'false',
      fitToPageEnabled: 'true',
      stampReceivedFaxesEnabled: 'false',
      faxReceiveMethod: 'internalModem',
      faxReceiveEnabled: 'false',
      version: '1.0.0',
    },
  },
  'com.hp.cdm.service.firmwareUpdate.version.2.resource.configuration': {
    cdmMetaData: {
      version: '2.0.0',
      serviceGun: 'com.hp.cdm.service.firmwareUpdate.version.2.resource.configuration',
    },
    cdmData: {
      pjlUpdateEnabled: 'false',
      version: '1.0.0',
      sha1ValidationEnabled: 'true',
    },
  },
  'com.hp.cdm.security.1.resource.accountPolicy': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.security.version.1.resource.accountPolicy',
    },
    cdmData: {
      minPasswordLength: 8,
      minPasswordLengthEnabled: 'true',
      accountLockout: {
        maximumAttempts: 5,
        lockoutInterval: 300,
        resetLockoutCounterInterval: 300,
        enabled: 'false',
      },
      passwordComplexityEnabled: 'true',
      version: '1.0.0',
    },
  },
  'com.hp.cdm.service.security.version.1.resource.dssConfig': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.security.version.1.resource.dssConfig',
    },
    cdmData: {
      minPasswordLength: 8,
      passwordConfigured: 'true',
      accountLockout: {
        maximumAttempts: 5,
        lockoutInterval: 10,
        resetLockoutCounterInterval: 10,
        enabled: 'false',
      },
      passwordComplexityEnabled: 'true',
      version: '1.0.0',
    },
  },
  'com.hp.stratus.iot.systemconfig': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.security.version.1',
    },
    cdmData: {
      csrfPreventionEnabled: 'true',
      httpsRedirectionEnabled: 'true',
      version: '1.0.0',
    },
  },
  'com.hp.cdm.system.1.resource.configuration': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.system.version.1.resource.configuration',
    },
    cdmData: {
      assetNumber: 'Asset No15.77.167.41',
      deviceDescription: 'HP Color LaserJet MFP M776',
      companyName: 'HP Inc RCB',
      companyContact: 'Lathashree',
      deviceLocation: 'Emulator keystone device',
    },
  },
  'com.hp.cdm.service.usbHost.version.1.resource.configuration': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.usbHost.version.1.resource.configuration',
    },
    cdmData: {
      printFromUsbEnabled: 'false',
      scanToUsbEnabled: 'false',
      version: '1.0.0',
    },
  },
  'com.hp.cdm.service.network.version.1.resource.networkScanServices': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.network.version.1.resource.networkScanServices',
    },
    cdmData: {
      scanServices: {
        eSCL: {
          enableUserAuthentication: 'false',
          eSCL: 'false',
          eSCLSecure: 'false',
        },
      },
      networkInitiatedScan: 'false',
    },
  },
  'com.hp.cdm.network.1.resource.discoveryServices': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.network.version.1.resource.discoveryServices',
    },
    cdmData: {
      wsDiscovery: {
        enabled: 'true',
      },
      slp: {
        enabled: 'false',
      },
      bonjour: {
        domainName: 'hubjdi0809.local.',
        serviceName: 'HP Color LaserJet MFP M776 [434878]',
        highestPriorityPrintService: 'ipps',
        enabled: 'true',
      },
      version: '1.0.0',
    },
  },
  'com.hp.cdm.network.1.resource.nameResolverServices': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.network.version.1.resource.nameResolverServices',
    },
    cdmData: {
      wins: {
        winsPort: 'false',
        secondaryServer: '0.0.0.0',
        primaryServer: '0.0.0.0',
        enabled: 'true',
      },
      llmnr: {
        enabled: 'false',
      },
      version: '1.0.0',
    },
  },
  'com.hp.cdm.network.1.resource.jetdirectServices': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.network.version.1.resource.jetdirectServices',
    },
    cdmData: {
      xdmEnabled: 'true',
      telnetEnabled: 'false',
      tftpEnabled: 'false',
    },
  },
  'com.hp.cdm.service.clock.version.1.resource.configuration': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.clock.version.1.resource.configuration',
    },
    cdmData: {
      localTime: '2023-05-24T17:48:06+00:00',
      ntpSyncFrequency: 24,
      dstEnabled: 'false',
      systemTimeSync: 'ntp',
      dateFormat: 'yyyymmmdd',
      timeFormat: 'hr24',
      ntpLocalPortNumber: 1230,
      timeZone: 'Etc/GMT',
      timeZoneDescription: 'Coordinated Universal Time',
    },
  },
  'com.hp.cdm.service.jobTicket.version.1.resource.receiveFaxConfigurationDefaults': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.jobTicket.version.1.resource.receiveFaxConfigurationDefaults',
    },
    cdmData: {
      dest: {
        print: {
          mediaSize: 'na_letter_8.5x11in',
          mediaType: 'stationery',
          mediaSource: 'auto',
        },
      },
    },
  },
  'com.hp.cdm.service.ble.version.1.resource.bleConfiguration': {
    cdmMetaData: {
      version: '1.0.0',
      eTag: '1000003',
      serviceGun: 'com.hp.cdm.service.ble.version.1.resource.bleConfiguration',
    },
    cdmData: {
      requestedBeaconingEnabled: 'true',
      currentBeaconingEnabled: 'true',
      version: '1.0.0',
    },
  },
  'com.hp.cdm.service.ioConfig.version.1.resource.adapterConfigs': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.ioConfig.version.1.resource.adapterConfigs',
    },
    cdmData: {
      ipConfigPrecedence: [
        {
          "method": "manual",
          "precedence": 0
        },
        {
          "method": "tftp",
          "precedence": 1
        },
        {
          "method": "dhcpv4",
          "precedence": 2
        },
        {
          "method": "dhcpv6",
          "precedence": 3
        },
        {
          "method": "default",
          "precedence": 4
        }
      ],
      eth1: {
        macAddress: 'ac:e2:d3:43:48:78',
        ipv4: {
          address: {
            requestedConfigMethod: 'dhcpv4',
            subnet: '255.255.192.0',
            dhcpLeaseTime: 604800,
            activeConfigMethod: 'dhcpv4',
            ip: '15.77.167.41',
            dhcpFqdnRfc4702Compliance: 'false',
            configuredBy: '15.77.141.97',
            gateway: '15.77.128.10',
          },
          multicastEnabled: 'false',
          dnsServer: {
            secondary: {
              address: '15.77.52.28',
            },
            primary: {
              address: '15.77.52.27',
            },
          },
          enabled: 'false',
        },
        connectionState: 'connectedInternet',
        identity: {
          domainNameV6: {
            name: '',
          },
          hostname: {
            name: 'hubjdi0809',
          },
          domainName: {
            name: 'psr.rd.hpicorp.net',
          },
        },
        ipv6: {
          dhcpv6Policy: 'onStatelessFailure',
          addresses: [
            {
              prefixLength: '18',
              address: '15.77.167.41',
              addressType: 'ipv4',
            },
          ],
          stateless: 'true',
          manual: {
            address: {
              prefixLength: '::',
              address: '::',
              addressType: 'ipv4',
            },
            enabled: 'false',
          },
          dnsServer: {
            secondary: {
              address: '::',
            },
            primary: {
              address: '::',
            },
          },
          enabled: 'false',
        },
        adapterName: 'HP ETHERNET MULTI-ENVIRONMENT,ROM none,JETDIRECT,JD153,EEPROM JSI25500358',
        // ipConfigPrecedence: [
        //   {
        //     method: 'manual',
        //     precedence: 0,
        //   },
        //   {
        //     method: 'tftp',
        //     precedence: 1,
        //   },
        //   {
        //     method: 'dhcpv4',
        //     precedence: 2,
        //   },
        //   {
        //     method: 'dhcpv6',
        //     precedence: 3,
        //   },
        //   {
        //     method: 'default',
        //     precedence: 4,
        //   },
        // ],
        version: '2.0',
        adapterType: 'wiredEthernet',
        dnsSuffixList: {
          suffixes: [
            {
              name: 'noSuchInstance',
            },
          ],
        },
        ethConfig: {
          linkConfig: 'automatic',
          dot1xEnabled: 'false',
          linkSpeed: 'link1000full',
          dot1xAuthConfig: {
            eapPeapEnabled: 'false',
            password: 'NOT_SET',
            eapLeapEnabled: 'false',
            reauthenticate: 'false',
            exactServerIdMatch: 'false',
            eapTlsEnabled: 'false',
            userName: 'hubjdi0809',
            serverIdentity: '',
          },
        },
      },
      eth0: {
        macAddress: 'ac:e2:d3:43:48:78',
        ipv4: {
          address: {
            requestedConfigMethod: 'dhcpv4',
            subnet: '255.255.192.0',
            dhcpLeaseTime: 604800,
            activeConfigMethod: 'dhcpv4',
            ip: '15.77.167.41',
            dhcpFqdnRfc4702Compliance: 'false',
            configuredBy: '15.77.141.97',
            gateway: '15.77.128.10',
          },
          multicastEnabled: 'false',
          dnsServer: {
            secondary: {
              address: '15.77.52.28',
            },
            primary: {
              address: '15.77.52.27',
            },
          },
          enabled: 'false',
        },
        connectionState: 'connectedInternet',
        identity: {
          domainNameV6: {
            name: '',
          },
          hostname: {
            name: 'hubjdi0809',
          },
          domainName: {
            name: 'psr.rd.hpicorp.net',
          },
        },
        ipv6: {
          dhcpv6Policy: 'onStatelessFailure',
          addresses: [
            {
              prefixLength: '18',
              address: '15.77.167.41',
              addressType: 'ipv4',
            },
          ],
          stateless: 'true',
          manual: {
            address: {
              prefixLength: '::',
              address: '::',
              addressType: 'ipv4',
            },
            enabled: 'false',
          },
          dnsServer: {
            secondary: {
              address: '::',
            },
            primary: {
              address: '::',
            },
          },
          enabled: 'false',
        },
        adapterName: 'HP ETHERNET MULTI-ENVIRONMENT,ROM none,JETDIRECT,JD153,EEPROM JSI25500358',
        // ipConfigPrecedence: [
        //   {
        //     method: 'manual',
        //     precedence: 0,
        //   },
        //   {
        //     method: 'tftp',
        //     precedence: 1,
        //   },
        //   {
        //     method: 'dhcpv4',
        //     precedence: 2,
        //   },
        //   {
        //     method: 'dhcpv6',
        //     precedence: 3,
        //   },
        //   {
        //     method: 'default',
        //     precedence: 4,
        //   },
        // ],
        version: '2.0',
        adapterType: 'wiredEthernet',
        dnsSuffixList: {
          suffixes: [
            {
              name: 'noSuchInstance',
            },
          ],
        },
        ethConfig: {
          linkConfig: 'automatic',
          dot1xEnabled: 'false',
          linkSpeed: 'link1000full',
          dot1xAuthConfig: {
            eapPeapEnabled: 'false',
            password: 'NOT_SET',
            eapLeapEnabled: 'false',
            reauthenticate: 'false',
            exactServerIdMatch: 'false',
            eapTlsEnabled: 'false',
            userName: 'hubjdi0809',
            serverIdentity: '',
          },
        },
      },
    },
  },
  'com.hp.cdm.service.network.version.1.resource.snmpConfig': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.network.version.1.resource.snmpConfig',
    },
    cdmData: {
      snmpV1V2Config: {
        contextName: 'Jetdirect',
        accessOption: 'readWrite',
        readOnlyCommunityNameSet: 'true',
        readOnlyPublicAllowed: 'false',
        writeOnlyCommunityNameSet: 'true',
        enabled: 'true',
      },
      snmpV3Config: {
        authenticationAlgorithm: 'md5',
        minPasswordLength: 8,
        privacyAlgorithm: 'des',
        keyType: 'passPhrase',
        accountLockout: {
          maximumAttempts: 15,
          lockoutInterval: 250,
          resetLockoutCounterInterval: 250,
          enabled: 'true',
        },
        enabled: 'true',
        passwordComplexityEnabled: 'true',
      },
    },
  },
  'com.hp.cdm.service.security.version.1.resource.serviceUserConfig': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.security.version.1.resource.serviceUserConfig',
    },
    cdmData: {
      passwordConfiguredByUser: 'true',
    },
  },
  'com.hp.cdm.service.security.version.1.resource.deviceAdminConfig': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.security.version.1.resource.deviceAdminConfig',
    },
    cdmData: {
      passwordSet: 'true',
      configuredByUser: 'true',
    },
  },
  'com.hp.cdm.service.email.version.1.resource.smtpServers': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.email.version.1.resource.smtpServers',
    },
    cdmData: {
      servers: [
        {
          version: '',
          serverAddress: '15.77.141.198',
          serverPort: '25',
          fileSize: '0',
          useSsl: 'false',
          validateServerCertificate: 'false',
          serverRequireAuthentication: 'false',
          credential: {
            credentialType: 'alwaysUseCredential',
            isPasswordSet: 'false',
          },
          serverUsage: {
            email: 'true',
            fax: 'true',
            autoSend: 'true',
            automatedEmail: 'true',
            emailOrder: "0",
            faxOrder: "0",
            automatedEmailOrder: "0",
            autoSendOrder: "0",
          },
        },
        {
          version: '',
          serverAddress: '15.77.141.199',
          serverPort: '25',
          fileSize: '0',
          useSsl: 'false',
          validateServerCertificate: 'false',
          serverRequireAuthentication: 'false',
          credential: {
            credentialType: 'alwaysUseCredential',
            isPasswordSet: 'false',
          },
          serverUsage: {
            email: 'true',
            emailOrder: "2",
          },
        },
        {
          version: '',
          serverAddress: '15.77.141.197',
          serverPort: '25',
          fileSize: '0',
          useSsl: 'false',
          validateServerCertificate: 'false',
          serverRequireAuthentication: 'false',
          credential: {
            credentialType: 'alwaysUseCredential',
            isPasswordSet: 'false',
          },
          serverUsage: {
            fax: 'true',
            faxOrder: "1",
          },
        },
        {
          version: '',
          serverAddress: '15.77.141.196',
          serverPort: '25',
          fileSize: '0',
          useSsl: 'false',
          validateServerCertificate: 'false',
          serverRequireAuthentication: 'false',
          credential: {
            credentialType: 'alwaysUseCredential',
            isPasswordSet: 'false',
          },
          serverUsage: {
            automatedEmail: 'true',
            automatedEmailOrder: "1"
          },
        },
        {
          version: '',
          serverAddress: '15.77.141.195',
          serverPort: '25',
          fileSize: '0',
          useSsl: 'false',
          validateServerCertificate: 'false',
          serverRequireAuthentication: 'false',
          credential: {
            credentialType: 'alwaysUseCredential',
            isPasswordSet: 'false',
          },
          serverUsage: {
            email: 'true',
            fax: 'true',
            emailOrder: "1",
            faxOrder: "2",
          },
        },
      ],
    },
  },
  'com.hp.cdm.service.solutionManager.version.1.resource.configuration': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.solutionManager.version.1.resource.configuration',
    },
    cdmData: {
      workpathEnabledState: 'true',
      software: {
        apps: [
          {
            uuid: '66029bd4-93b5-42a2-9945-4bbc723c80a1',
            store: 'app-deploy',
            type: 'workpath-app',
            title: 'HP for OneDrive',
            version: '1.01.48',
            License: 'VALID',
            installedAt: 'Wed Jan 01 12:00:00 UTC 2024',
            updatedAt: 'Wed Mar 21 12:00:00 UTC 2024',
            provider: 'HP inc.',
          },
        ],
      }
    },
  },
  'com.hp.cdm.service.power.version.1.resource.configuration': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.power.version.1.resource.configuration',
    },
    cdmData: {
      version: '1.0.0',
      sleepAutoOffTimerEnabled: 'false',
      sleepModeTimeout: '70',
      autoOnEvents: 'powerButtonPress',
      autoOffTimeout: 60,
    },
  },
  'com.hp.cdm.service.power.version.1.resource.sleepConfiguration': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.power.version.1.resource.sleepConfiguration',
    },
    cdmData: {
      version: '1.0.0',
      sleepAutoOffTimerEnabled: 'false',
      sleepModeTimeout: '70',
      autoOnEvents: 'powerButtonPress',
      autoOffTimeout: 60,
      sleepSchedules: [
        {
          daysOfWeek: [
            'saturday',
          ],
          timeOfDay: {
            minuteOffset: 59,
            hourOffset: 22,
          }
        },
        {
          daysOfWeek: [
            'sunday',
          ],
          timeOfDay: {
            minuteOffset: 59,
            hourOffset: 22,
          }
        }
      ],
      wakeSchedules: [
        {
          daysOfWeek: [
            'saturday',
          ],
          timeOfDay: {
            minuteOffset: 59,
            hourOffset: 23,
          }
        },
        {
          daysOfWeek: [
            'sunday',
          ],
          timeOfDay: {
            minuteOffset: 59,
            hourOffset: 23,
          }
        }
      ],
      holidaySchedules: [
        {
          holidayName: 'Holiday',
          startTime: '2024-01-01T01:00:00Z',
          endTime: '2024-01-01T02:00:00Z',
        }
      ],
    },
  },
  'com.hp.cdm.service.scan.version.1.resource.destinationConfig': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.scan.version.1.resource.destinationConfig',
    },
    cdmData: {
      folderEnabled: 'true',
      emailEnabled: 'false',
      sharePointEnabled: 'false',
    },
  },
  'com.hp.cdm.service.network.version.1.resource.printServices': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.network.version.1.resource.printServices',
      eTag: 'c897f947-debc-46d3-b083-05707013f1cf',
    },
    cdmData: {
      airPrint: {
        enabled: 'false',
      },
      ftpPrint: {
        enabled: 'false',
      },
      ipp: {
        ipp: 'false',
        ippSecure: 'false',
      },
      port9100: {
        enabled: 'false',
      },
      wsPrint: {
        enabled: 'false',
      },
      lpdPrint: {
        enabled: 'false',
      },
    },
  },
  'com.hp.cdm.service.jobTicket.version.1.resource.scanEmailConfigurationDefaults': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.jobTicket.version.1.resource.scanEmailConfigurationDefaults',
    },
    cdmData: {
      dest: {
        email: {
          emailSigningEnabled: 'false',
          emailSigning: {
            isEditable: 'false',
          },
          subject: '',
          toListIsEditable: 'true',
          body: '',
          isSubjectEditable: 'true',
          isFromEditable: 'false',
          fromSignInRequired: 'true',
          toListSignInRequired: 'true',
          bccListSignInRequired: 'true',
          ccListSignInRequired: 'true',
          bccListIsEditable: 'true',
          addressFieldRestrictionsEnabled: 'true',
          allowInvalidEmailAddress: 'true',
          emailEncryptionEnabled: 'false',
          from: {
            emailAddress: '',
            displayName: '',
          },
          ccListIsEditable: 'false',
          isBodyEditable: 'true',
        },
      },
    },
  },
  'com.hp.cdm.service.mock.version.1.resource.mockSettings': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.mock.version.1.resource.mockSettings',
    },
    cdmData: {
      sleepScheduleWeekly: [
        {
          column1_EventType: 'Sleep',
          column2_Day: 'Tu, Th',
          column3_SleepTime: '11:00PM',
          column4_WakeTime: '11:30PM',
        },
        {
          column1_EventType: 'Sleep',
          column2_Day: 'We, Th, Fr, Sa, Su',
          column3_SleepTime: '12:00AM',
          column4_WakeTime: '12:30AM',
        },
        {
          column1_EventType: 'Wake',
          column2_Day: 'Mon',
          column3_SleepTime: '06:00AM',
          column4_WakeTime: '06:30AM',
        },
      ],
      sleepScheduleHoliday: [
        {
          column1_EventName: 'Labor’s Day',
          column2_Start: 'Sep 10, 2023 6:00 PM',
          Column3_End: 'Sep 10, 2024 6:00 PM',
        },
        {
          column1_EventName: 'National Holiday',
          column2_Start: 'Aug 16, 2023 2:15 AM',
          Column3_End: 'Aug 16, 2024 2:15 AM',
        },
        {
          column1_EventName: 'Labor’s Day',
          column2_Start: 'Dec 24, 2024 10:00 PM',
          Column3_End: 'Dec 26, 2024 12:00 AM',
        },
      ],
      servers: [
        {
          serverAddress: 'Server Name1',
          serverPort: '1111',
          serverUsage: {
            email: 'true',
            fax: 'true',
            automatedEmail: 'true',
          },
        },
        {
          serverAddress: 'Server Name2',
          serverPort: '2222',
          serverUsage: {
            email: 'true',
          },
        },
        {
          serverAddress: 'Server Name3',
          serverPort: '3333',
          serverUsage: {
            fax: 'true',
          },
        },
        {
          serverAddress: 'Server Name4',
          serverPort: '4444',
          serverUsage: {
            automatedEmail: 'true',
          },
        },
        {
          serverAddress: 'Server Name5',
          serverPort: '5555',
          serverUsage: {
            email: 'true',
            fax: 'true',
          },
        },
      ],
      corsdata: [
        {
          trustedSite: 'trustedSite01',
        },
        {
          trustedSite: 'trustedSite02',
        },
        {
          trustedSite: 'trustedSite03',
        },
      ],
    },
  },
  'com.hp.cdm.service.fleetProxy.version.1.resource.legacyAttributes': {
    cdmMetaData: {
      version: '1.0.0',
      serviceGun: 'com.hp.cdm.service.fleetProxy.version.1.resource.legacyAttributes',
      eTag: 'c4086d45-90fa-430f-9955-0385f077bbb7',
    },
    cdmData: {
      security: {
        digitalSendingService: {
          allowUse: 'false',
          allowTransfer: 'false',
        },
      },
      version: '',
      webServices: {
        hpJetAdvantage: 'true',
        accountCreation: 'true',
        cloudPrint: 'true',
      },
    },
  },
  'com.hp.cdm.service.ioConfig.version.2.resource.wifiDirectConfig': {
    cdmMetaData: {
      version: "1.0.0",
      serviceGun: "com.hp.cdm.service.ioConfig.version.2.resource.wifiDirectConfig"
    },
    cdmData: {
      ssidPrefix: "HP-Print-79-",
      showPassphrase: "false",
      preferredChannel: 7,
      ssidSuffix: "M406 LaserJet",
      currentChannel: 7,
      enabled: "true",
      hideSsid: "false",
      connectionMethod: "advanced"
    }
  },
  'com.hp.cdm.service.mediaHandling.version.1.resource.configuration': {
    cdmMetaData: {
      version: "1.0.0",
      serviceGun: "com.hp.cdm.service.mediaHandling.version.1.resource.configuration"
    },
    cdmData: {
      sizeTypeEnabled: "false",
    }
  },
  'com.hp.cdm.service.mediaHandling.version.1.resource.configuration': {
    cdmMetaData: {
      version: "1.0.0",
      serviceGun: "com.hp.cdm.service.mediaHandling.version.1.resource.configuration"
    },
    cdmData: {
      sizeTypeEnabled: "false"
    }
  },
  'com.hp.cdm.service.jobTicket.version.1.resource.scanNetworkFolderConfigurationDefaults': {
    cdmMetaData: {
      version: "1.0.0",
      serviceGun: "com.hp.cdm.service.jobTicket.version.1.resource.scanNetworkFolderConfigurationDefaults"
    },
    cdmData: {
      src: {
        scan: {
          pageBinding: "twoSidedShortEdge",
          mediaSize: "na_foolscap_8.5x13in",
          colorMode: "autoDetect",
          resolution: "e300Dpi",
          plexMode: "duplex",
          autoColorDetect: "treatNonColorAsBlackAndWhite1"
        }
      },
      pipelineOptions: {
        notification: {
          includeThumbnailOfFirstPageEnabled: "false",
          notificationCondition: "never",
          notificationMode: "email"
        },
        imageModifications: {
          blankPageSuppressionEnabled: "false"
        },
        sendFileAttributes: {
          fileName: "Karthi Shanmugam",
          prefix: "Sathish Raja",
          colorGrayScaleTiffCompression: "tiff6",
          compressPDF: "true",
          encryptPDF: "true",
          fileNameIsEditable: "true",
          suffix: "Amirtharaj",
          qualityAndFileSize: "medium",
          monoTiffCompression: "automatic",
          fileType: "pdf"
        }
      }
    }
  },
  'com.hp.cdm.service.jobTicket.version.1.resource.scanEmailConfigurationDefaults' : {
    cdmMetaData: {
        version: "1.0.0",
        serviceGun: "com.hp.cdm.service.jobTicket.version.1.resource.scanEmailConfigurationDefaults"
    },
    cdmData: {
        src: {
            scan: {
                mediaSize: "na_letter_8.5x11in",
                colorMode: "monochrome",
                resolution: "e200Dpi",
                contentType: "mixed",
                autoColorDetect: "treatNonColorAsGrayscale8",
                pageBinding: "twoSidedShortEdge",
                plexMode: "duplex"
            }
        },
        pipelineOptions: {
            notification: {
                notificationCondition: "never",
                notificationMode: "email"
            },
            imageModifications: {
                blankPageSuppressionEnabled: "true"
            },
            sendFileAttributes: {
                fileName: "~`@#$%^&()-_+={}[],.;'",
                prefix: "%DEVICE_DATE_DDMMYYYY%",
                compressPDF: "false",
                colorGrayScaleTiffCompression: "postTiff6",
                encryptPDF: "true",
                suffix: "%DEVICE_DATE_DDMMYYYY%",
                fileNameIsEditable: "false",
                qualityAndFileSize: "medium",
                fileType: "pdfa",
                monoTiffCompression: "g3"
            }
        },
        dest: {
            email: {
                emailSigning: {
                    isEditable: "false",
                    algorithm: "sha256"
                },
                subject: "",
                bccList: [],
                toList: [],
                toListIsEditable: "true",
                body: "",
                isSubjectEditable: "true",
                ccListSignInRequired: "false",
                isFromEditable: "true",
                emailEncryptionEnabled: "false",
                fromSignInRequired: "false",
                bccListIsEditable: "true",
                toListSignInRequired: "false",
                addressFieldRestrictionsEnabled: "false",
                ccList: [],
                allowInvalidEmailAddress: "false",
                emailEncryption: {
                    recipientsPublicKey: "userCertificate",
                    isEditable: "false",
                    algorithm: "aes256",
                    verifyRecipientUsingPublicKey: "true"
                },
                from: {
                    emailAddress: "",
                    displayName: ""
                },
                emailSigningEnabled: "false",
                bccListSignInRequired: "false",
                ccListIsEditable: "true",
                isBodyEditable: "true"
            }
        }
    }
},
  'com.hp.cdm.service.overlay.version.1.resource.scanStampLocation': {
    cdmMetaData: {
      version: "1.0.0",
      serviceGun: "com.hp.cdm.service.overlay.version.1.resource.scanStampLocation"
    },
    cdmData: {
      itemsMetadata: {
        totalItemsCount: 6,
        reportedItemsCount: 6
      },
      items: [
        // {
        //   itemId: "dESONUhHa2sGP7nXK88uyw==",
        //   version: 1,
        //   dateCreated: "Wed Mar 05 05:23:14 GMT 2025",
        //   timestamp: "Wed Mar 05 05:23:14 GMT 2025",
        //   cdmVersion: "1.0.0",
        //   state: {
        //     reported: {
        //       textSize: "twelvePoint",
        //       locationId: "bottomRight",
        //       startingPage: 1,
        //       startingNumber: 1,
        //       pageNumberingStyle: "hyphenNumberHyphen",
        //       textFont: "letterGothic",
        //       textColor: "purple",
        //       numberOfDigits: 1,
        //       whiteBackground: "true",
        //       stampContent: [
        //         { stampId: "userName" },
        //         { stampId: "productInformation" }
        //       ],
        //       policy: "guided"
        //     }
        //   }
        // },
        // {
        //   itemId: "daBWDoHxqJjw98KxNThtBQ==",
        //   version: 1,
        //   dateCreated: "Wed Mar 05 05:23:14 GMT 2025",
        //   timestamp: "Wed Mar 05 05:23:14 GMT 2025",
        //   cdmVersion: "1.0.0",
        //   state: {
        //     reported: {
        //       textSize: "eightPoint",
        //       locationId: "bottomCenter",
        //       startingPage: 1,
        //       startingNumber: 1,
        //       pageNumberingStyle: "pagePlusNumber",
        //       textFont: "antiqueOlive",
        //       textColor: "blue",
        //       numberOfDigits: 1,
        //       whiteBackground: "true",
        //       stampContent: [
        //         { stampId: "ipAddress" },
        //         { stampId: "userName" },
        //         { stampId: "productInformation" },
        //         { stampId: "pageNumber" },
        //         { stampId: "dateAndTime" }
        //       ],
        //       policy: "guided"
        //     }
        //   }
        // },
        {
          itemId: "bhEOPWxIGd79SDi0KIQww==",
          version: 1,
          dateCreated: "Wed Mar 05 05:23:14 GMT 2025",
          timestamp: "Wed Mar 05 05:23:14 GMT 2025",
          cdmVersion: "1.0.0",
          state: {
            reported: {
              textSize: "twentyPoint",
              locationId: "bottomLeft",
              startingPage: 1000000,
              startingNumber: 1000000,
              pageNumberingStyle: "number",
              textFont: "centurySchoolbook",
              textColor: "red",
              numberOfDigits: 10,
              whiteBackground: "true",
              stampContent: [
                {},
                {},
                {},
                { stampId: "ipAddress" },
                { stampId: "userName" }
              ],
              policy: "guided"
            }
          }
        },
        {
          itemId: "gwt13pB3dcV2uWwURmioXw==",
          version: 1,
          dateCreated: "Wed Mar 05 05:23:14 GMT 2025",
          timestamp: "Wed Mar 05 05:23:14 GMT 2025",
          cdmVersion: "1.0.0",
          state: {
            reported: {
              textSize: "twelvePoint",
              locationId: "topRight",
              startingPage: 50,
              startingNumber: 1,
              pageNumberingStyle: "pagePlusNumber",
              textFont: "garamond",
              textColor: "green",
              numberOfDigits: 5,
              whiteBackground: "false",
              stampContent: [
                { stampId: "pageNumber" },
                { stampId: "date" }
              ],
              policy: "guided"
            }
          }
        },
        {
          itemId: "jL1lq7sVeygCEeLsXQwyg==",
          version: 1,
          dateCreated: "Wed Mar 05 05:23:14 GMT 2025",
          timestamp: "Wed Mar 05 05:23:14 GMT 2025",
          cdmVersion: "1.0.0",
          state: {
            reported: {
              textSize: "eightPoint",
              locationId: "topCenter",
              startingPage: 1,
              startingNumber: 1,
              pageNumberingStyle: "hyphenNumberHyphen",
              textFont: "centurySchoolbook",
              textColor: "yellow",
              numberOfDigits: 1,
              whiteBackground: "true",
              stampContent: [
                { stampId: "productInformation" },
                { stampId: "pageNumber" }
              ],
              policy: "guided"
            }
          }
        },
        {
          itemId: "N0y9QUr6w3+2tKHKyJuxA==",
          version: 1,
          dateCreated: "Wed Mar 05 05:23:14 GMT 2025",
          timestamp: "Wed Mar 05 05:23:14 GMT 2025",
          cdmVersion: "1.0.0",
          state: {
            reported: {
              textSize: "twentyPoint",
              locationId: "topLeft",
              startingPage: 1,
              startingNumber: 1,
              pageNumberingStyle: "number",
              textFont: "antiqueOlive",
              textColor: "skyBlue",
              numberOfDigits: 2,
              whiteBackground: "true",
              stampContent: [
                { stampId: "ipAddress" },
                { stampId: "userName" },
                { stampId: "productInformation" },
                { stampId: "pageNumber" }
              ],
              policy: "guided"
            }
          }
        }
      ]
    }
  },
  'com.hp.cdm.service.security.version.1.resource.dssConfig' : {
    cdmMetaData: {
    version: "1.0.0",
    serviceGun: "com.hp.cdm.service.security.version.1.resource.dssConfig"
  },
  cdmData: {
    minPasswordLength: 8,
    passwordConfigured: "false",
    accountLockout: {
      maximumAttempts: 5,
      lockoutInterval: 10,
      resetLockoutCounterInterval: 10,
      enabled: "false",
    },
    passwordComplexityEnabled: "false",
  }
  }
};
