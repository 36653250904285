import { DeviceCacheServiceGunEnum } from '../config/DeviceCacheServiceGunEnums';

const GetCustomDeviceCacheValueHelper = (value, fleetdata, serviceSettingsData) => {
  let result;
  switch (value) {
    case DeviceCacheServiceGunEnum.Eth0_DeviceAdapterConfiguration:
      if (fleetdata?.cdmData?.ethConfig?.dot1xAuthConfig?.blockNetworkOnAuthFailure != undefined) {
        result = fleetdata?.cdmData?.ethConfig?.dot1xAuthConfig?.blockNetworkOnAuthFailure;
      } else {
        result = false;
      }
      break;
    case DeviceCacheServiceGunEnum.WireLess_DeviceAdapterConfiguration:
      if (fleetdata?.cdmData?.wlanProfile1?.dot1xAuthConfig?.password === "SET")
        result = true;
      else {
        result = false;
      }
      break;
    case DeviceCacheServiceGunEnum.DeviceAdminConfig:
      if (fleetdata?.cdmData?.passwordSet != undefined) {
        result = fleetdata?.cdmData?.passwordSet;
      } else if (fleetdata?.cdmData?.currentPasswordLength != undefined) {
        if (fleetdata?.cdmData?.currentPasswordLength > 0) {
          result = true;
        } else {
          result = false;
        }
      } else {
        result = fleetdata?.cdmData?.configuredByUser;
      }
      break;
    case DeviceCacheServiceGunEnum.SnmpConfiguration:
      if (fleetdata?.cdmData?.snmpV1V2Config?.enabled != undefined) {
        result =
          fleetdata?.cdmData?.snmpV1V2Config?.enabled === 'true'
            ? fleetdata?.cdmData?.snmpV1V2Config?.accessOption
            : 'disable';
      } else {
        result = 'undefined';
      }
      break;
    case DeviceCacheServiceGunEnum.WifiDirectConfiguration:
      if (fleetdata?.cdmData != undefined) {
        if (serviceSettingsData === 'cdmData.ssidSuffix') {
          result =
            fleetdata?.cdmData?.ssidSuffix === 'noSuchInstance'
              ? ''
              : fleetdata?.cdmData.ssidSuffix;
        } else if (serviceSettingsData === 'cdmData.currentChannel') {
          result =
            fleetdata?.cdmData?.currentChannel === 'noSuchInstance'
              ? ''
              : fleetdata?.cdmData.currentChannel;
        }
      } else {
        result = 'undefined';
      }
      break;
    case DeviceCacheServiceGunEnum.Network_Folder_Notification_Settings:
    case DeviceCacheServiceGunEnum.EmailAddressAndMessageSetting:
      // Check for colorMode and handle accordingly
      if (serviceSettingsData === 'cdmData.src.scan.colorMode') {
        const colorMode = fleetdata?.cdmData?.src?.scan?.colorMode;
        if (colorMode !== 'autoDetect') {
          result = fleetdata?.cdmData?.src?.scan?.colorMode; // Set to default value if not autoDetect
          break;
        }

        const autoColorDetect = fleetdata?.cdmData?.src?.scan?.autoColorDetect;
        if (autoColorDetect === 'treatNonColorAsBlackAndWhite1') {
          result = 'treatNonColorAsBlackAndWhite1';
        } else if (autoColorDetect === 'treatNonColorAsGrayscale8') {
          result = 'treatNonColorAsGrayscale8';
        }
        break;
      }

      // Check for previewFileName and construct the value
      if (serviceSettingsData === 'cdmData.pipelineOptions.sendFileAttributes.previewFileName') {
        const sendFileAttributes = fleetdata?.cdmData?.pipelineOptions?.sendFileAttributes;
        if (sendFileAttributes?.prefix !== undefined) {
          const newvalue = `${sendFileAttributes.prefix}${sendFileAttributes.fileName}${sendFileAttributes.suffix}`;
          result = newvalue;
        }
        break;
      }

      // Default result if no specific condition matches
      result = 'undefined';
      break;

    default:
      result = 'undefined';
  }
  return result != undefined ? result : 'undefined';
};

export default GetCustomDeviceCacheValueHelper;
