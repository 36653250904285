import { TextBox } from '@veneer/core';
import React, { memo, useContext, useEffect, useState } from 'react';
import { ConstraintsEffectEnum } from '../../config/ConstraintsEnums';
import { FeatureEffectivePolicyEnum } from '../../config/FeatureEnums';
import { SubFeatureAccessModeEnum } from '../../config/SubFeatureEnums';
import TextBoxValidator from '../../constraintsValidators/TextBoxValidator';
import EditDataContext from '../../context/EditDataContext';
import LocalizeHelper from '../../helper/LocalizeHelper';
import TempDataHelper from '../../helper/TempDataHelper';
import { InputTitle } from '../CommonExtended/InputTitle/InputTitle';
import { InputWrap } from './Styles';

const TextBoxControl = (props) => {
  const { device_settings, featuresId, isChecked, isControlledPolicy } = props;

  const { getRulesEffect } = TempDataHelper();

  const { onUpdateTempData, tempRulesEffect } = useContext(EditDataContext);

  // Localization Helper
  const {
    getDeviceSettingsLocString,
    getDeviceSettingsWithCountLocString,
    getRequirePasswordStringsWithRange,
  } = LocalizeHelper();
  const [inputVal, setInputVal] = useState(
    device_settings.fleetValue ? device_settings.fleetValue : '',
  );
  const [error, setError] = useState('');
  const [errorValue, setErrorValue] = useState('');
  const [errorMinRange, setErrorMinRange] = useState('');
  const [errorMaxRange, setErrorMaxRange] = useState('');
  const [effectVal, setEffectVal] = useState();

  // Strings
  const label = getDeviceSettingsLocString(featuresId, device_settings.label);
  const modifiedLabel = label === "Authentication Server" ? `${label}*` : label;

  const tooltipMessage = getDeviceSettingsLocString(featuresId, device_settings.info);
  const hint = device_settings.hint
    ? getDeviceSettingsLocString(featuresId, device_settings.hint)
    : null;

  const placeholder = device_settings.placeholder
    ? getDeviceSettingsLocString(featuresId, device_settings.placeholder)
    : null;

  const errorMsg = errorValue
    ? getDeviceSettingsWithCountLocString(featuresId, `${device_settings.id}-${error}`, errorValue)
    : errorMinRange || errorMaxRange
      ? getRequirePasswordStringsWithRange('error_range', errorMinRange, errorMaxRange)
      : getDeviceSettingsLocString(featuresId, `${device_settings.id}-${error}`);

  const isDisable =
    effectVal &&
      (effectVal === ConstraintsEffectEnum.ENABLE || effectVal === ConstraintsEffectEnum.SET)
      ? true
      : isChecked && isChecked
        ? isChecked
        : isControlledPolicy === FeatureEffectivePolicyEnum.controlledPolicy &&
          device_settings.accessMode === SubFeatureAccessModeEnum.READWRITE_DISABLE
          ? true
          : false;
          
  const handleOnchange = (value) => {
    const isInteger = /^[0-9]+$/;
    let validation;
    let inputValueData;

    if (device_settings.constraints.type == 'integer') {
      if (value === '' || isInteger.test(value)) {
        setInputVal(value);
        validation = TextBoxValidator(device_settings, value);
        inputValueData = value;
      } else {
        validation = TextBoxValidator(device_settings, inputVal);
        inputValueData = inputVal;
      }
    } else {
      setInputVal(value);
      validation = TextBoxValidator(device_settings, value);
      inputValueData = value;
    }

    setError(validation.errorType);
    setErrorValue(validation.errorCharLength);
    setErrorMinRange(validation.errorMinRange);
    setErrorMaxRange(validation.errorMaxRange);

    const errorKey = validation.errorType;

    onUpdateTempData({
      settingsName: device_settings.settingsName,
      fleetValue: inputValueData,
      attribute: device_settings.attribute,
      error: !!errorKey,
    });
  };

  useEffect(() => {
    const regex = new RegExp(device_settings.constraints.regex);
    if (!regex.test(device_settings?.fleetValue)) {
      handleOnchange(device_settings?.fleetValue)
    }
  }, []);

  useEffect(() => {
    const ruleEffect = getRulesEffect(tempRulesEffect, device_settings.settingsName).effect;
    setEffectVal(ruleEffect);
  }, [tempRulesEffect]);

  return (
    <>
      <InputTitle
        title={modifiedLabel}
        tooltipMessage={tooltipMessage}
        info={device_settings.info}
        disable={isDisable}
      />
      <InputWrap>
        <TextBox
          id={device_settings.attribute}
          helperText={error ? error && errorMsg : hint}
          placeholder={placeholder}
          disabled={isDisable}
          value={inputVal}
          error={!!error}
          onChange={(value) => handleOnchange(value)}
        />
      </InputWrap></>

  );
};

export default memo(TextBoxControl);
