import {
  ConstraintsResourceEnum,
  ConstraintsSettingsNameEnum,
  ConstraintsSubFeatureIdEnum,
} from '../config/ConstraintsEnums';
import { FeatureIdEnum } from '../config/FeatureEnums';
import { FleetSvcPolicyAttributeId } from '../config/FleetServiceEnums';
import { DeviceCacheCdmDataEnum } from '../config/DeviceCacheCdmDataEnums';
import { DeviceCacheServiceGunEnum } from '../config/DeviceCacheServiceGunEnums';
import {
  SubFeatureAccessModeEnum,
  SubFeatureControlTypeEnum,
  SubfeatureIdEnum,
} from '../config/SubFeatureEnums';

export const SuppliesDataModel = [
  {
    id: `${FeatureIdEnum.Cartridge_Policy}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Cartridge_Policy}`,
        attribute: `${FleetSvcPolicyAttributeId.Cartridge_Policy}.genuine`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'cat_policy_label',
        info: 'info',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Cartridge_Policy}`,
        resource: `${ConstraintsResourceEnum.Supply_configPvt}`,
        settingsName: `${ConstraintsSettingsNameEnum.Supply_Policy}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ConfigPrivate}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.SupplyPolicy}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Delay_VeryLow_Message}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Delay_VeryLow_Message}`,
        attribute: `${FleetSvcPolicyAttributeId.Delay_VeryLowMessage}.check`,
        type: `${SubFeatureControlTypeEnum.Table}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'delayverylow_message_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Delay_VeryLow_Message}`,
        resource: `${ConstraintsResourceEnum.Supply_configPvt}`,
        settingsName: `${ConstraintsSettingsNameEnum.Delay_VeryLow_Message}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ConfigPrivate}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.DelayVeryLowMessage}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Toner_Collection_Unit}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Toner_Collection_Unit}`,
        attribute: `${FleetSvcPolicyAttributeId.Toner_Collection_Unit}.action`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Toner_Collection_Unit}`,
        resource: `${ConstraintsResourceEnum.Supply_configPvt}`,
        settingsName: `${ConstraintsSettingsNameEnum.Marker_Collection_Unit_Full_Action}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ConfigPrivate}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.MarkerCollectionUnitFullAction}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Cartridge_VeryLowAction_Black}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Cartridge_VeryLowAction_Black}`,
        attribute: `${FleetSvcPolicyAttributeId.Cartridge_VeryLowAction_Black}.action`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'cartridge-verylowaction-black_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Cartridge_VeryLowAction_Black}`,
        resource: `${ConstraintsResourceEnum.Supply_configPvt}`,
        settingsName: `${ConstraintsSettingsNameEnum.Cartridge_VeryLowAction_Black}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ConfigPrivate}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.CartridgeVeryLowAction_Black}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Cartridge_VeryLowAction_Color}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Cartridge_VeryLowAction_Color}`,
        attribute: `${FleetSvcPolicyAttributeId.Cartridge_VeryLowAction_Color}.action`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'cartridge-verylowaction-color_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Cartridge_VeryLowAction_Color}`,
        resource: `${ConstraintsResourceEnum.Supply_configPvt}`,
        settingsName: `${ConstraintsSettingsNameEnum.Cartridge_VeryLowAction_Color}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ConfigPrivate}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.CartridgeVeryLowAction_Color}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Cartridge_Protection}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Cartridge_Protection}`,
        attribute: `${FleetSvcPolicyAttributeId.Cartridge_Protection}.check`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Cartridge_Protection}`,
        resource: `${ConstraintsResourceEnum.Supply_configPvt}`,
        settingsName: `${ConstraintsSettingsNameEnum.Cartridge_Protection}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ConfigPrivate}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.CartridgeProtection}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Cartridge_Threshold_Black}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Cartridge_Threshold_Black}`,
        attribute: `${FleetSvcPolicyAttributeId.Cartridge_Threshold_Black}.value`,
        type: `${SubFeatureControlTypeEnum.LowThreshold}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Low_Threshold}`,
        resource: `${ConstraintsResourceEnum.Supply_configPvt}`,
        settingsName: `${ConstraintsSettingsNameEnum.Low_Threshold}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ConfigPrivate}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.LowThreshold}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Cartridge_Threshold_Cyan}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Cartridge_Threshold_Cyan}`,
        attribute: `${FleetSvcPolicyAttributeId.Cartridge_Threshold_Cyan}.value`,
        type: `${SubFeatureControlTypeEnum.LowThreshold}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Low_Threshold}`,
        resource: `${ConstraintsResourceEnum.Supply_configPvt}`,
        settingsName: `${ConstraintsSettingsNameEnum.Low_Threshold}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ConfigPrivate}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.LowThreshold}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Cartridge_Threshold_Magenta}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Cartridge_Threshold_Magenta}`,
        attribute: `${FleetSvcPolicyAttributeId.Cartridge_Threshold_Magenta}.value`,
        type: `${SubFeatureControlTypeEnum.LowThreshold}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Low_Threshold}`,
        resource: `${ConstraintsResourceEnum.Supply_configPvt}`,
        settingsName: `${ConstraintsSettingsNameEnum.Low_Threshold}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ConfigPrivate}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.LowThreshold}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Cartridge_Threshold_Yellow}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Cartridge_Threshold_Yellow}`,
        attribute: `${FleetSvcPolicyAttributeId.Cartridge_Threshold_Yellow}.value`,
        type: `${SubFeatureControlTypeEnum.LowThreshold}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Low_Threshold}`,
        resource: `${ConstraintsResourceEnum.Supply_configPvt}`,
        settingsName: `${ConstraintsSettingsNameEnum.Low_Threshold}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ConfigPrivate}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.LowThreshold}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Drum_Threshold_Black}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Drum_Threshold_Black}`,
        attribute: `${FleetSvcPolicyAttributeId.Drum_Threshold_Black}.value`,
        type: `${SubFeatureControlTypeEnum.LowThreshold}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Low_Threshold}`,
        resource: `${ConstraintsResourceEnum.Supply_configPvt}`,
        settingsName: `${ConstraintsSettingsNameEnum.Low_Threshold}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ConfigPrivate}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.LowThreshold}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Drum_Threshold_Cyan}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Drum_Threshold_Cyan}`,
        attribute: `${FleetSvcPolicyAttributeId.Drum_Threshold_Cyan}.value`,
        type: `${SubFeatureControlTypeEnum.LowThreshold}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Low_Threshold}`,
        resource: `${ConstraintsResourceEnum.Supply_configPvt}`,
        settingsName: `${ConstraintsSettingsNameEnum.Low_Threshold}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ConfigPrivate}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.LowThreshold}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Drum_Threshold_Magenta}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Drum_Threshold_Magenta}`,
        attribute: `${FleetSvcPolicyAttributeId.Drum_Threshold_Magenta}.value`,
        type: `${SubFeatureControlTypeEnum.LowThreshold}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Low_Threshold}`,
        resource: `${ConstraintsResourceEnum.Supply_configPvt}`,
        settingsName: `${ConstraintsSettingsNameEnum.Low_Threshold}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ConfigPrivate}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.LowThreshold}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Drum_Threshold_Yellow}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Drum_Threshold_Yellow}`,
        attribute: `${FleetSvcPolicyAttributeId.Drum_Threshold_Yellow}.value`,
        type: `${SubFeatureControlTypeEnum.LowThreshold}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Low_Threshold}`,
        resource: `${ConstraintsResourceEnum.Supply_configPvt}`,
        settingsName: `${ConstraintsSettingsNameEnum.Low_Threshold}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ConfigPrivate}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.LowThreshold}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Fuser_Kit_Threshold}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Fuser_Kit_Threshold}`,
        attribute: `${FleetSvcPolicyAttributeId.Fuser_Kit_Threshold}.value`,
        type: `${SubFeatureControlTypeEnum.LowThreshold}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Low_Threshold}`,
        resource: `${ConstraintsResourceEnum.Supply_configPvt}`,
        settingsName: `${ConstraintsSettingsNameEnum.Low_Threshold}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ConfigPrivate}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.LowThreshold}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Fuser_Kit_Very_Low_Action}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Fuser_Kit_Very_Low_Action}`,
        attribute: `${FleetSvcPolicyAttributeId.Fuser_Kit_Very_Low_Action}.action`,
        type: `${SubFeatureControlTypeEnum.VeryLowAction}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Fuser_Kit_Very_Low_Action}`,
        resource: `${ConstraintsResourceEnum.Supply_configPvt}`,
        settingsName: `${ConstraintsSettingsNameEnum.Fuser_Kit_Very_Low_Action}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ConfigPrivate}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.VeryLowActions}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.MaintenanceKit_threshold}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Maintenance_Kit_threshold}`,
        attribute: `${FleetSvcPolicyAttributeId.MaintenanceKit_threshold}.action`,
        type: `${SubFeatureControlTypeEnum.LowThreshold}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'maintenanceKit-threshold_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Maintenance_Kit_Threshold}`,
        resource: `${ConstraintsResourceEnum.Supply_configPvt}`,
        settingsName: `${ConstraintsSettingsNameEnum.kit_threshold}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ConfigPrivate}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.LowThreshold}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Transfer_Kit_threshold}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.TransferKit_threshold}`,
        attribute: `${FleetSvcPolicyAttributeId.TransferKit_Threshold}.value`,
        type: `${SubFeatureControlTypeEnum.LowThreshold}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'transferKitThreshold_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.TransferKit_Threshold}`,
        resource: `${ConstraintsResourceEnum.Supply_configPvt}`,
        settingsName: `${ConstraintsSettingsNameEnum.kit_threshold}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ConfigPrivate}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.LowThreshold}`,
       },
    ],
  },
  {
    id: `${FeatureIdEnum.Supply_Low_Alerts}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Supply_Low_Alerts}`,
        attribute: `${FleetSvcPolicyAttributeId.Supply_Low_Alerts}.check`,
        type: `${SubFeatureControlTypeEnum.Checkbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'supply_low_alerts_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Supply_Low_Alerts}`,
        resource: `${ConstraintsResourceEnum.Supply_configPvt}`,
        settingsName: `${ConstraintsSettingsNameEnum.Supply_Low_Alerts}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ConfigPrivate}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Supply_Low_Alerts}`,
      },
    ],
  },
];
