import React, { useEffect, useState } from 'react';
import LocalizeHelper from '../../../../../helper/LocalizeHelper';
import {
  FlexColumnTable,
  FlexColumnTableWrap,
  CopyStampLocationText,
  StyledTable,
  CopyStampContentText,
  CopyStampContentLayout,
} from '../Styles';

const CopyStampTable = ({ responseData }) => {
  const { getDeviceTableColumnsLocString, getCommonStrings } = LocalizeHelper();
  const noDataAvailable = getCommonStrings('noDataAvailable');

  // Fetch localized strings
  const localizedStrings = {
    startNumber: getDeviceTableColumnsLocString(responseData?.id, 'startNumber'),
    textFont: getDeviceTableColumnsLocString(responseData?.id, 'textFont'),
    textSize: getDeviceTableColumnsLocString(responseData?.id, 'textSize'),
    textColor: getDeviceTableColumnsLocString(responseData?.id, 'textColor'),
    whiteBackground: getDeviceTableColumnsLocString(responseData?.id, 'whiteBackground'),
    topLeft: getDeviceTableColumnsLocString(responseData?.id, 'topLeft'),
    topCenter: getDeviceTableColumnsLocString(responseData?.id, 'topCenter'),
    topRight: getDeviceTableColumnsLocString(responseData?.id, 'topRight'),
    bottomLeft: getDeviceTableColumnsLocString(responseData?.id, 'bottomLeft'),
    bottomCenter: getDeviceTableColumnsLocString(responseData?.id, 'bottomCenter'),
    bottomRight: getDeviceTableColumnsLocString(responseData?.id, 'bottomRight'),
    point: getDeviceTableColumnsLocString(responseData?.id, 'point'),
    enable: getDeviceTableColumnsLocString(responseData?.id, 'enable'),
    disable: getDeviceTableColumnsLocString(responseData?.id, 'disable'),
    stampContent: getDeviceTableColumnsLocString(responseData?.id, 'stampContent'),
  };

  const tableColumns = [
    { id: 'startNumber', label: localizedStrings.startNumber },
    { id: 'textFont', label: localizedStrings.textFont },
    { id: 'textSize', label: localizedStrings.textSize },
    { id: 'textColor', label: localizedStrings.textColor },
    { id: 'whiteBackground', label: localizedStrings.whiteBackground },
  ];

  const locationOrder = [
    localizedStrings.topLeft,
    localizedStrings.topCenter,
    localizedStrings.topRight,
    localizedStrings.bottomLeft,
    localizedStrings.bottomCenter,
    localizedStrings.bottomRight,
  ];

  const locationMapping = {
    topLeft: localizedStrings.topLeft,
    topCenter: localizedStrings.topCenter,
    topRight: localizedStrings.topRight,
    bottomLeft: localizedStrings.bottomLeft,
    bottomCenter: localizedStrings.bottomCenter,
    bottomRight: localizedStrings.bottomRight,
  };

  const [groupedData, setGroupedData] = useState({});

  useEffect(() => {
    if (responseData && responseData.device_settings) {
      setGroupedData(groupStampDataByLocation(responseData));
    }
  }, [JSON.stringify(responseData)]); // Ensure stability of dependency

  const groupStampDataByLocation = (data) => {
    if (!Array.isArray(data?.device_settings)) return {};

    const grouped = {};

    data.device_settings.forEach((item) => {
      const reported = item.state?.reported || {};
      const location = locationMapping[reported.locationId] ?? 'Unknown';

      if (!grouped[location]) {
        grouped[location] = [];
      }

      const stampContent = Array.isArray(reported.stampContent)
        ? reported.stampContent
            .filter((s) => s.stampId)
            .map((s) => getDeviceTableColumnsLocString(data.id, s.stampId) || '')
            .filter(Boolean)
            .join(', ')
        : '';

      grouped[location].push({
        id: item.itemId,
        startNumber: reported.startingNumber || noDataAvailable,
        textFont: getDeviceTableColumnsLocString(data.id, reported.textFont) || noDataAvailable,
        textSize: getDeviceTableColumnsLocString(data.id, reported.textSize) || noDataAvailable,
        textColor: getDeviceTableColumnsLocString(data.id, reported.textColor) || noDataAvailable,
        whiteBackground:
          reported.whiteBackground === 'true' ? localizedStrings.enable : localizedStrings.disable,
        stampContent: stampContent.trim() !== '' ? stampContent : noDataAvailable,
      });
    });

    return grouped;
  };

  return (
    <>
      {locationOrder.map((location) => {
        const tableData = groupedData[location];

        if (!tableData) return null;

        return (
          <FlexColumnTableWrap key={location}>
            <CopyStampLocationText>{location}</CopyStampLocationText>
            <CopyStampContentText>{localizedStrings.stampContent}</CopyStampContentText>
            <CopyStampContentLayout>
              {tableData?.[0]?.stampContent || noDataAvailable}
            </CopyStampContentLayout>
            <FlexColumnTable>
              <StyledTable
                columns={tableColumns}
                data={Array.isArray(tableData) ? tableData : []}
                preferences={{
                  width: [
                    { columnId: 'startNumber', width: 128 },
                    { columnId: 'textFont', width: 170 },
                    { columnId: 'textSize', width: 89 },
                    { columnId: 'textColor', width: 98 },
                    { columnId: 'whiteBackground', width: 183 },
                  ],
                }}
              />
            </FlexColumnTable>
          </FlexColumnTableWrap>
        );
      })}
    </>
  );
};

export default CopyStampTable;
