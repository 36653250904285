import React from 'react';
import { InformationIcon } from '../../../CommonExtended/Icons/InformationIcon';
import LocalizeHelper from '../../../../helper/LocalizeHelper';
import * as Style from '../Styles';

export const SubFeatureTitle = (props) => {
  const { label, info, info1, featureId, prefixLabel, subFeatureId } = props;
  const { getDeviceSettingsLocString } = LocalizeHelper();

  let labelValue = prefixLabel
    ? `${prefixLabel} (${getDeviceSettingsLocString(featureId, label)})`
    : getDeviceSettingsLocString(featureId, label);

  const hideInfoIcon = [
    'fe-dot1x-authentication-wireless'
  ].includes(featureId);

  return (
    <Style.TitleWrap>
      <Style.Title id={`${subFeatureId}-title`}>
        {labelValue}
      </Style.Title>

      {info && !hideInfoIcon ? (
        <InformationIcon
          infoMessage={getDeviceSettingsLocString(featureId, info)}
          infoMessageTwo={getDeviceSettingsLocString(featureId, info1)}
          size={16}
          subFeatureId={subFeatureId}
        />
      ) : null}
    </Style.TitleWrap>
  );
};
