import { useI18n } from '@jarvis/react-portal-addons';
import React, { Fragment } from 'react';
import defaultLang from '../../src/assets/locale';
import logger from '../../src/utils/logger';
import { AppConfig } from '../config/App.config';

import he from 'he';
const LocalizeHelper = () => {
  const { en_US } = defaultLang;

  const { t } = useI18n();
  let newString: string;

  // key = "localization key"
  const getDeviceCategoryLocString = (key: any): string => {
    try {
      const keyToString = key.toString();
      let newString = keyToString;
      const categoryKey = 'ecp-device-details-configuration.categories.' + newString;
      const defaultKey = en_US['ecp-device-details-configuration'].categories[newString];

      if (t(categoryKey) === categoryKey) {
        return t(defaultKey);
      }
      return t(categoryKey);
    } catch (error) {
      logger.info(`Error while fetching the translation for the key "${newString}": ${error}`);
      return newString; // Return the key as default value in case of an error
    }
  };

  // id = featureID, key = "localization key
  const getDeviceFeatureLocString = (id: string, key: any): string => {
    try {
      const keyToString = key.toString();
      newString = keyToString;
      const attributeKey = 'ecp-device-details-configuration.attributes.' + id + '.' + newString;
      const defaultKey = en_US['ecp-device-details-configuration'].attributes[id][newString];

      if (t(attributeKey) === attributeKey) {
        return t(defaultKey);
      }
      return t(attributeKey);
    } catch (error) {
      logger.info(
        `Error while fetching the translation for the key "${newString}" with id "${id}": ${error}`,
      );
      return newString; // Return the key as default value in case of an error
    }
  };

  const getDeviceFeatureModalString = (id: string, key: any): JSX.Element[] | string => {
    try {
      const keyToString = key.toString();
      newString = keyToString;
      const attributeKey = 'ecp-device-details-configuration.attributes.' + id + '.' + newString;
      const defaultKey = en_US['ecp-device-details-configuration'].attributes[id][newString];
      let text;

      if (t(attributeKey) === attributeKey) {
        text = t(defaultKey);
      } else {
        text = t(attributeKey);
      }

      return text.split('\n').map((item, index) => (
        <Fragment key={index}>
          {item}
          <br />
        </Fragment>
      ));
    } catch (error) {
      logger.info(
        `Error while fetching the translation for the key "${newString}" with id "${id}": ${error}`,
      );
      return newString; // Return the key as default value in case of an error
    }
  };

  // id = featureID, key = "localization key", variable = "Variable value"
  const getDeviceSettingsLocString = (id: string, key: string, variable?: any): string => {
    try {
      const keyToString = key?.toString();
      newString = keyToString;
      const settingsKey =
        'ecp-device-details-configuration.attributes.' + id + '.device_settings.' + newString;
      const defaultKey =
        en_US['ecp-device-details-configuration'].attributes[id].device_settings[newString];

      if (variable) {
        if (t(settingsKey, variable) === settingsKey) {
          return he.decode(t(defaultKey, variable));
        } else {
          return he.decode(t(settingsKey, variable));
        }
      } else {
        if (t(settingsKey) === settingsKey) {
          return t(defaultKey);
        }
        return t(settingsKey);
      }
    } catch (error) {
      logger.info(
        `Error while fetching the translation for the key "${newString}" with id "${id}": ${error}`,
      );
      return newString; // Return the key as default value in case of an error
    }
  };

  const getDeviceSettingsWithCountLocString = (id: string, key: any, length): string => {
    try {
      const keyToString = key.toString();
      newString = keyToString;
      const settingsKey =
        'ecp-device-details-configuration.attributes.' + id + '.device_settings.' + newString;
      const defaultKey =
        en_US['ecp-device-details-configuration'].attributes[id].device_settings[newString];

      if (t(settingsKey, { length }) === settingsKey) {
        return t(defaultKey, { length });
      }
      return t(settingsKey, { length });
    } catch (error) {
      logger.info(
        `Error while fetching the translation for the key "${newString}" with id "${id}": ${error}`,
      );
      return newString; // Return the key as default value in case of an error
    }
  };

  const getDeviceSettingsWithRangeLocString = (id: string, key: any, errorRange: any): string => {
    try {
      const keyToString = key.toString();
      newString = keyToString;
      const settingsKey =
        'ecp-device-details-configuration.attributes.' + id + '.device_settings.' + newString;

      if (
        t(settingsKey, { minimum: errorRange.errorMinRange, maximum: errorRange.errorMaxRange }) ===
        settingsKey
      ) {
        return t(
          en_US['ecp-device-details-configuration'].attributes[id].device_settings[key],
          errorRange,
        );
      }

      return t(settingsKey, {
        minimum: errorRange.errorMinRange,
        maximum: errorRange.errorMaxRange,
      });
    } catch (error) {
      logger.info(
        `Error while fetching the translation for the key "${newString}" with id "${id}": ${error}`,
      );
      return newString; // Return the key as default value in case of an error
    }
  };

  const getDeviceConfigurationsLocString = (id: string, key: any, subId: any): string => {
    const keyString = key?.toString() || '';
    const newString = keyString.includes('.') ? keyString.replace(/\./g, '_') : keyString;
    const newSubId = `${subId}-options`;

    const translationKey = (prefix: string) => t(`${prefix}.${newString}`);

    try {
      // Check for common token types and return appropriate translations
      if (AppConfig.commonTokenSettings_MediaSize?.includes(subId)) {
        return translationKey('ecp-device-details-configuration.common_tokens.mediaSize');
      }
      if (AppConfig.commonTokenSettings_MediaType?.includes(subId)) {
        return translationKey('ecp-device-details-configuration.common_tokens.mediaType');
      }
      if (AppConfig.commonTokenSettings_Language?.includes(subId)) {
        return translationKey('ecp-device-details-configuration.common_tokens.language');
      }

      // If no common token types matched, proceed with the default handling
      const settingsKey = t(`ecp-device-details-configuration.attributes.${id}.device_settings.${newSubId}.${newString}`,
      );

      // Fallback to en_US if the translation is the same as the key
      if (
        settingsKey === `ecp-device-details-configuration.attributes.${id}.device_settings.${newSubId}.${newString}`
      ) {
        const enResult = en_US['ecp-device-details-configuration']?.attributes?.[id]?.device_settings?.[newSubId]?.[
            newString
          ];
      if (!enResult) {
        logger.error(`Missing fallback key in en_US for id: ${id}, subId: ${subId}, key: ${newString}`);
        return newString; // Return the key if fallback is also missing
      }
        return t(enResult);
      }

      return settingsKey;
    } catch (error) {
      logger.error(
        `Error while processing the translation for key "${newString}" with id "${id}" and subId "${subId}": ${error.message}`,
      );
      console.error(error); // Log the full error stack for debugging
      return newString; // Return the key as default value in case of an error
    }
  };


  // key = "localization key"
  const getDeviceModalLocString = (key: any, variable?: object): string => {
    try {
      const keyToString = key.toString();
      newString = keyToString;
      const settingsKey = 'ecp-device-details-configuration.modal.' + newString;
      const defaultKey = en_US['ecp-device-details-configuration'].modal[newString];

      if (variable) {
        if (t(settingsKey, variable) === settingsKey) {
          return he.decode(t(defaultKey, variable));
        } else {
          return he.decode(t(settingsKey, variable));
        }
      } else {
        if (t(settingsKey) === settingsKey) {
          return t(defaultKey);
        }
        return t(settingsKey);
      }
    } catch (error) {
      logger.info(`Error while fetching the translation for the key "${newString}": ${error}`);
      return newString; // Return the key as default value in case of an error
    }
  };

  // key = "localization key"
  // const getDeviceOptionsLocString = (key: any): string => {
  //   try {
  //     const keyToString = key.toString();
  //     newString = keyToString;
  //     const result = t('ecp-device-details-configuration.options.' + newString);
  //     if (!result) throw new Error(`No value found for key: ${newString}`);
  //     return result;
  //   } catch (error) {
  //     logger.info('Error retrieving device options localization string:', error);
  //     return newString;
  //   }
  // };
  const getDeviceValueLocString = (id: string, key: any): string => {
    try {
      const keyToString = key.toString();
      newString = keyToString;
      const result = t(
        'ecp-device-details-configuration.attributes.' +
          id +
          '.device_settings.values.' +
          newString,
      );
      if (
        result ===
        'ecp-device-details-configuration.attributes.' + id + '.device_settings.values.' + newString
      ) {
        const splitNewString = newString.split('.');
        const valuesKey = splitNewString[0];
        const valuesSubKey = splitNewString[1];
        const enResult = t(
          en_US['ecp-device-details-configuration'].attributes[id].device_settings.values[
            valuesKey
          ][valuesSubKey],
        );
        return enResult;
      }
      return result;
    } catch (error) {
      logger.info('Error retrieving device options localization string:', error);
      return newString;
    }
  };

  const getCommonStrings = (key: any, category?: object): string => {
    try {
      const keyToString = key.toString();
      newString = keyToString;
      if (category) {
        const commonString = t(
          'ecp-device-details-configuration.commonstrings.' + newString,
          category,
        );

        if (commonString === 'ecp-device-details-configuration.commonstrings.' + newString) {
          const commonStringEn = t(
            en_US['ecp-device-details-configuration'].commonstrings[newString],
            category,
          );
          if (!commonStringEn)
            throw new Error(`No value found for key: ${newString} in en_US common strings`);
          return commonStringEn;
        }

        if (!commonString)
          throw new Error(`No value found for key: ${newString} in common strings`);
        return commonString;
      }
      const commonString = t(
        'ecp-device-details-configuration.commonstrings.' + newString,
        en_US['ecp-device-details-configuration'].commonstrings[newString],
      );
      if (!commonString) throw new Error(`No value found for key: ${newString} in common strings`);
      return commonString;
    } catch (error) {
      logger.info('Error retrieving common strings:', error);
      return newString;
    }
  };

  const getCustomizableComponentStrings = (key: any): string => {
    try {
      const keyToString = key.toString();
      newString = keyToString;
      const result = t(
        'ecp-device-details-configuration.customizable_componentstrings.' + newString,
      );
      if (
        result ===
        'ecp-device-details-configuration.customizable_componentstrings.' + newString
      ) {
        const enResult = t(
          en_US['ecp-device-details-configuration'].customizable_componentstrings[newString],
        );
        if (!enResult)
          throw new Error(
            `No value found for key: ${newString} in en_US customizable component strings`,
          );
        return enResult;
      }
      if (!result)
        throw new Error(`No value found for key: ${newString} in customizable component strings`);
      return result;
    } catch (error) {
      logger.info('Error retrieving customizable component strings:', error);
      return newString;
    }
  };

  const getFleetValueStrings = (key: any, value: string): string => {
    try {
      const keyToString = key.toString();
      newString = keyToString;
      const result = t('ecp-device-details-configuration.fleetValues.' + newString + '.' + value);
      if (result === 'ecp-device-details-configuration.fleetValues.' + newString + '.' + value) {
        const enResult = t(en_US['ecp-device-details-configuration'].fleetValues[newString], value);
        if (!enResult)
          throw new Error(
            `No value found for key: ${newString} and value: ${value} in en_US fleet values`,
          );
        return enResult;
      }
      if (!result)
        throw new Error(`No value found for key: ${newString} and value: ${value} in fleet values`);
      return result;
    } catch (error) {
      logger.info('Error retrieving fleet values strings:', error);
      return newString;
    }
  };

  const getNotificationStrings = (key: any, value: string): string => {
    try {
      const keyToString = key.toString();
      newString = keyToString;
      const result = t('ecp-device-details-configuration.notification.' + newString + '.' + value);
      if (result === 'ecp-device-details-configuration.notification.' + newString + '.' + value) {
        const enResult = t(
          en_US['ecp-device-details-configuration'].notification[newString],
          value,
        );
        if (!enResult)
          throw new Error(
            `No value found for key: ${newString} and value: ${value} in en_US notification`,
          );
        return enResult;
      }
      if (!result)
        throw new Error(`No value found for key: ${newString} and value: ${value} in notification`);
      return result;
    } catch (error) {
      logger.info('Error retrieving notification strings:', error);
      return newString;
    }
  };

  const getRequirePasswordStrings = (key: any): string => {
    try {
      const keyToString = key.toString();
      newString = keyToString;
      const result = t('ecp-device-details-configuration.require_password.' + newString);
      if (result === 'ecp-device-details-configuration.require_password.' + newString) {
        const enResult = t(en_US['ecp-device-details-configuration'].require_password[newString]);
        if (!enResult)
          throw new Error(`No value found for key: ${newString} in en_US require password strings`);
        return enResult;
      }
      if (!result)
        throw new Error(`No value found for key: ${newString} in require password strings`);
      return result;
    } catch (error) {
      logger.info('Error retrieving require password strings:', error);
      return newString;
    }
  };

  const getRequirePasswordStringsWithCount = (key: any, length): string => {
    try {
      const keyToString = key.toString();
      newString = keyToString;
      const result = t('ecp-device-details-configuration.require_password.' + newString, {
        length: length,
      });
      if (result === 'ecp-device-details-configuration.require_password.' + newString) {
        const enResult = t(en_US['ecp-device-details-configuration'].require_password[newString], {
          length: length,
        });

        if (!enResult)
          throw new Error(
            `No value found for key: ${newString} and length: ${length} in en_US require password strings`,
          );
        return enResult;
      }
      if (!result)
        throw new Error(
          `No value found for key: ${newString} and length: ${length} in require password strings`,
        );
      return result;
    } catch (error) {
      logger.info('Error retrieving require password strings with count:', error);
      return newString;
    }
  };

  const getRequirePasswordStringsWithRange = (key: any, minimum, maximum): string => {
    try {
      const keyToString = key.toString();
      newString = keyToString;
      const result = t('ecp-device-details-configuration.require_password.' + newString, {
        minimum: minimum,
        maximum: maximum,
      });
      if (result === 'ecp-device-details-configuration.require_password.' + newString) {
        const enResult = t(en_US['ecp-device-details-configuration'].require_password[newString], {
          minimum: minimum,
          maximum: maximum,
        });
        if (!enResult)
          throw new Error(
            `No value found for key: ${newString}, minimum: ${minimum}, and maximum: ${maximum} in en_US require password strings`,
          );
        return enResult;
      }
      if (!result)
        throw new Error(
          `No value found for key: ${newString}, minimum: ${minimum}, and maximum: ${maximum} in require password strings`,
        );
      return result;
    } catch (error) {
      logger.info('Error retrieving require password strings with range:', error);
      return newString;
    }
  };

  const getDeviceTableColumnsLocString = (id: string, key: any): string => {
    try {
      const keyToString = key.toString();
      newString = keyToString;
      const result = t(
        'ecp-device-details-configuration.attributes.' +
          id +
          '.device_settings.columns.' +
          newString,
      );
      if (
        result ===
        'ecp-device-details-configuration.attributes.' +
          id +
          '.device_settings.columns.' +
          newString
      ) {
        const enResult = t(
          en_US['ecp-device-details-configuration'].attributes[id].device_settings.columns[
            newString
          ],
        );
        return enResult;
      }
      if (!result) throw new Error(`No value found for key: ${newString}`);
      return result;
    } catch (error) {
      logger.info('Error retrieving device options localization string:', error);
      return newString;
    }
  };
  const getDeviceRestrictColorLocString = (id: string, value: any): string => {
    try {
      const result = t(
        'ecp-device-details-configuration.categories.' + id +  '.device_settings.NonDefaultApplications.' + value
      );
      if (
        result ===
        'ecp-device-details-configuration.categories.' + id +  '.device_settings.NonDefaultApplications.' + value
      ) {
        const enResult = t(
          en_US['ecp-device-details-configuration'].attributes[id].device_settings.NonDefaultApplications[
            value
          ],
        );
        return enResult;
      }
      if (!result) throw new Error(`No value found for key: ${newString}`);
      return result;
    } catch (error) {
      logger.info('Error retrieving device options localization string:', error);
      return newString;
    }
  };

   const getDeviceCommonLocString = (id: string, value: any): string => {
    try {
      const result = t(
        'ecp-device-details-configuration.categories.' + id +  '.device_settings.tableData.' + value
      );
      if (
        result ===
        'ecp-device-details-configuration.categories.' + id +  '.device_settings.tableData.' + value
      ) {
        const enResult = t(
          en_US['ecp-device-details-configuration'].attributes[id].device_settings.tableData[
            value
          ],
        );
        return enResult;
      }
      if (!result) throw new Error(`No value found for key: ${newString}`);
      return result;
    } catch (error) {
      logger.info('Error retrieving device options localization string:', error);
      return newString;
    }
  };
  return {
    getDeviceCategoryLocString,
    getDeviceFeatureLocString,
    getDeviceSettingsLocString,
    getDeviceModalLocString,
    // getDeviceOptionsLocString,
    getCommonStrings,
    getCustomizableComponentStrings,
    getFleetValueStrings,
    getNotificationStrings,
    getDeviceConfigurationsLocString,
    getRequirePasswordStrings,
    getRequirePasswordStringsWithCount,
    getRequirePasswordStringsWithRange,
    getDeviceSettingsWithCountLocString,
    getDeviceSettingsWithRangeLocString,
    getDeviceFeatureModalString,
    getDeviceTableColumnsLocString,
    getDeviceValueLocString,
    getDeviceRestrictColorLocString,
    getDeviceCommonLocString,
  };
};
export default LocalizeHelper;
