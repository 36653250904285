export enum SubfeatureIdEnum {
  AirPrint_Fax = 'airprint-fax',
  AirprintScan = 'airprint-scan',
  Airprint_SecureScan = 'airprint-secure-scan',
  Asset_Number = 'asset-number',
  AutoSend_Enable = 'auto-send-enable',
  AutoSend_Interval = 'auto-send-interval',
  AutoSend_SendToHP = 'auto-send-send-to-hpurl',
  AutoSend_EmailToHP = 'auto-send-email-to-hpurl',
  AutoSend_SendToURL = 'auto-send-send-to-url',
  AutoSend_Web_Addresses = 'auto-send-web-addresses',
  AutoSend_SendToEmail = 'auto-send-send-to-email',
  AutoSend_Email_Addresses = 'auto-send-email-addresses',
  Bt_Low_Energy = 'bt-low-energy',
  Ftp_enable = 'ftp_enable',
  EWS_Config = 'ews_Config',
  Stored_Data_Pin_Protection_pinLimit = 'pin_Limit',
  Stored_Data_Pin_Protection_RequirePrintJobPinProtection = 'require_PrintJobPinProtection',
  Stored_Data_Pin_Protection_PrintdriverStoredjobs = 'printdriver_Storedjobs',
  Stored_Data_Pin_Protection_CancelStoredJob = 'cancel_StoredJob',
  Cartridge_Policy = 'cartridge-policy',
  Cartridge_VeryLowAction_Black = 'cartridge-verylowaction-black',
  Cartridge_VeryLowAction_Color = 'cartridge-verylowaction-color',
  Delay_VeryLow_Message = 'delay_verylow_message',
  Company_Name = 'company-name',
  Contact_Person = 'contact-person',
  Control_Panel_Language = 'ctrl-panel-language',
  CORSEnabled = 'CORSEnabled',
  CORSTrustedSite = 'CORSTrustedSite',
  AccessControl = 'access-control_defaultControlPannel',
  SignIn_Method = 'signIn-method',
  EWS_AccessControlTable = 'access-control_ews',
  PermissionSets = 'permission-sets',
  ControlPanelAccessTable = 'control-panel-access-table',
  AlternateSignIn = 'alternate-sign-in-method',
  AutomaticSignOut = 'automatic-sign-out',
  SessionTimeOut = 'session-time-out',
  Date_Format = 'date-format',
  Date_And_Time = 'date-and-time',
  Device_Location = 'device-location',
  Device_Name = 'device-name',
  Default_Media_Size = 'def-media-size',
  Default_Media_Type = 'def-media-type',
  Def_Print_Options = 'def-print-options',
  Delay_Low_Supply_Msg = 'delay-low-supply-msg',
  Device_User_Accounts = 'device-user-accounts',
  Dhcp_v4_Compliance = 'dhcp-v4-compliance',
  Dhcp_v4_FQDN_Compliance_With_RFC_4702 = 'dhcp-v4-compliance',
  Domain_Name = 'domain-name',
  Duplex_Binding = 'duplex-binding',
  Outgoing_Servers = 'outgoing-servers',
  Outgoing_Server_List = 'outgoing-email-server-list',
  Outgoing_Server_List_OverwriteOption = 'outgoing-email-server-OverwriteOption',
  Outgoing_Server_List_OverWriteExistingName = 'outgoing-email-server-OverWriteExistingName',
  Outgoing_Server_List_Email = 'outgoing-email-server-list-email',
  Outgoing_Server_List_InternetFax = 'outgoing-email-server-list-fax',
  Outgoing_Server_List_AutomatedEmail = 'outgoing-email-server-list-automatedEmail',
  Outgoing_Server_List_AutoSend = 'outgoing-email-server-list-autoSend',
  Outgoing_Server_Name = 'outgoing-email-server-name',
  Outgoing_Server_Port = 'outgoing-email-server-port',
  Outgoing_Server_Split = 'outgoing-email-server-split',
  Outgoing_server_SplitEmailFileSize = 'outgoing-email-server-splitEmailFileSize',
  Outgoing_Server_SMTP = 'outgoing-email-server-smtp',
  Outgoing_Server_Valid_Cert = 'outgoing-email-server-valid-cert',
  Outgoing_Server_Req_Auth = 'outgoing-email-server-req-auth',
  Outgoing_Server_ReqAuthLabel = 'outgoing-email-server-reqAuthLabel',
  Outgoing_Server_InformationLabel = 'outgoing-email-server-informationLabel',
  Outgoing_ServerTypes_Label = 'outgoing-email-server-typesLabel',
  Outgoing_Server_CredType = 'outgoing-email-server-credType',
  Outgoing_Server_User_Name = 'outgoing-email-server-username',
  Outgoing_Server_Password = 'outgoing-email-server-password',
  Outgoing_Server_Send_Email = 'outgoing-email-server-email',
  Outgoing_Server_Fax = 'outgoing-email-server-fax',
  Outgoing_Server_Auto_Send = 'outgoing-email-server-autoSend',
  Outgoing_Server_Auto_AutomatedEmail = 'outgoing-email-server-automatedEmail',
  Ews_Language_Mode = 'ews-language-mode',
  Ews_Language = 'ews-language',
  Time_ServicePort = 'time-Service-port',
  Time_Synchours = 'time-Synchours',
  Time_ServerAddress = 'time-ServerAddress',
  Time_driftCorrection = 'time-driftCorrection',
  Ews_Access = 'ews-access',
  Ews_Admin_Password = 'ews-password',
  Ews_Admin_Password_Check = 'ews-admin-password-check',
  Ews_Admin_MinimumPasswordLength = 'ews-admin-minimum-password-length',
  Ews_Current_Password = 'ews-admin-current-password',
  Ews_Admin_PasswordSet = 'ews-admin-password-set',
  Ews_Proposed_Password = 'ews-admin-Proposed-password',
  Ews_Admin_Password_Complexity = 'ews-admin-password-complexity',
  Ews_Admin_Password_AccountLockout = 'ews-admin-password-account-lockout',
  Ews_Admin_Password_Maximum_Attempts = 'ews-admin-password-maximum-attempts',
  Ews_Admin_Password_ResetAfterSecs = 'ews-admin-password-reset-after-secs',
  Ews_Admin_Password_LockDurationSecs = 'ews-admin-password-lock-duration-secs',
  PJL_Password = 'pjl-password',
  LDAP_SignIn = 'ldap_signIn',
  LDAP_Server_Address = 'ldap_server_address',
  LDAP_Port_Number = 'ldap_port_number',
  LDAP_Use_SSL = 'ldap_use_ssl',
  LDAP_Server_Authentication = 'ldap_server_authentication',
  LDAP_MFP_User_Credentials = 'ldap_mfp_user_credentials',
  LDAP_Bind_Prefix = 'ldap_bind_prefix',
  LDAP_Admin_Credentials = 'ldap_admin_credentials',
  LDAP_Admin_DN = 'ldap_admin_dn',
  LDAP_Admin_DN_Password = 'ldap_admin_dn_password',
  LDAP_Bind_And_Search_Root = 'ldap_bind_and_search_root',
  LDAP_match_name_with_attribute = 'ldap_match_name_with_attribute',
  LDAP_retrieve_user_email = 'ldap_retrieve_user_email',
  LDAP_retrieve_user_name = 'ldap_retrieve_user_name',
  LDAP_retrieve_user_group = 'ldap_retrieve_user_group',
  LDAP_exact_match_on_group_attribute = 'ldap_exact_match_on_group_attribute',
  Gate_way = 'gate-way',
  Wifi0_GateWay = 'Wifi0_gate-way',
  Home_Screen_App_Fs4 = 'home-screen-app-fs4',
  Home_Screen_Custom = 'home-screen-custom',
  IP_Config_Precedence = 'ip-config-precedence',
  Ipv4_Info = 'ipv4-info',
  Wifi0_info = 'wifi0_info',
  Ipv6_Info = 'ipv6-info',
  Wifi0_IPv6Info = 'Wifi0-IPv6Info',
  IPv6_DomainName = 'ipv6domain_name',
  Wifi0IPV6DomainName = 'Wifi0_IPv6DomainName',
  IPv6_PrimaryDNS = 'ipv6_primarydns',
  Wifi0_IPv6PrimaryDNS = 'Wifi0_IPv6Primarydns',
  IPv6_SecondaryDNS = 'ipv6_secondarydns',
  Wifi0_IPv6SecondaryDns = 'Wifi0_IPv6Secondarydns',
  IPv6ConfigurationPrecedence = 'ipv6_configurationprecedence',
  Wifi0_IPv6ConfigurationPrecedence = 'Wifi0_IPv6ConfigurationPrecedence ',
  Link_Setting = 'link-setting',
  Nw_Features = 'nw-features',
  Primary_Dns_Server = 'dns-server-primary',
  Secondary_Dns_Server = 'dns-server-secondary',
  Sleep_Delay = 'sleep-delay',
  Sleep_Schedule_Weekly = 'sleep-schedule-weekly',
  Sleep_Schedule_Holiday = 'sleep-schedule-holiday',
  Sleep_Settings_Sleep_Mode_Auto_Off_Timer = 'sleep-settings-sleep-mode-auto-off-timer',
  Sleep_Settings_Sleep_Mode = 'sleep-settings-sleep-mode',
  Sleep_Settings_Auto_On_Events = 'sleep-settings-auto-on-events',
  Sleep_Settings_Auto_Off_After_Sleep = 'sleep-settings-auto-off-after-sleep',
  Remote_Configuration_Password = 'remote-cfg-password',
  Remote_Configuration_Password_Check = 'remote-cfg-password-check',
  Remote_Configuration_Password_Configured = 'remote-cfg-password-configured',
  Remote_Configuration_Password_Minimum_Length = 'remote-cfg-password-minimum-length',
  Remote_Configuration_Password_Complexity = 'remote-cfg-password-complexity',
  Remote_Configuration_Password_Account_Lockout = 'remote-cfg-password-account-lockout',
  Remote_Configuration_Password_MaxAttempts = 'remote-cfg-password-max-attempts',
  Remote_Configuration_Password_Reset_After_Secs = 'remote-cfg-password-reset-after-secs',
  Remote_Configuration_Password_Lockout_Duration = 'remote-cfg-password-lockout-duration',
  Restrict_Color = 'restrict-Color-Access',
  Restrict_User_Permissions = 'restrict-user-permissions',
  Restrict_Application = 'restrict-Application',
  Default_Permissions = 'default-permissions',
  Non_Default_Applications = 'NonDefaultApplications',
  Service_Access_Code = 'svc-access-code',
  SNMP_V1_Enable = 'snmp-v1-enable',
  SNMPV1V2_Access_Option = 'snmpv1v2_accessoption',
  SNMPV1V2_RW_Access = 'snmp-v1-v2-read-write-access',
  SNMPV1V2_Read_Community_Name_Password = 'snmp-v1-v2-read-community-name-password',
  SNMPV1V2_RW_Community_Name_Password = 'snmp-v1-v2-read-write-community-name-password',
  SNMPV1V2_ReadOnly_Public_Allowed = 'snmp-v1-v2-readonly-public-allowed',
  SNMPV3_Enabled = 'snmp-v3-enabled',
  SNMPV3_Username = 'snmp-v3-username',
  SNMPV3_MinimumPassword_Length = 'snmp-v3-config-minimum-password-length',
  SNMPV3_KeyType = 'snmp-v3-config-keytype',
  SNMPV3_Privacy_Algorithms = 'snmp-v3-config-privacy-algorithm',
  SNMPV3_Encryption_Algorithms = 'snmp-v3-config-encryption-algorithm',
  SNMPV3_Account_Lockout = 'snmp-v3-config-account-lockout-enabled',
  SNMPV3_Maximum_Attempts = 'snmp-v3-config-maximum-attempts',
  SNMPV3_Account_Reset_Lockout_Intervel = 'snmp-v3-config-reset-lockout-interval',
  SNMPV3_Lockout_Duration = 'snmp-v3-config-lockout-duration',
  SNMPV3_Password_Complexity_Enabled = 'snmp-v3-config-password-complexity-enabled',
  SNMPV3_Privacy_Passphrase = 'snmp-v3-config-privacy-passphrase',
  SNMPV3_Authentication_Passphrase = 'snmp-v3-config-authentication-passphrase',
  SNMP_Traps = 'snmp-traps',
  Subnet_mask = 'subnet-mask',
  Wifi0_SubnetMask = 'Wifi0-subnet-mask',
  dhcp_IPv6Policy = 'dhcp_v6policy',
  Wifi0_IPv6Policy = 'Ipv6_v6Policy',
  Time_Format = 'time-format',
  Time_Zone = 'time-zone',
  DayLight_Saving = 'daylight-saving',
  Time_Services = 'time-services',
  Tray_Admin = 'tray-admin',
  Work_Path_Enablement = 'work-path-enablement',
  Web_App_Deployment = 'web-app-deployment',
  SignIn_OnceConfiguration = 'singIn-once-configuration',
  CA_Certificate = 'ca-certificate',
  ID_Certificate = 'id-certificate',
  ID_Certificate_Password = 'id-password',
  EmailAddress_RestrictionEnabled = 'email-address-restrictionEnabled',
  EmailAddress_MessageSetting = 'email-address-messageSetting',
  EmailAddress_AllowInvalidFormats = 'email-address-AllowInvalidFormats',
  EmailAddress_SignEmailMeg_SmartCard = 'email-address-SignEmailMeg-SmartCard',
  EmailAddress_DigitalSignUser = 'email-address-DigitalSign',
  EmailAddress_DigitalSignUserEditable = 'email-address-DigitalSign-UserEditable',
  EmailAddress_EncryptEmailMsg = 'email-address-EncryptEmailMsg',
  EmailAddress_EncryptEmailMsgUserEditable = 'email-address-EncryptEmailMsg-UserEditable',
  SaveTo_SharePoint = 'save-To-Share-Point',
  SendTo_Email = 'save-To-Email',
  SaveTo_NetworkFolder = 'save-To-Network-Folder',
  Smart_CloudPrint = 'smp',
  HPJetAdvantage_MoreApps = 'hpjma',
  AccountCreation = 'hpjma_acc',
  Dot1x_Authentication_PeapEnabled = 'dot1x-authentication-peapEnabled',
  Dot1x_Authentication_TlsEnabled = 'dot1x-authentication-tlsEnabled',
  Dot1x_Authentication_Username = 'dot1x-authentication-username',
  Dot1x_Authentication_Password = 'dot1x-authentication-password',
  Dot1x_Authentication_ServerId = 'dot1x-authentication-serverid',
  Dot1x_Authentication_RequireExactMatch = 'dot1x-authentication-requireExactMatch',
  Dot1x_Authentication_OnAuthenticationFailure = 'dot1x-authentication-onAuthenticationFailure',
  Dot1x_Authentication_EncryptionStrength = 'dot1x-authentication-encryptionStrength',
  Dot1x_Authentication_Reauthenticate = 'dot1x-authentication-reauthenticate',
  Dot1x_Authentication_Wireless_PeapEnabled = 'dot1x-authentication-wireless-peapEnabled',
  Dot1x_Authentication_Wireless_LeapEnabled = 'dot1x-authentication-wireless-leapEnabled',
  Dot1x_Authentication_Wireless_TlsEnabled = 'dot1x-authentication-wireless-tlsEnabled',
  Dot1x_Authentication_Wireless_Username = 'dot1x-authentication-wireless-username',
  Dot1x_Authentication_Wireless_Password = 'dot1x-authentication-wireless-password',
  Dot1x_Authentication_Wireless_ServerId = 'dot1x-authentication-wireless-serverid',
  Dot1x_Authentication_Wireless_RequireExactMatch = 'dot1x-authentication-wireless-requireExactMatch',
  Dot1x_Authentication_Wireless_EncryptionStrength = 'dot1x-authentication-wireless-encryptionStrength',
  Dot1x_Authentication_Wireless_AuthenticationServer= 'dot1x-authentication-wireless-authenticationServer',
  Wifi_Direct_Enable = 'wifi-direct-enable',
  Wifi_Direct_Suffix = 'wifi-direct-suffix',
  Wifi_Direct_Connection = 'wifi-direct-connection',
  Wifi_Direct_Password = 'wifi-direct-password',
  Wifi_Direct_Channel = 'wifi-direct-channel',
  Wifi_Direct_Hide_Broadcast = 'wifi-direct-hide-broadcast',
  Wifi_Direct_Hide_SSID = 'wifi-direct-hide-ssid',
  Wifi_Direct_Hide_Password = 'wifi-direct-hide-password',
  App_Deployment_WorkPath_Enablement = 'work-path-enablement',
  App_Deployment_AppInstallation = 'app-installation',
  Color_Copy_Mode = 'color-copy-mode',
  Copy_JobBuild = 'copy-job-build',
  HP_WebServices = 'webservices',
  Toner_Collection_Unit = 'toner-collection-unit',
  Cartridge_Protection = 'cartridge-protection',
  Home_Screen_Application = 'home-screen-application',
  Home_Screen_Language_Enabled = 'home-screen-language-enabled',
  Home_Screen_AlternateKeyboard = 'home-screen-alternate-keyboard',
  Emergency_Client_Mode = 'emergency-client-mode',
  Cartridge_Threshold_Black = 'cartridge-threshold-black',
  Cartridge_Threshold_Cyan = 'cartridge-threshold-cyan',
  Cartridge_Threshold_Magenta = 'cartridge-threshold-magenta',
  Cartridge_Threshold_Yellow = 'cartridge-threshold-yellow',
  Drum_Threshold_Black = 'drum-threshold-black',
  Drum_Threshold_Cyan = 'drum-threshold-cyan',
  Drum_Threshold_Magenta = 'drum-threshold-magenta',
  Drum_Threshold_Yellow = 'drum-threshold-yellow',
  Fuser_Kit_Threshold = 'fuser-kit-threshold',
  Use_Requested_Tray = 'use-requested-tray',
  Override_A4_Letter = 'override-a4-letter',
  Manual_Feed_Prompt = 'manual-feed-prompt',
  Tray1_Mode_Manual_feed = 'tray1-mode-manual-feed',
  System_Location = 'system-location',
  System_Contact = 'system-contact',
  Support_Contact = 'support-contact',
  Size_Type_Prompt = 'size-type-prompt',
  Proxy_Server = 'proxy-server',
  Proxy_Server_Address = 'proxy-server-address',
  Proxy_Server_Port = 'proxy-server-port',
  Proxy_Server_User = 'proxy-server-user',
  Proxy_Server_Password = 'proxy-server-password',
  Bootloader_Password = 'bootloader-password',
  Bootloader_Current_Password = 'bootloader-current-password',
  Bootloader_Proposed_Password = 'bootloader-proposed-password',
  Email_Scan_Settings = 'email-scan-settings',
  Copy_Background_Cleanup = 'copy-background-cleanup',
  Copy_Contrast = 'copy-contrast',
  Copy_Darkness = 'copy-darkness',
  Copy_Optimize_Text_Picture = 'copy-optimize-text-picture',
  Copy_Paper_Tray_Selection = 'copy-paper-tray-selection',
  Copy_Sharpness = 'copy-sharpness',
  Copy_Stamp_Position_Preview = 'copy-stamp-position-preview',
  Copy_Stamp_Content = 'copy-stamp-content',
  Online_Solutions_ShowEventQrCode = 'online-solutions-show-event-qr-code',
  Online_Solutions_ShowSupportLinks = 'online-solutions-show-support-links',
  Online_Solutions_ShowLinksInEventLog = 'online-solutions-show-links-in-event-log',
  Network_Folder_Notification_Condition = 'network-folder-notification-settings',
  Network_Folder_Notification_NotificationMode = 'network-folder-notification-notification-mode',
  Network_Folder_Notification_NotificationAddress = 'network-folder-notification-notification-address',
  Digital_Sending_Service_AllowUse = 'digital-sending-service-allow-use',
  Digital_Sending_Service_AllowTransfer = 'digital-sending-service-allow-transfer',
  Fax_Header_Settings_Phone_Number = 'fax-header-settings-phone-number',
  Fax_Header_Settings_Company_Name = 'fax-header-settings-company-name',
  Fax_Header_Settings_Location = 'fax-header-settings-location',
  Pc_Fax_Send = 'pc-fax-send',
  Fax_Send_Settings_Enable = 'fax-send-settings-enable',
  Fax_Send_Method = 'fax-send-method',
  Fax_Send_CommonJob = 'fax-send-common-job',
  Fax_Receive_Settings_Enable = 'fax-receive-settings-enable',
  Fax_Receive_Method = 'fax-receive-method',
  Fax_Receive_CommonJob = 'fax-receive-common-job',
  Device_Announcement_Agent = 'device-announcement-agent',
  Device_Announcement_Agent_Server_Address = 'device-announcement-agent-server-address',
  Device_Announcement_Agent_Server_Auth_Enabled = 'device-announcement-agent-server-auth-enabled',
  //NetworkFolder File Settings(Nffs)
  Nffs_FileName_Prefix = 'nffs-fileName-prefix',
  Nffs_Default_FileName = 'nffs-defaultfile-name',
  Nffs_UserEditable = 'nffs-user-editable',
  Nffs_FileNameSuffix = 'nffs-fileName-suffix',
  Nffs_FileNamePreview = 'nffs-fileName-preview',
  Nffs_DefaultColor_Preference = 'nffs-defaultColor-preference',
  Nffs_MetaDataFileFormat = 'nffs-metaData-fileFormat',
  Nffs_DefaultOutputQuality = 'nffs-defaultOutput-quality',
  Nffs_DefaultFileType = 'nffs-defaultFile-type',
  Nffs_DefaultResolution = 'nffs-default-resolution',
  Nffs_PdfCompression = 'nffs-pdf-compression',
  Nffs_BlockTiffCompressionMethod = 'nffs-blockTiff-compressionMethod',
  Nffs_ColorGrayScaleTiffCompressionMethod = 'nffs-colorGray-scale',
  Nffs_PdfEncryption = 'nffs-pdf-encryption',
  Nffs_BlankPageSuppression = 'nffs-blackPage-suppression',
  //Email File Settings (Emfs)
  Emfs_FileName_Prefix = 'emfs-fileName-prefix',
  Emfs_Default_FileName = 'emfs-defaultfile-name',
  Emfs_UserEditable = 'emfs-user-editable',
  Emfs_FileNameSuffix = 'emfs-fileName-suffix',
  Emfs_FileNamePreview = 'emfs-fileName-preview',
  Emfs_OriginalSize = 'emfs-original-size',
  Emfs_OriginalSlides = 'emfs-original-slides',
  Emfs_DefaultColor_Preference = 'emfs-defaultColor-preference',
  Emfs_DefaultOutputQuality = 'emfs-defaultOutput-quality',
  Emfs_DefaultResolution = 'emfs-default-resolution',
  Emfs_DefaultFileType = 'emfs-defaultFile-type',
  Emfs_PdfCompression = 'emfs-pdf-compression',
  Emfs_PdfEncryption = 'emfs-pdf-encryption',
  Emfs_BlankPageSuppression = 'emfs-blackPage-suppression',
  //
  Host_Usb_Plug_And_Play = 'host-usb-plug-and-play',
  Retrieve_From_Usb = 'retrieve-from-usb',
  Save_To_Usb = 'save-to-usb',
  Tcp_Ip_Configuration_Method = 'tcp-ip-configuration-method',
  Hp_Jetdirect_Xml_Services = 'hp-jetdirect-xml-services',
  Temporary_Job_Storage_Limit= 'temporary-job-storage-limit',
  SMB_CIFS_Server= 'smb-cifs-server',
  SMB_CIFS_v2= 'smb-cifs-v2',
  SMB_CIFS_v3= 'smb-cifs-v3',
  FIPS_140_Compliance_Library = 'fips-140-compliance-library',
  Retain_Temporary_Print_Jobs_after_Reboot = 'retain-temporary-print-jobs-after-reboot',
  WebScan_eSCL = 'webscan-escl',
  WebScan_eSCLSecure = 'webscan-escl-secure',
  Fuser_Kit_Very_Low_Action = 'fuser-kit-very-low-action',
  Maintenance_Kit_threshold = 'maintenance-kit-threshold',
  TransferKit_threshold = 'transfer-kit-threshold',
  Supply_Low_Alerts = 'supply-low-alerts',
}

export enum SubFeatureControlTypeEnum {
  Password = 'password',
  PasswordView = 'password-view',
  Textbox = 'textbox',
  Toggle = 'toggle',
  ToggleView = 'toggle-view',
  Dropbox = 'dropbox',
  Radio = 'radio',
  Checkbox = 'checkbox',
  Table = 'table',
  Label = 'label',
  MultiSelectCheckbox = 'multi-select-checkbox',
  FwUpdateSchedule = 'fwu-schedule',
  FwUpdateVersion = 'fwu-version',
  Customize = 'customize',
  WebAppsDeployment = 'webAppsDeployment',
  File = 'file',
  IPconfigPrecedence = 'ipconfig-precedence',
  LowThreshold = 'low-threshold',
  HomeScreenFutureSmart = 'home-screen-future-smart',
  IconCheckMark = 'icon-check-mark',
  VeryLowAction = 'very-low-action',
}

export enum SubFeatureAccessModeEnum {
  READONLY = 'readOnly',
  WRITEONLY = 'writeOnly',
  READWRITE = 'readWrite',
  READWRITE_DISABLE = 'readWriteDisable',
}

export enum SubFeatureCustomModeEnum {
  READVIEW = 'readView',
  WRITEVIEW = 'editView',
}

export enum SubFeatureFleetDataStatus {
  PENDING = 'pending',
  FAILURE = 'failed',
}

export enum SubfeatureIndentEnum {
  Indent_Level_1 = '32px',
  Indent_Level_2 = '64px',
}

export const CORSIds = ['site'];
export const AutoSendWebAddress = ['autoSend_WebAddress'];
export const AutoSendEmailAddress = ['emailAddress'];
export const ApplicationIds = ['applicationName', 'allowColorAndQuality'];
export const IPconfigPrecedenceTableIds = ['method'];
export const LDAPBindAndSearchRootIds = ['ldapBindRoots'];
export const EmailAddMsgSettingTableIds = ['option_name', 'value', 'userEditable'];

export const OutgoingServersTableIds = ['serverName', 'port', 'serverUsage'];

export const SleepScheduleWeeklyTableIds = [
  'column1_EventType',
  'column2_Day',
  'column3_SleepTime',
  'column4_WakeTime',
];

export const SleepScheduleHolidayTableIds = ['column1_EventName', 'column2_Start', 'column3_End'];

export const AppDeploymentTableIds = ['name', 'version', 'provider'];

export const TrayAdminTableIds = ['mediaSourceId', 'currentMediaSize', 'currentMediaType'];

export const PermissionSetsTableIds = ['permission', 'type'];

export const DelayVeryLowMessageTableIds = ['threshold_name', 'page_threshold'];

export const ControlPanelTableIds = ['Application', 'SignInMethod'];
export const FaxMethodIds = ['option_name', 'value'];

export const FaxSendMethodIds = ['option_name', 'value'];

export const CopyStampContentsTableIds = ['startNumber', 'textFont', 'textSize', 'textColor','whiteBackground'];

// LocalizationPath
export enum LocalizationPathEnum {
  Control_Panel_Language = 'fe-ctrl-panel-language.name',
  Date_Format = '',
}
