import { SubfeatureIdEnum } from '../config/SubFeatureEnums';
export const AppConfig = {
  notSupportedFeature: {
    featureFlag: true,
    pagination: '10',
  },
  editFilters: {
    featureFlag: true,
  },
  batchModeSearch: {
    featureFlag: true,
  },
  batchModeToggleFilter: {
    featureFlag: true,
  },
  controlledPolicy: {
    featureFlag: false,
  },
  entitlementFeature: {
    featureFlag: true,
  },

  incompletePolicySetting: {
    featureFlag: false,
  },
  customizeGetSetting: [
    SubfeatureIdEnum.Ews_Proposed_Password,
    // SubfeatureIdEnum.EmailAddress_RestrictionEnabled,
    SubfeatureIdEnum.SNMPV1V2_RW_Access,
    // SubfeatureIdEnum.Dot1x_Authentication_OnAuthenticationFailure,
    SubfeatureIdEnum.Dot1x_Authentication_OnAuthenticationFailure,
    SubfeatureIdEnum.Wifi_Direct_Suffix,
    SubfeatureIdEnum.Wifi_Direct_Channel,
    SubfeatureIdEnum.Nffs_DefaultColor_Preference,
    SubfeatureIdEnum.Nffs_FileNamePreview,
    SubfeatureIdEnum.Dot1x_Authentication_Wireless_Password,
    SubfeatureIdEnum.Emfs_DefaultColor_Preference,
    SubfeatureIdEnum.Emfs_FileNamePreview,
  ],
  commonTokenSettings_MediaSize: [
    SubfeatureIdEnum.Emfs_OriginalSize,
    SubfeatureIdEnum.Default_Media_Size,
  ],
  commonTokenSettings_MediaType: [
    SubfeatureIdEnum.Default_Media_Type,
  ],
  commonTokenSettings_Language:[
    SubfeatureIdEnum.Control_Panel_Language,
    SubfeatureIdEnum.Ews_Language,
  ]
};
