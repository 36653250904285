import React, { Fragment } from 'react';
import {
  SubFeatureControlTypeEnum,
} from '../../../../config/SubFeatureEnums';
import * as Style from './Styles';
import { useSectionFieldData } from './useSectionFieldData';

export const SectionField = (props) => {
  const { featureId, value, result, subFeatureId, wex, deviceConstraints, deviceCacheResult, tableTitle} = props;
  const {
    getFleetDataValue,
    getFleetDataOptions,
    getFleetDataDefaultValue,
    getFleetDataTable,
    getFleetDataIPconfigPrecedence,
    getDeviceCacheCartridgeThreshold,
    getDeviceCacheHomeScreenFuturesmart,
    getDeviceCacheCopyStampPositionPreview,
    getDeviceCacheVeryLowAction,
  } = useSectionFieldData();
 

  let component;
  switch (props.type) {
    case SubFeatureControlTypeEnum.Textbox:
      component = getFleetDataValue(featureId, subFeatureId, value, result);
      break;
    case SubFeatureControlTypeEnum.Password:
      component = getFleetDataDefaultValue(value, result, SubFeatureControlTypeEnum.Password);
      break;
    case SubFeatureControlTypeEnum.Dropbox:
    case SubFeatureControlTypeEnum.Radio:
    case SubFeatureControlTypeEnum.Toggle:
    case SubFeatureControlTypeEnum.Checkbox:
      component = getFleetDataOptions(featureId, value, result, subFeatureId);
      break;
    case SubFeatureControlTypeEnum.File:
      component = getFleetDataDefaultValue(value, result, SubFeatureControlTypeEnum.File);
      break;
    case SubFeatureControlTypeEnum.Table:
      component = getFleetDataTable(featureId, value, result, subFeatureId, deviceConstraints, wex,deviceCacheResult, tableTitle);
      break;
    case SubFeatureControlTypeEnum.IPconfigPrecedence:
      component = getFleetDataIPconfigPrecedence(featureId, value, result, subFeatureId);
      break;
    case SubFeatureControlTypeEnum.LowThreshold:
      component = getDeviceCacheCartridgeThreshold(featureId, value, result);
      break;
    case SubFeatureControlTypeEnum.VeryLowAction:
      component = getDeviceCacheVeryLowAction(featureId, value, result, subFeatureId);
      break;
    case SubFeatureControlTypeEnum.HomeScreenFutureSmart:
      component = getDeviceCacheHomeScreenFuturesmart(featureId, value, result, subFeatureId);
      break;
    case SubFeatureControlTypeEnum.IconCheckMark:
      component = getDeviceCacheCopyStampPositionPreview(featureId, value, result, subFeatureId);
      break;
  }
  return (
    <Fragment>
      <Style.SubFeatureValue id={`${subFeatureId}-value`}>{component}</Style.SubFeatureValue>
    </Fragment>
  );
};
