export const SubfeatureIdMap = [
  {
    subFeatureId: 'snmpv1v2_enabled',
    resource: 'snmpConfig',
    settingsName: 'snmpV1V2Config.enabled',
  },
  {
    subFeatureId: 'snmpv1v2_accessoption',
    resource: 'snmpConfig',
    settingsName: 'snmpV1V2Config.accessOption',
  },
  {
    subFeatureId: 'snmpv1v2_readonlycommunityname',
    resource: 'snmpConfig',
    settingsName: 'snmpV1V2Config.readOnlyCommunityName',
  },
  {
    subFeatureId: 'snmpv1v2_writeonlycommunityname',
    resource: 'snmpConfig',
    settingsName: 'snmpV1V2Config.writeOnlyCommunityName',
  },
  {
    subFeatureId: 'snmpv1v2_readonlypublicallowed',
    resource: 'snmpConfig',
    settingsName: 'snmpV1V2Config.readOnlyPublicAllowed',
  },
  {
    subFeatureId: 'snmpv1v2_readonlycommunitynameset',
    resource: 'snmpConfig',
    settingsName: 'snmpV1V2Config.readOnlyCommunityNameSet',
  },
  {
    subFeatureId: 'snmpv1v2_writeonlycommunitynameset',
    resource: 'snmpConfig',
    settingsName: 'snmpV1V2Config.writeOnlyCommunityNameSet',
  },
  {
    subFeatureId: 'snmpv3_enabled',
    resource: 'snmpConfig',
    settingsName: 'snmpV3Config.enabled',
  },
  {
    subFeatureId: 'snmpv3_keytype',
    resource: 'snmpConfig',
    settingsName: 'snmpV3Config.keyType',
  },
  {
    subFeatureId: 'snmpv3_securityname',
    resource: 'snmpConfig',
    settingsName: 'snmpV3Config.securityName',
  },
  {
    subFeatureId: 'snmpv3_acclockoutenabled',
    resource: 'snmpConfig',
    settingsName: 'snmpV3Config.accountLockout.enabled',
  },
  {
    subFeatureId: 'snmpv3_acclockoutmaxattempts',
    resource: 'snmpConfig',
    settingsName: 'snmpV3Config.accountLockout.maximumAttempts',
  },
  {
    subFeatureId: 'snmpv3_acclockoutresetlockoutcounterinterval',
    resource: 'snmpConfig',
    settingsName: 'snmpV3Config.accountLockout.resetLockoutCounterInterval',
  },
  {
    subFeatureId: 'snmpv3_acclockoutlockoutinterval',
    resource: 'snmpConfig',
    settingsName: 'snmpV3Config.accountLockout.lockoutInterval',
  },
  {
    subFeatureId: 'snmpv3_privacyalgo',
    resource: 'snmpConfig',
    settingsName: 'snmpV3Config.privacyAlgorithm',
  },
  {
    subFeatureId: 'snmpv3_minpwdlength',
    resource: 'snmpConfig',
    settingsName: 'snmpV3Config.minPasswordLength',
  },
  {
    subFeatureId: 'snmpv3_privacypassphrase',
    resource: 'snmpConfig',
    settingsName: 'snmpV3Config.privacyPassphrase',
  },
  {
    subFeatureId: 'snmpv3_privacypassphraseset',
    resource: 'snmpConfig',
    settingsName: 'snmpV3Config.privacyPassphraseSet',
  },
  {
    subFeatureId: 'snmpv3_authalgo',
    resource: 'snmpConfig',
    settingsName: 'snmpV3Config.authenticationAlgorithm',
  },
  {
    subFeatureId: 'snmpv3_authpassphrase',
    resource: 'snmpConfig',
    settingsName: 'snmpV3Config.authenticationPassphrase',
  },
  {
    subFeatureId: 'snmpv3_pwdcomplexityenabled',
    resource: 'snmpConfig',
    settingsName: 'snmpV3Config.passwordComplexityEnabled',
  },
  {
    subFeatureId: 'snmpv3_authpassphraseset',
    resource: 'snmpConfig',
    settingsName: 'snmpV3Config.authenticationPassphraseSet',
  },
  {
    subFeatureId: 'adewspwd_proposedpwd',
    resource: 'deviceAdminConfig',
    settingsName: 'credentials.proposedPassword',
  },
  {
    subFeatureId: 'adewspwd_currpwd',
    resource: 'deviceAdminConfig',
    settingsName: 'credentials.currentPassword',
  },
  {
    subFeatureId: 'adewspwd_cfgbyuser',
    resource: 'deviceAdminConfig',
    settingsName: 'configuredByUser',
  },
  {
    subFeatureId: 'adewspwd_pwdset',
    resource: 'deviceAdminConfig',
    settingsName: 'passwordSet',
  },
  {
    subFeatureId: 'adewspwd_acclockout',
    resource: 'accountPolicy',
    settingsName: 'accountLockout.enabled',
  },
  {
    subFeatureId: 'adewspwd_maxattempts',
    resource: 'accountPolicy',
    settingsName: 'accountLockout.maximumAttempts',
  },
  {
    subFeatureId: 'adewspwd_resetattemptsaftersecs',
    resource: 'accountPolicy',
    settingsName: 'accountLockout.resetLockoutCounterInterval',
  },
  {
    subFeatureId: 'adewspwd_lockoutdurationsecs',
    resource: 'accountPolicy',
    settingsName: 'accountLockout.lockoutInterval',
  },
  {
    subFeatureId: 'adewspwd_minpwdlength',
    resource: 'accountPolicy',
    settingsName: 'minPasswordLength',
  },
  {
    subFeatureId: 'adewspwd_pwdcomplexity',
    resource: 'accountPolicy',
    settingsName: 'passwordComplexityEnabled',
  },
  {
    subFeatureId: 'ewsls_languagesettings',
    resource: 'ews_configuration',
    settingsName: 'selectedLanguage',
  },
  {
    subFeatureId: 'ewsls_languagesource',
    resource: 'ews_configuration',
    settingsName: 'languageSource',
  },
  {
    subFeatureId: '802Auth_PEAP',
    resource: 'adapterConfigs',
    settingsName: 'eth.ethConfig.dot1xAuthConfig.eapPeapEnabled',
  },
  {
    subFeatureId: '802Auth_EapTls',
    resource: 'adapterConfigs',
    settingsName: 'eth.ethConfig.dot1xAuthConfig.eapTlsEnabled',
  },
  {
    subFeatureId: 'dot1xAuthConfig_wireless_eapPeapEnabled',
    resource: 'wirelessConfig',
    settingsName: 'dot1xAuthConfig.eapPeapEnabled',
  },
  {
    subFeatureId: 'dot1xAuthConfig_wireless_eapLeapEnabled',
    resource: 'wirelessConfig',
    settingsName: 'dot1xAuthConfig.eapLeapEnabled',
  },
  {
    subFeatureId: 'dot1xAuthConfig_wireless_eapTlsEnabled',
    resource: 'wirelessConfig',
    settingsName: 'dot1xAuthConfig.eapTlsEnabled',
  },
  {
    subFeatureId: 'outgngserv_splitEmailOptions',
    resource: 'smtpServers',
    settingsName: 'splitEmailOptions',
  },
  {
    subFeatureId: 'outgngserv_useSsl',
    resource: 'smtpServers',
    settingsName: 'useSsl',
  },
  {
    subFeatureId: 'outgngserv_RequireAuth',
    resource: 'smtpServers',
    settingsName: 'serverRequireAuthentication',
  },
  {
    subFeatureId: 'outgngserv_credType',
    resource: 'smtpServers',
    settingsName: 'credential.credentialType',
  },
  {
    subFeatureId: 'outgngserv_servAddr',
    resource: 'smtpServers',
    settingsName: 'overwriteOption',
  },
  {
    subFeatureId: 'ldapsis_enb',
    resource: 'ldapConfig',
    settingsName: 'enabled',
  },
  {
    subFeatureId: 'server_authentication',
    resource: 'ldapConfig',
    settingsName: 'ldapSignInConfig.secureConnectionEnabled',
  },
  {
    resource: 'proxy_config',
    settingsName: 'httpProxy.enabled',
    subFeatures: [
    { subFeatureId: 'proxy-server-address' },
    { subFeatureId: 'proxy-server-port' },
    { subFeatureId: 'proxy-server-user' },
    { subFeatureId: 'proxy-server-password' },
  ]
},
  {
    resource: 'workPath_solutionManager_Configuration',
    settingsName: 'workpathEnabledState',
    subFeatures: [
    { subFeatureId: 'wpe_appInstalled' },
  ],
  },
  {
    subFeatureId: 'wifidirect_enable',
    resource: 'wifiDirectConfig',
    settingsName: 'enabled',
  },
  {
    subFeatureId: 'wifidirect_connection',
    resource: 'wifiDirectConfig',
    settingsName: 'connectionMethod',
  },
  {
    subFeatureId: 'rmcfgpwd_acclockout',
    resource: 'dssConfig',
    settingsName: 'accountLockout.enabled',
  },
  // ldapSignInConfig.serverAddress
];
