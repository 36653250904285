import React, { Fragment } from 'react';
import { SubFeatureAccessModeEnum } from '../../../../config/SubFeatureEnums';
import { SectionField } from '../../ControlTemplates/SectionField/SectionField';
import * as Style from '../Styles';
import { SubFeatureTitle } from '../Title/SubFeatureTitle';
import LocalizeHelper from '../../../../helper/LocalizeHelper';
export const SubFeatureSection = (props) => {
  const { deviceCacheResult, subFeature, featureId, wex } = props;
    const { getDeviceSettingsLocString } = LocalizeHelper();
  return (
    <Fragment>
      {subFeature.accessMode !== SubFeatureAccessModeEnum.WRITEONLY ? (
        <>
        {subFeature.header ? <Style.AccessControlHeader>{getDeviceSettingsLocString(featureId, subFeature.header)}</Style.AccessControlHeader> : null}
        <Style.SubFeature subFeatureId = {subFeature.id}>
          <SubFeatureTitle
            label={subFeature.label}
            info={subFeature.info}
            info1={subFeature.info1}
            featureId={featureId}
            subFeatureId={subFeature.id}
          />
          <SectionField
            deviceCacheResult={deviceCacheResult}
            type={subFeature.type}
            tableTitle = {subFeature.tableTitle}
            result={subFeature.fleetResult}
            value={subFeature.fleetValue}
            featureId={featureId}
            subFeatureId={subFeature.id}
            settingsName={subFeature.settingsName}
            deviceConstraints={subFeature.constraints}
            wex = {wex}
          />
        </Style.SubFeature>
        </>
      ) : null}
    </Fragment>
  );
};