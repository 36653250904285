import { Password } from '@veneer/core';
import React, { Fragment, memo, useContext, useEffect, useState } from 'react';
import { ConstraintsEffectEnum } from '../../config/ConstraintsEnums';
import { FeatureEffectivePolicyEnum } from '../../config/FeatureEnums';
import { SubFeatureAccessModeEnum } from '../../config/SubFeatureEnums';
import { PasswordValidator } from '../../constraintsValidators/PasswordValidator';
import EditDataContext from '../../context/EditDataContext';
import LocalizeHelper from '../../helper/LocalizeHelper';
import TempDataHelper from '../../helper/TempDataHelper';
import { InputTitle } from '../CommonExtended/InputTitle/InputTitle';
import { InputWrap } from './Styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { MockLaunchDarklyAdvanceEntitlement } from '../../../tests/mocks/launchDarkly';

const PasswordControl = (props) => {
  const { featuresData, entitlement,device_settings, featuresId, isChecked, isControlledPolicy } = props;
  const { entitlementId} = featuresData;
  const { getRulesEffect } = TempDataHelper();

  const regexData = device_settings.constraints.splitRegex;
  const constraintsData = device_settings.constraints;
  const constraintDataType = device_settings.constraints.type;
  const deviceSettingsAttr = device_settings.attribute;

  const {
    validatePassword,
    validateIntPassword,
    validateConfirmPassword,
    requirementsItems,
    errorState,
    confPassErrorState,
    hide,
    setHide,
    passwordError,
    confirmPasswordError,
  } = PasswordValidator(regexData, constraintsData);

  const { onUpdateTempData, tempRulesEffect, customErrorData, editViewData } =
    useContext(EditDataContext);
  const entitlementCondition = entitlement?.map((data) =>
    data?.includes('ws-hp.com/smcloud-advanced'));
    const ldAdvancedEntitlement = props.isWex ? MockLaunchDarklyAdvanceEntitlement : useFlags()?.dcAdvancedEntitlement;
  // Localization Helper
  const { getDeviceSettingsLocString } = LocalizeHelper();
  const [inputVal, setInputVal] = useState('');
  const [confirmPwdInputVal, confirmPwdsetInputVal] = useState('');
  const [effectVal, setEffectVal] = useState();
  const [wifiDirect, setWifiDirect] = useState(false);
  // Strings
  const label = getDeviceSettingsLocString(featuresId, device_settings.label);
  const placeholder = getDeviceSettingsLocString(featuresId, device_settings.placeholder);
  const confirmPasswordPlaceholder = getDeviceSettingsLocString(featuresId, device_settings.confirmPasswordPlaceholder);
  const confirmPasswordLabel = getDeviceSettingsLocString(
    featuresId,
    device_settings.confirmPasswordLabel,
  );
  const tooltipMessage = getDeviceSettingsLocString(featuresId, device_settings.info);
  const confirmPasswordErrMsg = getDeviceSettingsLocString(
    featuresId,
    `${device_settings.id}-confirmpassword_error`,
  );

  let isDisable =
    effectVal &&
    (effectVal === ConstraintsEffectEnum.ENABLE || effectVal === ConstraintsEffectEnum.SET)
      ? true
      : isChecked && isChecked
      ? isChecked
      : isControlledPolicy === FeatureEffectivePolicyEnum.controlledPolicy &&
        device_settings.accessMode === SubFeatureAccessModeEnum.READWRITE_DISABLE
      ? true
      : false;


      if (entitlementId && ldAdvancedEntitlement && !entitlementCondition?.includes(true)) {
        isDisable = true;
      }

  useEffect(() => {
    if (regexData || constraintsData.minimum || constraintsData.maximum) {
      validateConfirmPassword(inputVal, confirmPwdInputVal, confirmPasswordErrMsg);
    }
  }, [confirmPwdInputVal]);

  const handleOnchange = (value) => {
    const isInteger = /^[0-9]+$/;
    let inputErrorData;
    let inputValue;

    if (device_settings.id === "bootloader-current-password" || device_settings.id === "bootloader-proposed-password") {
      if (value === '' || isInteger.test(value)) {
        validatePassword(value);
        validateConfirmPassword(value, confirmPwdInputVal, confirmPasswordErrMsg);
        setInputVal(value);
        inputValue = value;
        inputErrorData =
          validatePassword(value) ||
          validateConfirmPassword(value, confirmPwdInputVal, confirmPasswordErrMsg);
      } else {
        inputValue = inputVal;
      }
    } else if (regexData || constraintsData.minimum || constraintsData.maximum) {
      if (constraintDataType == 'integer') {
        if (value === '' || isInteger.test(value)) {
          validateIntPassword(value);
          validateConfirmPassword(value, confirmPwdInputVal, confirmPasswordErrMsg);
          setInputVal(value);
          inputValue = value;

          inputErrorData =
            validateIntPassword(value) ||
            validateConfirmPassword(value, confirmPwdInputVal, confirmPasswordErrMsg);
        } else {
          inputValue = inputVal;
        }
      } else {
        validatePassword(value);
        validateConfirmPassword(value, confirmPwdInputVal, confirmPasswordErrMsg);

        setInputVal(value);

        inputValue = value;

        inputErrorData =
          validatePassword(value) ||
          validateConfirmPassword(value, confirmPwdInputVal, confirmPasswordErrMsg);
      }
    } else {
      setInputVal(value);
      inputValue = value;
    }
    onUpdateTempData({
      settingsName: device_settings.settingsName,
      fleetValue: inputValue,
      confirmPasswordValue: confirmPwdInputVal,
      attribute: device_settings.attribute,
      error: inputErrorData,
    });
  };

  const handlePasswordValidation = (inputVal, value, confirmPasswordErrMsg) => {
    validateConfirmPassword(inputVal, value, confirmPasswordErrMsg);
    confirmPwdsetInputVal(value);
    return {
      inputValue: value,
      inputErrorData: constraintDataType === 'integer' ? validateIntPassword(inputVal) : validatePassword(inputVal) || validateConfirmPassword(inputVal, value, confirmPasswordErrMsg),
    };
  };
  
  const confirmPassword = (value) => {
    const isInteger = /^[0-9]+$/;
    let inputErrorData;
    let inputValue;

    if (device_settings.id === "bootloader-proposed-password") {
      if (value === '' || isInteger.test(value)) {
        ({ inputValue, inputErrorData } = handlePasswordValidation(inputVal, value, confirmPasswordErrMsg));
      } else {
        inputValue = inputVal;
      }
    } else if (regexData || constraintsData.minimum || constraintsData.maximum) {
      if (constraintDataType === 'integer') {
        if (value === '' || isInteger.test(value)) {
          ({ inputValue, inputErrorData } = handlePasswordValidation(inputVal, value, confirmPasswordErrMsg));
        } else {
          inputValue = confirmPwdInputVal;
        }
      } else {
        ({ inputValue, inputErrorData } = handlePasswordValidation(inputVal, value, confirmPasswordErrMsg));
      }
    }

    onUpdateTempData({
      settingsName: device_settings.settingsName,
      fleetValue: inputVal,
      confirmPasswordValue: inputValue,
      attribute: device_settings.attribute,
      error: inputErrorData,
    });
  };

  const handleOnBlur = (event) => {
    setHide(true);

    if (regexData || constraintsData.minimum || constraintsData.maximum) {
      if (constraintDataType == 'string') {
        validatePassword(event.target.value);
        validateConfirmPassword(event.target.value, confirmPwdInputVal, confirmPasswordErrMsg);
      } else {
        validateIntPassword(event.target.value);
        validateConfirmPassword(event.target.value, confirmPwdInputVal, confirmPasswordErrMsg);
      }
    }
  };

  const handleOnBlur2 = (event) => {
    setHide(true);

    if (regexData || constraintsData.minimum || constraintsData.maximum) {
      validateConfirmPassword(inputVal, event.target.value, confirmPasswordErrMsg);
    }
  };

  const handleWifiDirect = () => {
    const wifiDirectConnectionMethod = editViewData.find(
      (data) => data.name === 'wifi-direct.method',
    );
    const wifiDirectValue = wifiDirectConnectionMethod?.value;
    const wifiItem = editViewData.find((item) => item.name === 'wifi-direct.password');

    if (wifiItem && wifiDirectValue !== 'automatic') {
      setWifiDirect(true);
    } else {
      setWifiDirect(false);
      setInputVal(''); // Ensure state is reset
    }
  };

  useEffect(() => {
    // This ensures that effectVal gets updated properly
    const ruleEffect = getRulesEffect(tempRulesEffect, device_settings.settingsName).effect;
    setEffectVal(ruleEffect);

    if (device_settings.id === 'wifi-direct-password') {
      handleWifiDirect(); // Ensure permissions are handled on password control.
    }
  }, [tempRulesEffect, editViewData, device_settings.settingsName, device_settings.id]);

  return (
    <Fragment>
      <>
        {wifiDirect ? (
          <InputWrap>
            <InputTitle
              title={label}
              tooltipMessage={tooltipMessage}
              info={device_settings.info}
              disable={false}
            />
            <Password 
              id={device_settings.attribute}
              disabled={false}
              value={inputVal}
              error={isDisable ? false : errorState || customErrorData.error}
              onChange={(value) => handleOnchange(value)}
              requirements={requirementsItems}
              hideRequirements={hide}
              onFocus={() => setHide(false)}
              onBlur={handleOnBlur}
              i18n={errorState ? passwordError : customErrorData.errorMsg}
              placeholder={placeholder? placeholder : ''}
            />
          </InputWrap>
        ) : (
          <InputWrap>
            <InputTitle
              title={label}
              tooltipMessage={tooltipMessage}
              info={device_settings.info}
              disable={isDisable}
            />
            <Password
              id={device_settings.attribute}
              disabled={isDisable}
              value={inputVal}
              error={isDisable ? false : errorState || customErrorData.error}
              onChange={(value) => handleOnchange(value)}
              requirements={requirementsItems}
              hideRequirements={hide}
              onFocus={() => setHide(false)}
              onBlur={handleOnBlur}
              i18n={errorState ? passwordError : customErrorData.errorMsg}
              placeholder={placeholder? placeholder : ''}
            />
          </InputWrap>
        )}
        {device_settings.confirmpassword && (
          <InputWrap>
            <InputTitle title={confirmPasswordLabel} disable={isDisable} />
            <Password
              className={'password'}
              id={device_settings.attribute}
              value={confirmPwdInputVal}
              error={isDisable ? false : confPassErrorState}
              disabled={isDisable}
              onChange={(value) => {
                confirmPassword(value);
              }}
              requirements={requirementsItems}
              hideRequirements={true}
              onBlur={handleOnBlur2}
              i18n={confirmPasswordError}
              placeholder={confirmPasswordPlaceholder? confirmPasswordPlaceholder : ''}
            />
          </InputWrap>
        )}
      </>
    </Fragment>
  );
};

export default memo(PasswordControl);
