import React from 'react';
import useSystemLaunchers from '../../../../helper/SystemLaunchersHelper';
import LocalizeHelper from '../../../../helper/LocalizeHelper';
import { SubFeatureFleetDataStatus, SubfeatureIdEnum } from '../../../../config/SubFeatureEnums';
import ClampedDiv from './ClampedDiv';
import TableTemplate from './Table/TableTemplate';
import IPConfigPrecedenceCard from '../../SubFeature/CustomizableSection/IPConfigPrecedence/IPConfigPrecedenceCard';
import CopyStampPositionPreview from '../../SubFeature/CustomizableSection/CopySampPostionPreview/CopyStampPositionPreview';
import ThresholdAction from '../../SubFeature/CustomizableSection/ThresholdAction/ThresholdAction';

let isEmailForNotificationForNotificationMode = false;

export const useSectionFieldData = () => {
  const { getFleetValueStrings, getDeviceConfigurationsLocString, getCommonStrings } = LocalizeHelper();
  const { findLauncherValue } = useSystemLaunchers();
  const noDataAvailable = getCommonStrings('noDataAvailable');
  const { LowThresholdValue, VeryLowActionValue } = ThresholdAction();

  const getFleetDataValue = (id, subId, value, result) => {
    if (value != null && value != 'undefined') {
      if (result == SubFeatureFleetDataStatus.PENDING) {
        return noDataAvailable;
      } else if (result == SubFeatureFleetDataStatus.FAILURE) {
        return noDataAvailable;
      } else {
        return <ClampedDiv children={value} id={id} subId={subId} />;
      }
    } else {
      return noDataAvailable;
    }
  };

  const getFleetDataOptions = (id, value, result, subId) => {
    if (subId === SubfeatureIdEnum.Network_Folder_Notification_NotificationMode && value === 'email') {
      isEmailForNotificationForNotificationMode = true;
    } 
    if (result !== SubFeatureFleetDataStatus.FAILURE && result !== SubFeatureFleetDataStatus.PENDING && subId === SubfeatureIdEnum.AutoSend_Interval) {
      const frequencyValue = value.frequencyValue ? value.frequencyValue : '--';
      const frequencyUnit = value.frequencyUnit ? getDeviceConfigurationsLocString(id, value.frequencyUnit, subId) : '--';
      return `${frequencyValue} ${frequencyUnit}`;
    } else if (subId === SubfeatureIdEnum.Network_Folder_Notification_NotificationAddress) {
      if (!isEmailForNotificationForNotificationMode) {
        return '';
      }
      return value || '';
    } else if (value != null && value !== 'undefined') {
      const valueToString = value?.toString();
      if (result == SubFeatureFleetDataStatus.PENDING) {
        return noDataAvailable;
      } else if (result == SubFeatureFleetDataStatus.FAILURE) {
        return noDataAvailable;
      } else if (valueToString) {
        return valueToString !== 'noSuchInstance' ? getDeviceConfigurationsLocString(id, valueToString, subId) : '--';
      } else {
        return noDataAvailable;
      }
    } else {
      return noDataAvailable;
    }
  };

  const getFleetDataDefaultValue = (value, result, key) => {
    if (value != null && value != 'undefined') {
      if (result == SubFeatureFleetDataStatus.PENDING) {
        return noDataAvailable;
      } else if (result == SubFeatureFleetDataStatus.FAILURE) {
        return noDataAvailable;
      } else {
        return getFleetValueStrings(key, value);
      }
    } else {
      return noDataAvailable;
    }
  };

  const getFleetDataTable = (id, value, result, subFeatureId, deviceConstraints, wex, deviceCacheResult,tableTitle) => {
    if (value != null && value != 'undefined') {
      if ((subFeatureId === SubfeatureIdEnum.Fax_Send_CommonJob || subFeatureId === SubfeatureIdEnum.Fax_Receive_CommonJob) &&  (result === SubFeatureFleetDataStatus.PENDING || result === SubFeatureFleetDataStatus.FAILURE)) {
        return null;
      } else if (result == SubFeatureFleetDataStatus.PENDING) {
        return noDataAvailable;
      } else if (result == SubFeatureFleetDataStatus.FAILURE) {
        return noDataAvailable;
      } else {
        return <TableTemplate id={id} subFeatureId={subFeatureId} value={value} wex={wex} deviceConstraints={deviceConstraints} deviceCacheResult={deviceCacheResult} tableTitle={tableTitle} />;
      }
    } else if ((value === null || value === 'undefined') && (subFeatureId === SubfeatureIdEnum.Fax_Send_CommonJob ||  subFeatureId === SubfeatureIdEnum.Fax_Receive_CommonJob)) {
      return null;
    }else {
      return noDataAvailable;
    }
  };

  const getFleetDataIPconfigPrecedence = (id, value, result, subFeatureId) => {
    if (value != null && value != 'undefined') {
      if (result == SubFeatureFleetDataStatus.PENDING) {
        return noDataAvailable;
      } else if (result == SubFeatureFleetDataStatus.FAILURE) {
        return noDataAvailable;
      } else {
        return <IPConfigPrecedenceCard id={id} value={value} />;
      }
    } else {
      return noDataAvailable;
    }
  };

  const getDeviceCacheCartridgeThreshold = (id, value, result) => {
    if (value != null && value != 'undefined') {
      if (result == SubFeatureFleetDataStatus.PENDING) {
        return noDataAvailable;
      } else if (result == SubFeatureFleetDataStatus.FAILURE) {
        return noDataAvailable;
      }
      else {
        return LowThresholdValue(id, value);
      }
    } else {
      return noDataAvailable;
    }
  };

  const getDeviceCacheVeryLowAction = (id, value, result, subId) => {
    if (value != null && value != 'undefined') {
      if (result == SubFeatureFleetDataStatus.PENDING) {
        return noDataAvailable;
      } else if (result == SubFeatureFleetDataStatus.FAILURE) {
        return noDataAvailable;
      }
      else {
        return VeryLowActionValue(id, value, subId);
      }
    } else {
      return noDataAvailable;
    }
  };

  const getDeviceCacheHomeScreenFuturesmart = (id, value, result, subId) => {
    if (value != null && value != 'undefined') {
      if (result == SubFeatureFleetDataStatus.PENDING) {
        return noDataAvailable;
      } else if (result == SubFeatureFleetDataStatus.FAILURE) {
        return noDataAvailable;
      } else {
        const key = findLauncherValue(value);
        const resultValue = key ? getDeviceConfigurationsLocString(id, key, subId) : value;
        return resultValue;
      }
    } else {
      return noDataAvailable;
    }
  };

  const getDeviceCacheCopyStampPositionPreview= (id, value, result, subId) => {
     if (value != null && value != 'undefined') {
      if (result == SubFeatureFleetDataStatus.PENDING) {
        return noDataAvailable;
      } else if (result == SubFeatureFleetDataStatus.FAILURE) {
        return noDataAvailable;
      } else {
        return <CopyStampPositionPreview id={id} value={value} />;
      }
    } else {
      return noDataAvailable;
  };
};

  return {
    getFleetDataValue,
    getFleetDataOptions,
    getFleetDataDefaultValue,
    getFleetDataTable,
    getFleetDataIPconfigPrecedence,
    getDeviceCacheCartridgeThreshold,
    getDeviceCacheVeryLowAction,
    getDeviceCacheHomeScreenFuturesmart,
    getDeviceCacheCopyStampPositionPreview,
  };
};
