import { Select } from '@veneer/core';
import React, { memo, useContext, useEffect, useState } from 'react';
import { ConstraintsEffectEnum } from '../../config/ConstraintsEnums';
import { FeatureEffectivePolicyEnum } from '../../config/FeatureEnums';
import { SubFeatureAccessModeEnum } from '../../config/SubFeatureEnums';
import DropDownValidator from '../../constraintsValidators/DropDownValidator';
import EditDataContext from '../../context/EditDataContext';
import LocalizeHelper from '../../helper/LocalizeHelper';
import TempDataHelper from '../../helper/TempDataHelper';
import { InputTitle } from '../CommonExtended/InputTitle/InputTitle';
import { InputWrap } from './Styles';

const DropDownControl = (props) => {
  const { device_settings, featuresId, isChecked, isControlledPolicy } = props;

  const { onUpdateTempData, tempRulesEffect } = useContext(EditDataContext);

  const { getRulesEffect } = TempDataHelper();

  const [inputVal, setInputVal] = useState(
    device_settings.fleetValue ? [device_settings.fleetValue] : [],
  );

  const [effectVal, setEffectVal] = useState();

  const [error, setError] = useState('');

  const { getDeviceSettingsLocString, getDeviceConfigurationsLocString } = LocalizeHelper();

  const label = getDeviceSettingsLocString(featuresId, device_settings.label);
  const tooltipMessage = getDeviceSettingsLocString(featuresId, device_settings.info);
  const hint = getDeviceSettingsLocString(featuresId, device_settings.hint);
  const errorMsg = error ? getDeviceSettingsLocString(featuresId, error) : '';

  const isDisable =
    effectVal &&
    (effectVal === ConstraintsEffectEnum.ENABLE || effectVal === ConstraintsEffectEnum.SET)
      ? true
      : isChecked && isChecked
      ? isChecked
      : isControlledPolicy === FeatureEffectivePolicyEnum.controlledPolicy &&
        device_settings.accessMode === SubFeatureAccessModeEnum.READWRITE_DISABLE
      ? true
      : false;

  const supportedOptions = device_settings.constraints.options.map((v) => {
    return {
      value: v.value,
      label: getDeviceConfigurationsLocString(featuresId, v.value, device_settings.id),
    };
  });

  const handleChange = (selectedOption) => {
    setInputVal([selectedOption.value]);
    const errorKey = DropDownValidator.errocheck(selectedOption.value);
    setError(errorKey);

    onUpdateTempData({
      settingsName: device_settings.settingsName,
      fleetValue: selectedOption.value,
      attribute: device_settings.attribute,
      error: !!errorKey,
    });
  };

  useEffect(() => {
    const ruleEffect = getRulesEffect(tempRulesEffect, device_settings.settingsName).effect;
    setEffectVal(ruleEffect);
  }, [tempRulesEffect]);

  return (
    <>
      <InputWrap>
        <InputTitle
          title={label}
          tooltipMessage={tooltipMessage}
          info={device_settings.info}
          disable={isDisable}
        />
        <Select
          id={device_settings.attribute}
          placeholder={inputVal[0] ? inputVal[0] : hint}
          helperText={
            effectVal &&
            (effectVal === ConstraintsEffectEnum.ENABLE || effectVal === ConstraintsEffectEnum.SET)
              ? ''
              : errorMsg
          }
          disabled={isDisable}
          clearIcon={false}
          helperTextVisibility="auto"
          options={supportedOptions}
          value={inputVal}
          error={!!errorMsg}
          onChange={handleChange}
        />
      </InputWrap>
    </>
  );
};

export default memo(DropDownControl);
