import { FleetSvcCustomValue, FleetSvcPolicyAttributeId } from '../config/FleetServiceEnums';

export const FleetAttributeHelper = (item) => {

  const encryptionStrength = (_value) => {
    let value;
    switch (_value) {
      case 'low':
        value = `${FleetSvcCustomValue.DOT1X_AUTHENTICATION_WIRELESS_ENCRYPTIONSTRENGHT_LOW}`;
        break;
      case 'medium':
        value = `${FleetSvcCustomValue.DOT1X_AUTHENTICATION_WIRELESS_ENCRYPTIONSTRENGHT_MEDIUM}`;
        break;
      case 'high':
        value = `${FleetSvcCustomValue.DOT1X_AUTHENTICATION_WIRELESS_ENCRYPTIONSTRENGHT_HIGH}`;
        break;
    }
    return value;
  }

  const encryptionStrengthWired = (_value) => {
    let value;
    switch (_value) {
      case 'low':
        value = `${FleetSvcCustomValue.DOT1X_AUTHENTICATION_WIRED_ENCRYPTIONSTRENGHT_LOW}`;
        break;
      case 'medium':
        value = `${FleetSvcCustomValue.DOT1X_AUTHENTICATION_WIRED_ENCRYPTIONSTRENGHT_MEDIUM}`;
        break;
      case 'high':
        value = `${FleetSvcCustomValue.DOT1X_AUTHENTICATION_WIRED_ENCRYPTIONSTRENGHT_HIGH}`;
        break;
    }
    return value;
  }

  const snmpAccessOptionValues = (_value) => {
    let value;
    switch (_value) {
      case 'readOnly':
        value = `${FleetSvcCustomValue.READONLY}`;
        break;
      case 'readWrite':
        value = `${FleetSvcCustomValue.READWRITE}`;
        break;
      case 'disable':
        value = `${FleetSvcCustomValue.DISABLE}`;
        break;
    }
    return value;
  };

  const encryptionAlgorithm = (_value) => {
    let value;
    switch (_value) {
      case 'md5':
        value = `${FleetSvcCustomValue.MD5}`;
        break;
      case 'sha1':
        value = `${FleetSvcCustomValue.SHA1}`;
        break;
    }
    return value;
  };

  const ldapSetupCredential = (_value) => {
    let value;
    switch (_value) {
      case 'true':
      case true:
        value = `${FleetSvcCustomValue.LDAP_SETUP_CREDENTIAL_TRUE}`;
        break;
      case 'false':
      case false:
        value = `${FleetSvcCustomValue.LDAP_SETUP_CREDENTIAL_FALSE}`;
        break;
    }
    return value;
  };

  const ldapBindSearchRoot = (_value) => {
    let value;
    value = JSON.stringify(_value);
    return value;
  };

  const outGoingServerData = (_value) => {
    let value;

    const updatedData = _value.map((item) => {
      // Destructure the necessary keys and rename them
      const {
        automatedEmail,
        autoSend,
        splitEmail,
        credential,
        autoSendOrder,
        automatedEmailOrder,
        ...rest
      } = item;

      // Create the updated item with the new keys
      const updatedItem = {
        ...rest,
        splitEmail: (parseInt(splitEmail) * 1024).toString(),
        alert: automatedEmail,
        autosend: autoSend,
        alertOrder: automatedEmailOrder,
        autosendOrder: autoSendOrder,
      };

      // Handle the credential transformation
      updatedItem.credential = credential
        ? credential === 'signInUser'
          ? 'outgoing-servers.servers.credential.device-user'
          : 'servers.servers.credential.public'
        : 'outgoing-servers.servers.credential.device-user'; // Use the default value if undefined

      Object.keys(updatedItem).forEach((key) => {
        if (updatedItem[key] === undefined) {
          delete updatedItem[key];
        }
      });

      return updatedItem;
    });

    value = JSON.stringify(updatedData);
    return value;
  };

  const onAuthenticationFailure = (_value) => {
    let value;
    switch (_value) {
      case 'true':
      case true:
        value = `${FleetSvcCustomValue.DOT1X_AUTHENTICATION_ONAUTHENTICATIONFAILURE_TRUE}`;
        break;
      case 'false':
      case false:
        value = `${FleetSvcCustomValue.DOT1X_AUTHENTICATION_ONAUTHENTICATIONFAILURE_FALSE}`;
        break;
    }
    return value;
  };

  const outGoingServersMode = (_value) => {
    let value;
    switch (_value) {
      case 'append':
        value = `${FleetSvcCustomValue.OUTGOING_SERVERMODE_APPEND}`;
        break;
      case 'overwrite':
        value = `${FleetSvcCustomValue.OUTGOING_SERVERMODE_OVERRIDE}`;
        break;
    }
    return value;
  };

  const wifiDirectConnectionMethod = (_value) => {
    let value;
    switch (_value) {
      case 'automatic':
        value = `${FleetSvcCustomValue.WIFIDIRECT_CONNECTION_METHOD_AUTO}`;
        break;
      case 'manual':
        value = `${FleetSvcCustomValue.WIFIDIRECT_CONNECTION_METHOD_MANUAL}`;
        break;
      case 'advanced':
        value = `${FleetSvcCustomValue.WIFIDIRECT_CONNECTION_METHOD_ADVANCED}`;
        break;
    }
    return value;
  };
  const boolToString = (_value) => {
    let value;
    switch (_value) {
      case 'true':
        value = 'true';
        break;
      case 'false':
        value = 'false';
        break;
      case true:
        value = 'true';
        break;
      case false:
        value = 'false';
        break;
    }
    return value;
  };

  const appDeployment = (apps) => {
    const filteredValue = apps
      .filter((app) => app.uuid && app.config)
      .map((app) => ({
        uuid: app.uuid,
        config: app.config[0],
      }))
      .filter(function (_result) {
        return _result.config !== undefined;
      });

    value = JSON.stringify(filteredValue);
    return value;
  };

  let name, value;
  switch (item.name) {
    case `${FleetSvcPolicyAttributeId.SNMPV1V2_RW_Access}.permission`:
      name = item.name;
      value = snmpAccessOptionValues(item.value);
      break;
    case `${FleetSvcPolicyAttributeId.SNMPV3}.encryption-algorithm`:
      name = item.name;
      value = encryptionAlgorithm(item.value);
      break;
    case `${FleetSvcPolicyAttributeId.LDAP_Setup}.credential`:
      name = item.name;
      value = ldapSetupCredential(item.value);
      break;
    case `${FleetSvcPolicyAttributeId.LDAP_Setup}.roots`:
      name = item.name;
      value = ldapBindSearchRoot(item.value);
      break;
    case `${FleetSvcPolicyAttributeId.Dot1x_802_1x_auth}.on-failure`:
      name = item.name;
      value = onAuthenticationFailure(item.value);
      break;
    case `${FleetSvcPolicyAttributeId.Dot1x_802_1x_auth}.encryption-strength`:
      name = item.name;
      value = encryptionStrengthWired(item.value);
      break;
    case `${FleetSvcPolicyAttributeId.Outgoing_Server}.mode`:
      name = item.name;
      value = outGoingServersMode(item.value);
      break;
    case `${FleetSvcPolicyAttributeId.Outgoing_Server}.servers`:
      name = item.name;
      value = outGoingServerData(item.value);
      break;
    case `${FleetSvcPolicyAttributeId.App_Deployment}.apps`:
      name = item.name;
      value = appDeployment(item.value);
      break;
    // case `${FleetSvcPolicyAttributeId.Outgoing_Server_Split}`:
    //   name = item.name;
    //   value = outGoingServerSplitEmail(item.value);
    //   break;
    case `${FleetSvcPolicyAttributeId.Wifi_Direct}.method`:
      name = item.name;
      value = wifiDirectConnectionMethod(item.value);
      break;
    case `${FleetSvcPolicyAttributeId.Dot1x_802_1x_auth_wireless}.encryption-strength`:
      name = item.name;
      value = encryptionStrength(item.value);
      break;
    case `${FleetSvcPolicyAttributeId.Wifi_Direct}.do-not-broadcast-name`:
    case `${FleetSvcPolicyAttributeId.Wifi_Direct}.do-not-show-password`:
    case `${FleetSvcPolicyAttributeId.Wifi_Direct}.do-not-show-name`:
      name = item.name;
      value = boolToString(item.value);
      break;

    default:
      name = item.name;
      value = item.value;
      break;
  } 

  return { name, value };
};
