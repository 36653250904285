import React from 'react';
import { SubfeatureIdEnum, SleepScheduleWeeklyTableIds, SleepScheduleHolidayTableIds, ApplicationIds , FaxMethodIds,PermissionSetsTableIds} from '../../../../../config/SubFeatureEnums';
import useTableBody from '../../../../../hooks/table/useTableBody';
import OutGoingServerTable from './OutGoingServerTable';
import AppDeploymentTable from '../../../../CustomizableComponents/AppsDeployment/AppDeploymentTable';
import CopyStampTable from './CopyStampsTable';
import { StyledTable, StyledTableTitle } from '../Styles';
import LocalizeHelper from '../../../../../../src/helper/LocalizeHelper';
import useTableHead from '../../../../../hooks/table/useTableHead';

const  TableTemplate = ({ id, subFeatureId, value , wex , deviceConstraints, deviceCacheResult, tableTitle  }) => {
  const { getDeviceSettingsLocString } = LocalizeHelper();
  const { tableHead } = useTableHead(subFeatureId,value);
  const { tableBody } = useTableBody(id , subFeatureId, tableHead, value , deviceConstraints, deviceCacheResult);
  const tableTitleHeading = getDeviceSettingsLocString(id, tableTitle);
  const getPreferences = (subFeatureId) => {
    switch (subFeatureId) {
      case SubfeatureIdEnum.Sleep_Schedule_Holiday:
        return {
          width: [
            { columnId: SleepScheduleHolidayTableIds[0], width: 24.578 },
            { columnId: SleepScheduleHolidayTableIds[1], width: 24.819 },
            { columnId: SleepScheduleHolidayTableIds[2], width: 50.603 },
          ],
        };
      case SubfeatureIdEnum.Sleep_Schedule_Weekly:
        return {
          width: [
            { columnId: SleepScheduleWeeklyTableIds[0], width: 24.578 },
            { columnId: SleepScheduleWeeklyTableIds[1], width: 24.819 },
            { columnId: SleepScheduleWeeklyTableIds[2], width: 19.277 },
            { columnId: SleepScheduleWeeklyTableIds[3], width: 31.326 },
          ],
        };
        case SubfeatureIdEnum.Non_Default_Applications:
        return {
          width: [
            { columnId: ApplicationIds[0], width: 20},
            { columnId: ApplicationIds[1], width: 60},
          ],
        };
        case SubfeatureIdEnum.PermissionSets:
          return {
            width: [
              { columnId: PermissionSetsTableIds[0], width: 192 },
              { columnId: PermissionSetsTableIds[1], width: 286 }, // Add dynamic columns
            ],
          };
        case SubfeatureIdEnum.ControlPanelAccessTable:
          return {
            width: [
              { columnId: 'Application', width: 223 },
              { columnId: 'SignInMethod', width: 129 },
          ],
          };
        case SubfeatureIdEnum.EWS_AccessControlTable:
          return {
            width: [
              { columnId: 'Application', width: 223 },
              { columnId: 'SignInMethod', width: 129 },
          ]
          };
          case SubfeatureIdEnum.Fax_Send_Method:
          case SubfeatureIdEnum.Fax_Send_CommonJob: 
          case SubfeatureIdEnum.Fax_Receive_Method:
          case SubfeatureIdEnum.Fax_Receive_CommonJob:
          return {
            width: [
              { columnId: FaxMethodIds[0], width: 300 },
              { columnId: FaxMethodIds[1], width: 305 },
            ],
          };
        default:
          return {};
      }
  };
  let component;
  switch (subFeatureId) {
    case SubfeatureIdEnum.Outgoing_Server_List_Email:
      component = <OutGoingServerTable id={id} subFeatureId={subFeatureId} value={value} />;
      break;
    case SubfeatureIdEnum.App_Deployment_AppInstallation:
      component = <AppDeploymentTable isEditView = {false} device_settings={value}  />;
      break;
    case SubfeatureIdEnum.Copy_Stamp_Content:
      component = <CopyStampTable responseData={{ id, subFeatureId, device_settings: value }} />;
      break;
    default:
      component = (
        <>
        <StyledTableTitle>{tableTitleHeading ? tableTitleHeading : []}</StyledTableTitle>
        <StyledTable
          data-testid="my-SleepScheduleWeeklyTable"
          columns={tableHead ? tableHead : []}
          data={tableBody ? tableBody : []}
          wex={wex}
          subFeatureId={subFeatureId}
          preferences={getPreferences(subFeatureId)}
        />
        </>
      );
  }

  return component;
};

export default TableTemplate;