import React from 'react';
import { IconCheckmarkCircle } from '@veneer/core';
import { StampPreviewBox, StampCheckIcon,StampPreviewBoxLayout} from '../../Styles';
import tokens from '@veneer/tokens';

const CopyStampPositionPreview = ({ id, value }) => {

  const stampPosition = (position) => {
    return { value: position, label: 'position-' + position };
  };

  interface Item {
    readonly value: string;
    readonly label?: string;
    readonly option?: string;
  }

  class copyStampItems {
    static stampPositions: Item[] = [
      stampPosition('topLeft'),
      stampPosition('topCenter'),
      stampPosition('topRight'),
      stampPosition('bottomLeft'),
      stampPosition('bottomCenter'),
      stampPosition('bottomRight'),
    ];
  }

  // Extract all unique `locationId` values from the data
  const existingPositions = new Set(value.map((item) => item.state.reported.locationId));
  

  return (
      <StampPreviewBoxLayout>
        <StampPreviewBox>
          {copyStampItems.stampPositions.map((item) => {
            const isHighlighted = existingPositions.has(item.value);
            return (
              <StampCheckIcon>
                <IconCheckmarkCircle
                  filled={isHighlighted ? true : false}
                  color={tokens.colorGray6}
                />
              </StampCheckIcon>
            );
          })}
        </StampPreviewBox>
      </StampPreviewBoxLayout>
  );
};

export default CopyStampPositionPreview;
