import {
    ConstraintsResourceEnum,
    ConstraintsSettingsNameEnum,
    ConstraintsSubFeatureIdEnum,
  } from '../config/ConstraintsEnums';
  import { DeviceCacheCdmDataEnum } from '../config/DeviceCacheCdmDataEnums';
  import { DeviceCacheServiceGunEnum } from '../config/DeviceCacheServiceGunEnums';
  import { FeatureIdEnum } from '../config/FeatureEnums';
  import {
    SubFeatureAccessModeEnum,
    SubFeatureControlTypeEnum,
    SubfeatureIdEnum,
  } from '../config/SubFeatureEnums';

  export const CopierDataModel = [
    {
        id: `${FeatureIdEnum.ColorCopyMode}`,
        configurable: false,
        subfeatures: [
          {
            id: `${SubfeatureIdEnum.Color_Copy_Mode}`,
            type: `${SubFeatureControlTypeEnum.Dropbox}`,
            accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
            label: 'label',
            subFeatureId: `${ConstraintsSubFeatureIdEnum.Color_Copy_Mode}`,
            resource: `${ConstraintsResourceEnum.ColorCopyMode}`,
            settingsName: `${ConstraintsSettingsNameEnum.Color_Copy_Mode}`,
            serviceGunData: `${DeviceCacheServiceGunEnum.CopyCopy}`,
            serviceSettingsData: `${DeviceCacheCdmDataEnum.ColorCopyMode}`,
          },
        ],
      },
      {
        id: `${FeatureIdEnum.Copy_JobBuild}`,
        configurable: false,
        subfeatures: [
          {
            id: `${SubfeatureIdEnum.Copy_JobBuild}`,
            type: `${SubFeatureControlTypeEnum.Toggle}`,
            accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
            label: 'copy-job-build_label',
            subFeatureId: `${ConstraintsSubFeatureIdEnum.Copy_JobBuild}`,
            resource: `${ConstraintsResourceEnum.Copy_JobBuild}`,
            settingsName: `${ConstraintsSettingsNameEnum.Copy_JobBuild}`,
            serviceGunData: `${DeviceCacheServiceGunEnum.CopyCopy}`,
            serviceSettingsData: `${DeviceCacheCdmDataEnum.CopyJobBuild}`,
          },
        ],
      },
      {
        id: `${FeatureIdEnum.Copy_Background_Cleanup}`,
        configurable: false,
        subfeatures: [
          {
            id: `${SubfeatureIdEnum.Copy_Background_Cleanup}`,
            type: `${SubFeatureControlTypeEnum.Textbox}`,
            accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
            label: 'label',
            subFeatureId: `${ConstraintsSubFeatureIdEnum.Copy_Background_Cleanup}`,
            resource: `${ConstraintsResourceEnum.ColorCopyMode}`,
            settingsName: `${ConstraintsSettingsNameEnum.Copy_Background_Cleanup}`,
            serviceGunData: `${DeviceCacheServiceGunEnum.CopyCopy}`,
            serviceSettingsData: `${DeviceCacheCdmDataEnum.Copy_Background_Cleanup}`,
          },
        ],
      },
      {
        id: `${FeatureIdEnum.Copy_Contrast}`,
        configurable: false,
        subfeatures: [
          {
            id: `${SubfeatureIdEnum.Copy_Contrast}`,
            type: `${SubFeatureControlTypeEnum.Textbox}`,
            accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
            label: 'label',
            subFeatureId: `${ConstraintsSubFeatureIdEnum.Copy_Contrast}`,
            resource: `${ConstraintsResourceEnum.ColorCopyMode}`,
            settingsName: `${ConstraintsSettingsNameEnum.Copy_Contrast}`,
            serviceGunData: `${DeviceCacheServiceGunEnum.CopyCopy}`,
            serviceSettingsData: `${DeviceCacheCdmDataEnum.Copy_Contrast}`,
          },
        ],
      },
      {
        id: `${FeatureIdEnum.Copy_Darkness}`,
        configurable: false,
        subfeatures: [
          {
            id: `${SubfeatureIdEnum.Copy_Darkness}`,
            type: `${SubFeatureControlTypeEnum.Textbox}`,
            accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
            label: 'label',
            subFeatureId: `${ConstraintsSubFeatureIdEnum.Copy_Darkness}`,
            resource: `${ConstraintsResourceEnum.ColorCopyMode}`,
            settingsName: `${ConstraintsSettingsNameEnum.Copy_Darkness}`,
            serviceGunData: `${DeviceCacheServiceGunEnum.CopyCopy}`,
            serviceSettingsData: `${DeviceCacheCdmDataEnum.Copy_Darkness}`,
          },
        ],
      },
      {
        id: `${FeatureIdEnum.Copy_Optimize_Text_Picture}`,
        configurable: false,
        subfeatures: [
          {
            id: `${SubfeatureIdEnum.Copy_Optimize_Text_Picture}`,
            type: `${SubFeatureControlTypeEnum.Toggle}`,
            accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
            label: 'label',
            subFeatureId: `${ConstraintsSubFeatureIdEnum.Copy_Optimize_Text_Picture}`,
            resource: `${ConstraintsResourceEnum.ColorCopyMode}`,
            settingsName: `${ConstraintsSettingsNameEnum.Copy_Optimize_Text_Picture}`,
            serviceGunData: `${DeviceCacheServiceGunEnum.CopyCopy}`,
            serviceSettingsData: `${DeviceCacheCdmDataEnum.Copy_Optimize_Text_Picture}`,
          },
        ],
      },
      {
        id: `${FeatureIdEnum.Copy_Paper_Tray_Selection}`,
        configurable: false,
        subfeatures: [
          {
            id: `${SubfeatureIdEnum.Copy_Paper_Tray_Selection}`,
            type: `${SubFeatureControlTypeEnum.Toggle}`,
            accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
            label: 'label',
            subFeatureId: `${ConstraintsSubFeatureIdEnum.Copy_Paper_Tray_Selection}`,
            resource: `${ConstraintsResourceEnum.ColorCopyMode}`,
            settingsName: `${ConstraintsSettingsNameEnum.Copy_Paper_Tray_Selection}`,
            serviceGunData: `${DeviceCacheServiceGunEnum.CopyCopy}`,
            serviceSettingsData: `${DeviceCacheCdmDataEnum.Copy_Paper_Tray_Selection}`,
          },
        ],
      },
      {
        id: `${FeatureIdEnum.Copy_Sharpness}`,
        configurable: false,
        subfeatures: [
          {
            id: `${SubfeatureIdEnum.Copy_Sharpness}`,
            type: `${SubFeatureControlTypeEnum.Textbox}`,
            accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
            label: 'label',
            subFeatureId: `${ConstraintsSubFeatureIdEnum.Copy_Sharpness}`,
            resource: `${ConstraintsResourceEnum.ColorCopyMode}`,
            settingsName: `${ConstraintsSettingsNameEnum.Copy_Sharpness}`,
            serviceGunData: `${DeviceCacheServiceGunEnum.CopyCopy}`,
            serviceSettingsData: `${DeviceCacheCdmDataEnum.Copy_Sharpness}`,
          },
        ],
      },
      {
       id: `${FeatureIdEnum.Copy_stamp}`,
      configurable: false,
      subfeatures: [
        {
          id: `${SubfeatureIdEnum.Copy_Stamp_Position_Preview}`,
          type: `${SubFeatureControlTypeEnum.IconCheckMark}`,
          accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
          label: 'copyStamp_label',
          subFeatureId: `${ConstraintsSubFeatureIdEnum.Copy_Stamp_Position_Preview}`,
          resource: `${ConstraintsResourceEnum.ScanStamp_Location}`,
          settingsName: `${ConstraintsSettingsNameEnum.ScanStamp_Location}`,
          serviceGunData: `${DeviceCacheServiceGunEnum.CopyStampLocations}`,
          serviceSettingsData: `${DeviceCacheCdmDataEnum.CopyStamp_Location}`,
        },
        {
          id: `${SubfeatureIdEnum.Copy_Stamp_Content}`,
          type: `${SubFeatureControlTypeEnum.Table}`,
          accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
          label: 'copyStampcontent_label',
          subFeatureId: `${ConstraintsSubFeatureIdEnum.Copy_Stamp_Position_Preview}`,
          resource: `${ConstraintsResourceEnum.ScanStamp_Location}`,
          settingsName: `${ConstraintsSettingsNameEnum.ScanStamp_Location}`,
          serviceGunData: `${DeviceCacheServiceGunEnum.CopyStampLocations}`,
          serviceSettingsData: `${DeviceCacheCdmDataEnum.CopyStamp_Location}`,
        },
      ],
      },
  ];
