import tokens from '@veneer/tokens';
import styled from 'styled-components';
import { FlexRowAlignCenter, styleAttributes5 } from '../../GlobalStyles/GlobalStyles';
import * as Veneer from '@veneer/tokens/dist/tokens/tokens';

export const InfoIcon = styled.div`
  height: 13.33px;
  width: 13.33px;
  left: 1.33px;
  border-radius: 0px;
  margin-left: 5.33px;
  display: inline-block;
`;

export const SubFeature = styled.div`
&:nth-child(2) {
    margin-bottom: ${(props) => (props.subFeatureId ===  'network-folder-notification-notification-mode' ? '0px' : '16px')};
  } 
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0px;
  }
`;

export const TitleWrap = styled(FlexRowAlignCenter)``;

export const Title = styled.div`
  ${styleAttributes5}
  font-family: Forma DJR UI !important;
  color: ${Veneer.colorGray5} !important;;
`;

export const CustomLabel = styled.div`
  font-size: 12px;
  line-height: 16px;
  font-family: ${tokens.fontTitleRegular};
  color: #404040;
  padding-bottom: 16px;
  font-weight: 700;
`;
export const AccessControlHeader = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-family: ${tokens.fontTitleRegular};
  color: #404040;
  padding: 24px 0px 16px 0px;
  font-weight: 700;
`;

export const StampPreviewBox = styled.div`
  width: 90px;
  height: 112.5px;
  background:${tokens.colorGray0} !important;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: space-evenly;
  border: 1px solid ${tokens.colorGray3};
`;
export const StampPreviewBoxLayout = styled.div`
pading-top: 8px;
padding-bottom: 24px;
`;

export const StampCheckIcon = styled.div`
  color: ${tokens.colorGray7} !important;
  margin-top: 8px;
  margin-bottom: 8px;
  gap: 8px;
  display: inline-flex;
`;
