import React from 'react';
import LocalizeHelper from '../LocalizeHelper';
import { FeatureIdEnum } from '../../config/FeatureEnums';
import { SubfeatureIdEnum } from '../../config/SubFeatureEnums';
import { tableValueIdsFaxSendMethod, tableValueIdsFaxSendCommonJob, tableValueIdsFaxReceiveMethod, tableValueIdsFaxReceiveCommonJob } from '../../config/tableIdMap';
import { ConstraintsSettingsNameEnum } from '../../config/ConstraintsEnums';

export const FaxTableHelper = (subFeatureId, values, deviceConstraints) => {
  const { getDeviceConfigurationsLocString, getDeviceValueLocString } = LocalizeHelper();
  const getNestedValue = (obj, path) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };

  const isEmptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  const getMatchedValues = (deviceConstraints, values) => {
    return deviceConstraints.reduce((acc, constraint) => {
      const keyParts = constraint.name.split('.');
      const nestedKey = constraint.name === ConstraintsSettingsNameEnum.ImageModifications ? keyParts.join('.')
      : keyParts.slice(1).join('.');
      const value = getNestedValue(values, nestedKey);
      if (value !== undefined) {
        acc.push({ name: constraint.name, value: value });
      }
      return acc;
    }, []);
  };

  const getTableValueIds = (subFeatureId) => {
    if (subFeatureId === SubfeatureIdEnum.Fax_Send_Method) {
      return tableValueIdsFaxSendMethod;
    } else if (subFeatureId === SubfeatureIdEnum.Fax_Receive_Method) {
      return tableValueIdsFaxReceiveMethod;
    } else if (subFeatureId === SubfeatureIdEnum.Fax_Send_CommonJob) {
      return tableValueIdsFaxSendCommonJob;
    } else if (subFeatureId === SubfeatureIdEnum.Fax_Receive_CommonJob) {
      return tableValueIdsFaxReceiveCommonJob;
    } else {
      return [];
    }
  };

  const getFinalValue = (optionName, value, localizedValue) => {
    return (optionName === 'notificationAddress' || optionName === 'ringsToAnswer') ? value : localizedValue;
  };
  const getOptionName = (values, localizedOptionName, optionName) => {
    return isEmptyObject(values) ? null : localizedOptionName || optionName;
  };

  const transformMatchedValuesToTableData = (matchedValues, tableValueIds) => {
    return tableValueIds.map((id) => {
      const matchedValue = matchedValues.find((item) => {
        const itemParts = item.name.split('.');
        const idParts = id.split('.');
        return itemParts.slice(-idParts.length).join('.') === id;
      });
      const value = matchedValue ? matchedValue.value : '';
      const optionName = id.split('.').pop();
      const localizedOptionNameForFaxSend = getDeviceConfigurationsLocString(FeatureIdEnum.Fax_Send_Settings, optionName, subFeatureId);
      const localizedOptionNameForFaxReceive = getDeviceConfigurationsLocString(FeatureIdEnum.Fax_Receive_Settings, optionName, subFeatureId);
      const localizedValue = value
        ? (subFeatureId === SubfeatureIdEnum.Fax_Receive_Method || subFeatureId === SubfeatureIdEnum.Fax_Receive_CommonJob)
          ? getDeviceValueLocString(FeatureIdEnum.Fax_Receive_Settings, `${optionName}.${value}`)
          : getDeviceValueLocString(FeatureIdEnum.Fax_Send_Settings, `${optionName}.${value}`)
        : '';
        const optionNameIsPresentForSend = getOptionName(values, localizedOptionNameForFaxSend, optionName);
        const optionNameForReceive = getOptionName(values, localizedOptionNameForFaxReceive, optionName);  
      return {
        option_name: subFeatureId === SubfeatureIdEnum.Fax_Send_Method ? optionNameIsPresentForSend : optionNameForReceive, // Use localized name if available
        value: getFinalValue(optionName, value, localizedValue),
      };
    });
  };

  const matchedValues = getMatchedValues(deviceConstraints, values);
  const tableValueIds = getTableValueIds(subFeatureId);
  const tableData = transformMatchedValuesToTableData(matchedValues, tableValueIds);
  const isTableDataEmpty = tableData.every(item => item.option_name === null && item.value === '');
  return { FaxSendTableData: isTableDataEmpty ? [] : tableData };
};