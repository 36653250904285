import { SubfeatureIdEnum } from '../../../src/config/SubFeatureEnums';
import { FeatureIdEnum } from '../../../src/config/FeatureEnums';
import LocalizeHelper from '../LocalizeHelper';
import { permissionIdForEWS } from '../../../tests/mocks/AccessControlData';
import {
  CustomParentHeading,
  CustomChildHeading,
} from '../../../src/helper/table/AccessControlStyles';
import React from 'react';
import { IconCheckmark } from '@veneer/core';

export const ControlPanelTableHelper = (subFeatureId, values, tableHead, deviceCacheResult) => {
  const { getDeviceCommonLocString, getDeviceConfigurationsLocString } = LocalizeHelper();
  const CPtableHeads = tableHead.map((data) => data.id).filter((id) => id !== 'Application' && id !== 'SignInMethod');
  const deviceCacheData = deviceCacheResult['com.hp.cdm.service.security.version.1.resource.rbacConfig']?.cdmData.rolePermissionMappings;
  const deviceCacheDataForPermissions = deviceCacheData?.map((role) => role.permissions.flatMap((ids) => ids));
  const deviceCacheDataForRoleIds = deviceCacheData?.map((role) => role.role);
  if (!Array.isArray(values)) {
    return { ControlPanelTableData: [] };
  }
  const controlPanelTableData = values.filter((data) => !permissionIdForEWS.permissionId.hasOwnProperty(data.permissionId));
  const permissionIdCheck = controlPanelTableData.map((data) => data.permissionId);
  const childPermissionsCheck = controlPanelTableData.map((data) => data.childPermissions);
  const renderCheckmark = (value) => (value ? <IconCheckmark size={24} color="green" /> : ' ');

  const permissionIdForCheckmark = deviceCacheDataForRoleIds?.map((roleId, roleIndex) => ({
    role: roleId,
    permissionsCheck: permissionIdCheck.map((permission) => deviceCacheDataForPermissions[roleIndex].includes(permission))}));
  const checkMarkForParentId = permissionIdForCheckmark?.map((roleResult) => roleResult.permissionsCheck.map(renderCheckmark)) 
  ?? [];

  const childPermissionsForCheckmark = deviceCacheDataForRoleIds?.map((roleId, roleIndex) => ({
    role: roleId,
    childCheck: childPermissionsCheck.map((childPermissionArray) => childPermissionArray?.map((childPermission) => 
      deviceCacheDataForPermissions[roleIndex].includes(childPermission),
      ),
    ),
  }));
  const checkMarkForChildren =childPermissionsForCheckmark?.map((roleResult) => 
    roleResult.childCheck.map((childArray) => childArray?.map(renderCheckmark)),
  ) ?? [];

  if (controlPanelTableData) {
      const combineApps = controlPanelTableData.flatMap((data, dataIndex) => {
      const localizedParentName =   data.parentPermission ? getDeviceCommonLocString(FeatureIdEnum.AccessControl, data.parentPermission) : data.localizedName;
      const localParentPermission = localizedParentName ?  <CustomParentHeading>{localizedParentName}</CustomParentHeading> : <CustomParentHeading>{data.localizedName}</CustomParentHeading>;
      const locChildPermissions = data.childPermissions? data.childPermissions.map((child, index) => (<CustomChildHeading key={index}>{getDeviceCommonLocString(FeatureIdEnum.AccessControl, child)}</CustomChildHeading>)): [];
      const signInMethod = data.permissionId? getDeviceConfigurationsLocString(FeatureIdEnum.AccessControl,data.configuredAuthenticationMethod,SubfeatureIdEnum.SignIn_Method,): '';
      const parentEntry = {
        Application: localParentPermission,
        SignInMethod: signInMethod,
        ...CPtableHeads.reduce((acc, columnId, index) => {
          acc[columnId] = checkMarkForParentId[index]?.[dataIndex] ?? ' ';
          return acc;
        }, {}),
      };
      const childEntries = locChildPermissions.map((child, childIndex) => ({
        Application: child,
        SignInMethod: '',
        ...CPtableHeads.reduce((acc, columnId, index) => {
          acc[columnId] = checkMarkForChildren[index]?.[dataIndex]?.[childIndex] ?? ' ';
          return acc;
        }, {}),
      }));
      return [parentEntry, ...childEntries];
    });
    return { ControlPanelTableData: combineApps };
  }
};
