import {
    ConstraintsResourceEnum,
    ConstraintsSettingsNameEnum,
    ConstraintsSubFeatureIdEnum,
  } from '../config/ConstraintsEnums';
  import { FeatureEntitlementEnum, FeatureIdEnum } from '../config/FeatureEnums';
  import { FleetSvcPolicyAttributeId } from '../config/FleetServiceEnums';
  import { DeviceCacheCdmDataEnum } from '../config/DeviceCacheCdmDataEnums';
  import { DeviceCacheServiceGunEnum } from '../config/DeviceCacheServiceGunEnums';
  import {
    SubFeatureAccessModeEnum,
    SubFeatureControlTypeEnum,
    SubfeatureIdEnum,
    SubfeatureIndentEnum
  } from '../config/SubFeatureEnums';

  export const WirelessDataModel = [
    {
      id: `${FeatureIdEnum.Wifi_Direct}`,
      entitlementId: `${FeatureEntitlementEnum.advance}`,
      subfeatures: [
        {
          id: `${SubfeatureIdEnum.Wifi_Direct_Enable}`,
          attribute: `${FleetSvcPolicyAttributeId.Wifi_Direct}.check`,
          type: `${SubFeatureControlTypeEnum.Checkbox}`,
          accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
          label: 'wifi_direct_enable_label',
          subFeatureId: `${ConstraintsSubFeatureIdEnum.Wifi_Direct_Enable}`,
          resource: `${ConstraintsResourceEnum.WifiDirect_Configuration}`,
          settingsName: `${ConstraintsSettingsNameEnum.Wifi_Direct_Enable}`,
          serviceGunData: `${DeviceCacheServiceGunEnum.WifiDirectConfiguration}`,
          serviceSettingsData: `${DeviceCacheCdmDataEnum.Wifi_Direct_Enable}`,
        },
        {
          id: `${SubfeatureIdEnum.Wifi_Direct_Suffix}`,
          attribute: `${FleetSvcPolicyAttributeId.Wifi_Direct}.suffix`,
          type: `${SubFeatureControlTypeEnum.Textbox}`,
          accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
          indent: SubfeatureIndentEnum.Indent_Level_1,
          label: 'wifi_direct_suffix_label',
          placeholder: 'suffix_hint',
          subFeatureId: `${ConstraintsSubFeatureIdEnum.Wifi_Direct_Suffix}`,
          resource: `${ConstraintsResourceEnum.WifiDirect_Configuration}`,
          settingsName: `${ConstraintsSettingsNameEnum.Wifi_Direct_Suffix}`,
          serviceGunData: `${DeviceCacheServiceGunEnum.WifiDirectConfiguration}`,
          serviceSettingsData: `${DeviceCacheCdmDataEnum.Wifi_Direct_Suffix}`,
        },
        {
          id: `${SubfeatureIdEnum.Wifi_Direct_Connection}`,
          attribute: `${FleetSvcPolicyAttributeId.Wifi_Direct}.method`,
          type: `${SubFeatureControlTypeEnum.Radio}`,
          accessMode: `${SubFeatureAccessModeEnum.WRITEONLY}`,
          indent: SubfeatureIndentEnum.Indent_Level_1,
          label: 'wifi_direct_connection_method_label',
          subFeatureId: `${ConstraintsSubFeatureIdEnum.Wifi_Direct_Connection}`,
          resource: `${ConstraintsResourceEnum.WifiDirect_Configuration}`,
          settingsName: `${ConstraintsSettingsNameEnum.Wifi_Direct_Connection}`,
          serviceGunData: `${DeviceCacheServiceGunEnum.WifiDirectConfiguration}`,
          serviceSettingsData: `${DeviceCacheCdmDataEnum.Wifi_Direct_Connection}`,
        },
        {
          id: `${SubfeatureIdEnum.Wifi_Direct_Password}`,
          attribute: `${FleetSvcPolicyAttributeId.Wifi_Direct}.password`,
          type: `${SubFeatureControlTypeEnum.Password}`,
          accessMode: `${SubFeatureAccessModeEnum.WRITEONLY}`,
          indent: SubfeatureIndentEnum.Indent_Level_2,
          label: 'wifi_direct_password_label',
          subFeatureId: `${ConstraintsSubFeatureIdEnum.Wifi_Direct_Password}`,
          resource: `${ConstraintsResourceEnum.WifiDirect_Configuration}`,
          settingsName: `${ConstraintsSettingsNameEnum.Wifi_Direct_Password}`,
          serviceGunData: `${DeviceCacheServiceGunEnum.WifiDirectConfiguration}`,
          serviceSettingsData: `${DeviceCacheCdmDataEnum.Wifi_Direct_Hide_SSID}`,
        },
        {
          id: `${SubfeatureIdEnum.Wifi_Direct_Channel}`,
          attribute: `${FleetSvcPolicyAttributeId.Wifi_Direct}.channel`,
          type: `${SubFeatureControlTypeEnum.Dropbox}`,
          accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
          indent: SubfeatureIndentEnum.Indent_Level_2,
          label: 'channel_label',
          hint: 'channel_hint',
          subFeatureId: `${ConstraintsSubFeatureIdEnum.Wifi_Direct_Channel}`,
          resource: `${ConstraintsResourceEnum.WifiDirect_Configuration}`,
          settingsName: `${ConstraintsSettingsNameEnum.Wifi_Direct_Channel}`,
          serviceGunData: `${DeviceCacheServiceGunEnum.WifiDirectConfiguration}`,
          serviceSettingsData: `${DeviceCacheCdmDataEnum.Wifi_Direct_PreferredChannel}`,
        },
        {
          id: `${SubfeatureIdEnum.Wifi_Direct_Hide_Broadcast}`,
          attribute: `${FleetSvcPolicyAttributeId.Wifi_Direct}.do-not-broadcast-name`,
          type: `${SubFeatureControlTypeEnum.Checkbox}`,
          accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
          indent: SubfeatureIndentEnum.Indent_Level_2,
          label: 'do_not_show_broadcast',
          subFeatureId: `${ConstraintsSubFeatureIdEnum.Wifi_Direct_Hide_Broadcast}`,
          resource: `${ConstraintsResourceEnum.WifiDirect_Configuration}`,
          settingsName: `${ConstraintsSettingsNameEnum.Wifi_Direct_Hide_Broadcast}`,
          serviceGunData: `${DeviceCacheServiceGunEnum.WifiDirectConfiguration}`,
          serviceSettingsData: `${DeviceCacheCdmDataEnum.wifi_Direct_DoNotBroadcast}`,
        },
        {
          id: `${SubfeatureIdEnum.Wifi_Direct_Hide_SSID}`,
          attribute: `${FleetSvcPolicyAttributeId.Wifi_Direct}.do-not-show-name`,
          type: `${SubFeatureControlTypeEnum.Checkbox}`,
          accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
          indent: SubfeatureIndentEnum.Indent_Level_2,
          label: 'do_not_show_name',
          subFeatureId: `${ConstraintsSubFeatureIdEnum.Wifi_Direct_Hide_SSID}`,
          resource: `${ConstraintsResourceEnum.WifiDirect_Configuration}`,
          settingsName: `${ConstraintsSettingsNameEnum.Wifi_Direct_Hide_SSID}`,
          serviceGunData: `${DeviceCacheServiceGunEnum.WifiDirectConfiguration}`,
          serviceSettingsData: `${DeviceCacheCdmDataEnum.Wifi_Direct_Hide_SSID}`,
        },
        {
          id: `${SubfeatureIdEnum.Wifi_Direct_Hide_Password}`,
          attribute: `${FleetSvcPolicyAttributeId.Wifi_Direct}.do-not-show-password`,
          type: `${SubFeatureControlTypeEnum.Checkbox}`,
          accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
          indent: SubfeatureIndentEnum.Indent_Level_2,
          label: 'do_not_show_password',
          subFeatureId: `${ConstraintsSubFeatureIdEnum.Wifi_Direct_Hide_Password}`,
          resource: `${ConstraintsResourceEnum.WifiDirect_Configuration}`,
          settingsName: `${ConstraintsSettingsNameEnum.Wifi_Direct_Hide_Password}`,
          serviceGunData: `${DeviceCacheServiceGunEnum.WifiDirectConfiguration}`,
          serviceSettingsData: `${DeviceCacheCdmDataEnum.Wifi_Direct_Hide_Password}`,
        },
      ],
    }
  ];
