import tokens from '@veneer/tokens';
import styled from 'styled-components';

export const FeatureAdvance = styled.div`
  color: ${tokens.colorGray6};
  font-size: ${tokens.fontSize1};
  line-height: ${tokens.lineHeight2};
  border: 1px solid ${tokens.colorGray6};
  border-radius: 10px;
  padding: 2px 8px 2px 8px;
  box-sizing: border-box;
  width: Hug(64px);
  height: Hug(20px);
  white-space: nowrap;
  margin: ${(props) => (props.view === 'batchView' ? '0px' : '4.5px')};
`;

export const advanceText = styled.text`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  display: flex;
  align-items: center;
  text-align: center;
  color: #585858;
  flex: none;
  order: 0;
  flex-grow: 0;
  Min-width : 25px
  height: 16px;
`;
