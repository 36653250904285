import { useDispatch } from 'react-redux';
import { AppConfig } from '../config/App.config';
import CategoryTreeHelper from '../helper/CategoryTreeHelper';
import GetDeviceCacheValueHelper from '../helper/GetDeviceCacheValueHelper';
import { setFeaturesData, setRulesData, setSettingsData } from '../store/deviceConfig/action';
import CategoryControl from './CategoryControl';
import ConstraintsRuleParser from './ConstraintsRuleParser';
import logger from '../../src/utils/logger';
import {
  SubfeatureIdEnum,
} from '../config/SubFeatureEnums';
import { ConstraintsResourceEnum } from '../../src/config/ConstraintsEnums';


const DeviceConfigParser = () => {
  const { getAsyncJsonData } = CategoryControl();
  const { getDeviceCacheValue } = GetDeviceCacheValueHelper();
  const dispatch = useDispatch();
  const { validateIfRuleExists } = ConstraintsRuleParser();
  const { getPolicyAttributeDescription } = CategoryTreeHelper();

  const getCategoryData = (
    constraintsResources,
    deviceModelNumber,
    deviceCacheResult,
    isUnSupportedPlugin,
    deviceData,
    policyData
  ) => {
    return new Promise((resolve, reject) => {
      getAsyncJsonData()
        .then((combinedSettings: any) => {
          const dataModelFeatues = [];
          const dataModelSettings = [];

          // Iterate DataModal
          Object.keys(combinedSettings).forEach((key) => {
            const combineDatas = combinedSettings[key];

            // Adding Categoryname and filter visible data
            const filterCombineDataFeatures = combineDatas
              ?.map((_combineData) => {
                const categoryLevel = getPolicyAttributeDescription(_combineData.id);
                const filteredPolicy = policyData?.length
                  ? policyData?.filter((policy) => policy.attributeName == _combineData.id.substring(3))
                  : [];
                return { ..._combineData,
                        categoryName: key,
                        categoryLevel,
                        controlledPolicy: filteredPolicy?.length ? filteredPolicy[0]?.controlledPolicy : [],
                        effectivePolicy: filteredPolicy?.length ? filteredPolicy[0]?.policyType : null };
              })
              .filter(
                (_resultCombineData) =>
                  _resultCombineData.visible === undefined || _resultCombineData.visible === true,
              );

            // Filter settings
            const filterCombineDataSettings = combineDatas
              ?.map((_combineData) => {
                return _combineData.subfeatures;
              })
              .flat(1);

            dataModelFeatues.push(...filterCombineDataFeatures);
            dataModelSettings.push(...filterCombineDataSettings);
          });

          // Compare Datamodal with constraints data
          // Added constraints values in settings Data
          const filteredConstraintSettings = dataModelSettings
            ?.map((_dataModelSetting) => {
              const result = constraintsResources?.find(
                (_constraintsResource) =>
                  _constraintsResource.resource === _dataModelSetting.resource,
              );
              if (result) {
                const constraints = (_dataModelSetting.resource ===  `${ConstraintsResourceEnum.FaxSettings_Method}` || 
                _dataModelSetting.resource === `${ConstraintsResourceEnum.FaxSendSettings_CommonJob}` ||
                _dataModelSetting.resource === `${ConstraintsResourceEnum.FaxReceiveSettings_CommonJob}`)
                ? result.settings?.filter(
                    (_constraintsSetting) =>
                      Array.isArray(_dataModelSetting.settingsName)
                        ? _dataModelSetting.settingsName.includes(_constraintsSetting.name)
                        : _constraintsSetting.name === _dataModelSetting.settingsName,
                  )
                : result.settings?.find(
                    (_constraintsSetting) =>
                      Array.isArray(_dataModelSetting.settingsName)
                        ? _dataModelSetting.settingsName.includes(_constraintsSetting.name)
                        : _constraintsSetting.name === _dataModelSetting.settingsName,
                  );
                if (
                  constraints &&
                  !constraints.excludeDeviceModelNumberList?.includes(deviceModelNumber[0])
                ) {
                  return {
                    ..._dataModelSetting,
                    constraints,
                  };
                }
              }
            })
            .filter(function (_resultSetting) {
              return _resultSetting !== undefined;
            });

          // Handling Device Cache Data
          // Added device cache values in settings Data
          const finalSettings = filteredConstraintSettings?.map((_filteredConstraintSetting) => {
            let deviceCacheData = {};
            const serviceGunData = _filteredConstraintSetting.serviceGunData;
            const serviceSettingsData = _filteredConstraintSetting.serviceSettingsData;
            const defaultValue = _filteredConstraintSetting.constraints.defaultValue;
            const isCustomMethod = AppConfig.customizeGetSetting?.includes(
              _filteredConstraintSetting.id,
            );

            const _filteredConstraintSettingGroup = _filteredConstraintSetting.group;

            const filteredfleetData = Object.fromEntries(
              (
                Object.entries(deviceCacheResult) as [
                  string,
                  { cdmMetaData: { serviceGun: string } },
                ][]
              ).filter(([key, value]) => value?.cdmMetaData?.serviceGun === serviceGunData),
            );

            const originalKey = Object.keys(filteredfleetData)[0]; // Get the original key
            const newKey = filteredfleetData[originalKey]?.cdmMetaData?.serviceGun; // Get the new key from the serviceGun property

            const modifiedObject = {
              [newKey]: filteredfleetData[originalKey],
            };

            if (_filteredConstraintSettingGroup) {
              const finalFleetData = modifiedObject[serviceGunData];

              if (finalFleetData) {
                const cdmData = finalFleetData['cdmData'];
                const splitCdmData = serviceSettingsData.split('.');
                splitCdmData.shift();

                const finalCdmData = [];

                for (let key in cdmData) {
                  let result = cdmData;
                  splitCdmData.forEach((index) => {
                    if (result[index] !== undefined) {
                      result = result[index];
                    } else {
                      result = 'undefined';
                    }
                  });

                  finalCdmData.push({
                    group: key,
                    id: _filteredConstraintSetting.id,
                    value: result,
                  });

                  deviceCacheData = {
                    fleetResult: 'success',
                    fleetValue: finalCdmData,
                  };
                }
              } else {
                deviceCacheData = {
                  fleetResult: 'pending',

                  fleetValue: defaultValue ? defaultValue : null,
                };
                if (isUnSupportedPlugin) {
                  deviceCacheData = {
                    fleetResult: 'pending',

                    fleetValue: defaultValue ? defaultValue : null,

                    visible: false,
                  };
                }
              }
            } else {
              if (serviceGunData && serviceSettingsData) {
                deviceCacheData = getDeviceCacheValue(
                  serviceGunData,
                  serviceSettingsData,
                  deviceCacheResult,
                  isUnSupportedPlugin,
                  isCustomMethod,
                  defaultValue,
                );
              } else {
                if (_filteredConstraintSetting.id === SubfeatureIdEnum.App_Deployment_AppInstallation && deviceData && Object.keys(deviceData).length !== 0) {
                  deviceCacheData = {
                    fleetResult: 'success',
                    fleetValue: deviceData,
                  };
                } else {
                  deviceCacheData = {
                    fleetResult: 'pending',
                    fleetValue: defaultValue ? defaultValue : null,
                    visible: isUnSupportedPlugin ? false : undefined,
                  };
                }
              }
            }

            return {
              ..._filteredConstraintSetting,
              ...deviceCacheData,
            };
          });

          // Adopt all the filtered constraints and devicecache Data
          const finalFeatures = dataModelFeatues
            ?.map((_dataModelFeatue) => {
              return {
                ..._dataModelFeatue,
                subfeatures: _dataModelFeatue.subfeatures
                  ?.map((_subfeature) => {
                    const result = finalSettings?.find(
                      (_finalSetting) =>
                        _finalSetting.id === _subfeature.id &&
                        (_subfeature.visible == undefined || _subfeature.visible),
                    );
                    return result;
                  })
                  .filter(function (_resultSubfeature) {
                    return _resultSubfeature !== undefined;
                  }),
              };
            })
            .filter(function (_resultDataModelFeatue) {
              return _resultDataModelFeatue.subfeatures.length;
            });

          // Check Rules Data
          const rulesExistsData = finalSettings
            ?.map((_finalSetting) => {
              return validateIfRuleExists(_finalSetting.constraints, _finalSetting.settingsName);
            })
            .flat(1);

          // Filter dependsOn values
          const filteredRulesExistsData = rulesExistsData.filter((_rulesExistsData) => {
            return _rulesExistsData.dependsOn.length;
          });

          // Dispatched Data to redux
          dispatch(setFeaturesData(finalFeatures));
          dispatch(setSettingsData(finalSettings));
          dispatch(setRulesData(filteredRulesExistsData));

          resolve('Success');
        })
        .catch((err) => {
          logger.error('DeviceConfigParser_TemplateControlsParser::getMergedTabControls rejecting with error', err);
          reject(err);
        });
    });
  };

  return {
    getCategoryData,
  };
};

export default DeviceConfigParser;