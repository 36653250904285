import {
  ConstraintsResourceEnum,
  ConstraintsSettingsNameEnum,
  ConstraintsSubFeatureIdEnum,
} from '../config/ConstraintsEnums';
import { FeatureIdEnum } from '../config/FeatureEnums';
import { FleetSvcPolicyAttributeId } from '../config/FleetServiceEnums';
import { DeviceCacheCdmDataEnum } from '../config/DeviceCacheCdmDataEnums';
import { DeviceCacheServiceGunEnum } from '../config/DeviceCacheServiceGunEnums';
import {
  SubFeatureAccessModeEnum,
  SubFeatureControlTypeEnum,
  SubfeatureIdEnum,
} from '../config/SubFeatureEnums';

export const FaxDataModel = [
  {
    id: `${FeatureIdEnum.Fax_Header_Settings}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Fax_Header_Settings_Phone_Number}`,
        attribute: `${FleetSvcPolicyAttributeId.Fax_Header_Settings_Phone_Number}.value`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'fax_header_settings_phone_number_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Fax_Header_Settings_Phone_Number}`,
        resource: `${ConstraintsResourceEnum.FaxModemConfiguration}`,
        settingsName: `${ConstraintsSettingsNameEnum.Fax_Header_Settings_Phone_Number}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.FaxModemConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Fax_Header_Settings_Phone_Number}`,
      },
      {
        id: `${SubfeatureIdEnum.Fax_Header_Settings_Company_Name}`,
        attribute: `${FleetSvcPolicyAttributeId.Fax_Header_Settings_Company_Name}.value`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'fax_header_settings_company_name_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Fax_Header_Settings_Company_Name}`,
        resource: `${ConstraintsResourceEnum.FaxModemConfiguration}`,
        settingsName: `${ConstraintsSettingsNameEnum.Fax_Header_Settings_Company_Name}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.FaxModemConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Fax_Header_Settings_Company_Name}`,
      },
      {
        id: `${SubfeatureIdEnum.Fax_Header_Settings_Location}`,
        attribute: `${FleetSvcPolicyAttributeId.Fax_Header_Settings_Location}.value`,
        type: `${SubFeatureControlTypeEnum.Dropbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'fax_header_settings_location_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Fax_Header_Settings_Location}`,
        resource: `${ConstraintsResourceEnum.FaxModemConfiguration}`,
        settingsName: `${ConstraintsSettingsNameEnum.Fax_Header_Settings_Location}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.FaxModemConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Fax_Header_Settings_Location}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Pc_Fax_Send}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Pc_Fax_Send}`,
        attribute: `${FleetSvcPolicyAttributeId.Pc_Fax_Send}.value`,
        type: `${SubFeatureControlTypeEnum.Checkbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'pc_fax_send_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Pc_Fax_Send}`,
        resource: `${ConstraintsResourceEnum.FaxSendConfig}`,
        settingsName: `${ConstraintsSettingsNameEnum.Pc_Fax_Send}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.FaxSendConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Pc_Fax_Send}`,
      }
    ],
  },
  {
    id: `${FeatureIdEnum.Fax_Send_Settings}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Fax_Send_Settings_Enable}`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'fax-send-settings_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Fax_Send_Settings_Enable}`,
        resource: `${ConstraintsResourceEnum.FaxSendSettings_Enable}`,
        settingsName: `${ConstraintsSettingsNameEnum.FaxSendSettings_Enable}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.FaxSendConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.FaxSend_Settings_Enable}`,
      },
      {
        id: `${SubfeatureIdEnum.Fax_Send_Method}`,
        type: `${SubFeatureControlTypeEnum.Table}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'fax-send-settings_method_label',
        tableTitle : 'fax-send-settings_method_header_tableTitle',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Fax_Send_Method}`,
        resource: `${ConstraintsResourceEnum.FaxSettings_Method}`,
        settingsName: [`${ConstraintsSettingsNameEnum.FaxSendSettings_errorCorrectionMode}`, `${ConstraintsSettingsNameEnum.FaxSendSettings_compressionJBIG}`],
        serviceGunData: `${DeviceCacheServiceGunEnum.FaxSendSettings_Method_Configuration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.FaxSend_Settings_Method}`,
      },
      {
        id: `${SubfeatureIdEnum.Fax_Send_CommonJob}`,
        type: `${SubFeatureControlTypeEnum.Table}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        tableTitle : 'fax-send-settings_commonJob_header_tableTitle',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Fax_Send_CommonJob}`,
        resource: `${ConstraintsResourceEnum.FaxSendSettings_CommonJob}`,
        settingsName: [ `${ConstraintsSettingsNameEnum.Copy_Background_Cleanup}`,`${ConstraintsSettingsNameEnum.Copy_Darkness}`,
         `${ConstraintsSettingsNameEnum.Copy_Contrast}`, `${ConstraintsSettingsNameEnum.Copy_Sharpness}`,
         `${ConstraintsSettingsNameEnum.Network_Folder_Notification_Condition}`, `${ConstraintsSettingsNameEnum.NotificationMode}`, 
         `${ConstraintsSettingsNameEnum.NotificationAddress}`,],
        serviceGunData: `${DeviceCacheServiceGunEnum.FaxSendSettings_CommonJob_Configuration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Fax_Settings_CommonJob}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Fax_Receive_Settings}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Fax_Receive_Settings_Enable}`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'fax-receive-settings-enable_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Fax_ReceiveSettings_Enable}`,
        resource: `${ConstraintsResourceEnum.FaxReceiveSettings}`,
        settingsName: `${ConstraintsSettingsNameEnum.FaxReceiveSettings_Enable}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.FaxReceiveConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.FaxSend_Receive_Enable}`,
      },
      {
        id: `${SubfeatureIdEnum.Fax_Receive_Method}`,
        type: `${SubFeatureControlTypeEnum.Table}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'fax-receive-settings_method_label',
        tableTitle : 'fax-receive-settings_method_header_tableTitle',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Fax_ReceiveMethod}`,
        resource: `${ConstraintsResourceEnum.FaxSettings_Method}`,
        settingsName: [`${ConstraintsSettingsNameEnum.FaxReceiveSettings_ringsToAnswer}`, `${ConstraintsSettingsNameEnum.FaxReceiveSettings_ringerVolume}`],
        serviceGunData: `${DeviceCacheServiceGunEnum.FaxSendSettings_Method_Configuration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.FaxReceive_Settings_Method}`,
      },
      {
        id: `${SubfeatureIdEnum.Fax_Receive_CommonJob}`,
        type: `${SubFeatureControlTypeEnum.Table}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        tableTitle : 'fax-receive-settings_commonJob_header_tableTitle',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Fax_ReceiveCommonJob}`,
        resource: `${ConstraintsResourceEnum.FaxReceiveSettings_CommonJob}`,
        settingsName: [`${ConstraintsSettingsNameEnum.ImageModifications}`,
          `${ConstraintsSettingsNameEnum.Network_Folder_Notification_Condition}`, 
          `${ConstraintsSettingsNameEnum.NotificationMode}`, 
        `${ConstraintsSettingsNameEnum.NotificationAddress}`],
        serviceGunData: `${DeviceCacheServiceGunEnum.JobTicketPrint}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Fax_Settings_CommonJob}`,
      },
    ],
  }
  ];
