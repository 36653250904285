import React, { Fragment, memo } from 'react';
import { SubFeatureAccessModeEnum } from '../../../config/SubFeatureEnums';
import * as Style from './Styles';

const Settings = (props) => {
  const { deviceSettingForms, accessMode,indent } = props;

  return (
    <>
      {deviceSettingForms.map((setting) => {
        return (
          <>
          {
            accessMode !== SubFeatureAccessModeEnum.READONLY ? (
              <Style.SettingsWarp key={setting.key} indent = {indent}>
                <Fragment>{setting.form}</Fragment>
              </Style.SettingsWarp>
            ) : null
          }
          </>
        );
      })}
    </>
  );
};

export default memo(Settings);
